import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Flex, LoadingOverlay, Modal, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks';
import { VscJersey } from 'react-icons/vsc';

import connection from '../../feathers';
import { AuthContext } from '../../Contexts';
import { getShortenedPlayerName } from '../../lib/getShortenedName';

import { convertPlayerObject } from '../../lib/convertPlayerObject';
import { getSalary } from '../../lib/getSalary';
import { getTotalSalary } from '../../lib/getTotalSalary';

import './Roster.scss'
import { notifications } from '@mantine/notifications';

export const SetLines = () => {
  const [players, setPlayers] = useState([]);
  const [line, setLine] = useState(null);
  const [position, setPosition] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [showPlayers, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(true);

  const load = async () => {
    const data = await connection.service('players').find({
      paginate: false,
      query: {
        $limit: 100,
        ownerId: currentUser.user._id
      }
    })

    // get match or game to see if the game is over
    let res = await connection.service('matchmaking').find({
      query: {
        $or: [
          { homeId: currentUser.user._id },
          { awayId: currentUser.user._id }
        ],
        $sort: {
          createdAt: -1
        },
      }
    })

    if (res.total <= 0) {

      res = await connection.service('games').find({
        query: {
          $limit: 1,
          $or: [
            { homeId: currentUser.user._id },
            { awayId: currentUser.user._id }
          ],
          $sort: {
            startTime: -1
          },
        }
      })
      
      if (res.total <= 0 || (res.total > 0 && (Date.now() - res.data[0].startTime) / 1000 > 3720)) {
        //console.log('unlocking g')
        setLocked(false);
      }
      
    }

    setPlayers(convertPlayerObject(data.data));
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, [])

  const choosePlayer = (line, position) => {
    setLine(line);
    setPosition(position);
    open();
  }

  const setLinePosition = async (player) => {
    if (locked) {
      notifications.show({
        title: 'Lineup Locked',
        message: 'You cannot change your lineup while in a game or matchmaking.',
        color: 'red',
      });
      return;
    }
    // Get current player in line and position
    // Remove line and position from current player or swap with new player
    const current = players.find(player => player.line === line && player.position === position);
    if (current) {
      await connection.service('players').patch(current._id, { line: 0, position: null });
    }
    await connection.service('players').patch(player._id, { line, position });
    load();
    close();
  }

  const getPlayerPosition = (line, position) => {
    const player = players.find(player => player.line === line && player.position === position);
    if (!player) return (<div className='linePlayer empty' onClick={() => choosePlayer(line, position)}>Empty</div>)
    return (
      <div key={player._id} className={(player.gamesPlayed >= player.contractLength || player.longevity <= player.bio.age) ? 'linePlayer highlighted' : 'linePlayer'} onClick={() => choosePlayer(line, position)}>
        <div>{getShortenedPlayerName(player)}</div>
        <div style={{ fontSize: '0.8rem', marginTop: '0.25rem', color: 'rgba(255, 255, 255, 0.75)'}}>{(player.longevity > player.bio.age) ? player.type.abbr + '|' + (player.overall() * 100).toFixed(0) : 'RETIRED'}</div>
      </div>
    )
  }

  const renderPlayerOptions = () => {
    let results = [];

    players.sort((a,b) => (a.overall() < b.overall()) ? 1 : -1).forEach(player => {
      if (((player.type.role.abbr === 'F' && (position === 'center' || position === 'leftWing' || position === 'rightWing')) ||
          (player.type.role.abbr === 'D' && (position === 'leftDefense' || position === 'rightDefense')) ||
          (player.type.role.abbr === 'G' && (position === 'starter' || position === 'backup'))) &&
          player.longevity > player.bio.age)
        results.push(
          <Flex key={player._id} className={(player.line === line && player.position === position) ? 'playerLineOption activePlayer' : 'playerLineOption'} onClick={() => setLinePosition(player)} my="1rem" justify="space-between">
            <div style={{ width: '20px'}}>{(player.line) ? <VscJersey /> : ''}</div>
            <div style={{ width: '20px'}}>{(player.overall() * 100).toFixed(0)}</div>
            <div style={{ width: '100px'}}>{getShortenedPlayerName(player)}</div>
            {(position === 'center') ? <div style={{ width: '50px'}}>{(player.technique.faceoff * 100).toFixed(0)} FO</div> : ''}
            <div className={(player.gamesPlayed >= player.contractLength) ? 'highlighted' : ''} style={{ width: '50px'}}>{(player.gamesPlayed >= player.contractLength) ? 'NC' : '$' + getSalary(player).toFixed(3) + 'M'}</div>
            <div style={{ width: '70px', textAlign: 'right'}}>{player.type.abbr} | {player.bio.handedness}</div>
          </Flex>
        )
    })

    return results;
  }

  

  const clearLines = async () => {
    setLoading(true);
    await connection.service('players').patch(null, { line: 0, position: null }, {
      query: {
        ownerId: currentUser.user._id
      }
    });
    load();
  }

  return (
    <div className='setLines'>
      <Modal opened={showPlayers} onClose={close} title="Players" centered>
        {renderPlayerOptions()}
      </Modal>

      <Container size="xs">
        <LoadingOverlay visible={loading} />
      
        <Title>Set Lines</Title>
        <Flex mt="1rem" gap="1rem" align="center">
          <Button size="xs" onClick={clearLines} disabled={locked}>Clear Lines</Button>
          <Text>Current Cost: {(players.filter(e => e.line > 0).length > 0) ? getTotalSalary(players.filter(e => e.line > 0)) : 0} / 100M</Text>
        </Flex>
        <Title my="1rem" size="1.5rem">Forwards</Title>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 1</Text>
          {getPlayerPosition(1, 'leftWing')}
          {getPlayerPosition(1, 'center')}
          {getPlayerPosition(1, 'rightWing')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 2</Text>
          {getPlayerPosition(2, 'leftWing')}
          {getPlayerPosition(2, 'center')}
          {getPlayerPosition(2, 'rightWing')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 3</Text>
          {getPlayerPosition(3, 'leftWing')}
          {getPlayerPosition(3, 'center')}
          {getPlayerPosition(3, 'rightWing')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 4</Text>
          {getPlayerPosition(4, 'leftWing')}
          {getPlayerPosition(4, 'center')}
          {getPlayerPosition(4, 'rightWing')}
        </Flex>
        <Title my="1rem" size="1.5rem">Defense</Title>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 1</Text>
          {getPlayerPosition(1, 'leftDefense')}
          {getPlayerPosition(1, 'rightDefense')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 2</Text>
          {getPlayerPosition(2, 'leftDefense')}
          {getPlayerPosition(2, 'rightDefense')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 3</Text>
          {getPlayerPosition(3, 'leftDefense')}
          {getPlayerPosition(3, 'rightDefense')}
        </Flex>
        <Title my="1rem" size="1.5rem">Goalies</Title>
        <Flex gap="0.5rem">
          <Text className='lineText'>Starter</Text>
          {getPlayerPosition(1, 'starter')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Backup</Text>
          {getPlayerPosition(1, 'backup')}
        </Flex>
      </Container>
    </div>
  )
}
