import React from 'react';
import './transition.scss';

export const TransitionOut = () => {
  return (
    <div className='transitionWrapper'>
      <div className='transitionOut'>
        <div className='scaleCardOut' style={{ transform: (window.innerHeight < window.innerWidth) ? "scale(" + window.innerHeight/2 + "px) rotate(90deg)" : "scale(" + window.innerHeight/2 + "px)"}}>
          <img src="/images/saihl/card.svg" alt="launch" />
        </div>
      </div>
    </div>
  )
}
