import React, { useContext, useEffect, useState } from 'react';
import { Button, Flex, Modal, Text } from '@mantine/core';
import { useNavigate } from 'react-router';

import { TransitionOut } from './Launch/TransitionOut';
import { TransitionIn } from './Launch/TransitionIn';

import './landing.scss';
import { useDisclosure } from '@mantine/hooks';
import connection, { PUBLIC_API } from '../feathers';
import { AuthContext } from '../Contexts';

export const Landing = () => {
  const navigate = useNavigate();
  const [transitionIn, setTransitionIn] = useState(true);
  const [transitionOut, setTransitionOut] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    const load = async () => {
      try {
        await connection.reAuthenticate();
        navigate('/dashboard');
      } catch (error) {
        //console.log(error);
      }
    }

    load();

    setTimeout(() => {
      setTransitionIn(false);
    }, 1500);
  }, [])

  const login = (route) => {
    close()
    setTransitionOut(true);

    setTimeout(() => {
      window.location.assign(PUBLIC_API + '/oauth/' + route);
    }, 1000);
  }

  return (
    <>
    <Modal opened={opened} onClose={close} centered withCloseButton={false}>
      <Flex direction="column" justify="center" gap="1rem">
        <Button size="xl" onClick={() => login('google')}>Login with Google</Button>
        <Button size="xl" onClick={() => login('discord')}>Login with Discord</Button>
      </Flex>
    </Modal>
    <div className='background landing'>
      <div className='logo'>
        <img src="/images/saihl/saihl_color.svg" alt="s_logo" />
      </div>
      <Text size="xl" className='fadeInText' my="1rem" align='center' w="300px" c="dimmed">
        Join a league, draft a team, beat your friends, and help me test my hockey sim.
      </Text>
      <Button onClick={open} color="brand" className='buttonTransition' my="3rem" size='xl'>JOIN NOW!</Button>
      <div onClick={open} className='loginLink'>Already have a team? Login Here</div>

      {(transitionIn) ? <TransitionIn /> : ''}
      {(transitionOut) ? <TransitionOut /> : ''}
    </div>
    </>
  )
}