export const RINK_WIDTH = 200;
export const RINK_HEIGHT = 85;
export const RESOLUTION = 5;

export const CENTER_ICE = {
  x: 100,
  y: 42.5
};
export const HOME_NET = {
  x: 15,
  y: 42.5
}
export const AWAY_NET = {
  x: 185,
  y: 42.5
}
export const HOME_BLUELINE = 70;
export const AWAY_BLUELINE = 130;
export const CORNER_RADIUS = 21;

export const FATIGUE_REDUCTION = 0.005;
export const MAX_SKATING_SPEED = 25;
export const MAX_PASS_SPEED = 80;
export const MAX_SHOOTING_SPEED = 125;
export const TOP_ACCELERATION = 17.5;
export const PLAYER_WEIGHT_SCALE = 0.5;

export const PUCK_FRICTION = 0.98;
export const SKATE_FRICTION = 0.95;


export const HOME_SCORING_POSITIONS = [
  { x: 0.75, y: 0, radius: 0.40, weight: 1 },
  { x: 0.65, y: -0.4, radius: 0.20, weight: 0.5 },
  { x: 0.65, y: 0, radius: 0.20, weight: 0.5 },
  { x: 0.65, y: 0.4, radius: 0.20, weight: 0.5 },
];
export const AWAY_SCORING_POSITIONS = [
  { x: -0.75, y: 0, radius: 0.20, weight: -1 },
  { x: -0.65, y: -0.4, radius: 0.10, weight: -0.5 },
  { x: -0.65, y: 0, radius: 0.10, weight: -0.5 },
  { x: -0.65, y: 0.4, radius: 0.10, weight: -0.5 },
];
