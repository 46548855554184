import { generateMap, generateScoringMap, mergeMaps, inverseMap } from "../generateMap";
import { calculatePuckBoardDeflections, getBoundedPosition, getDistance, getMagnitude, normalizeVector } from "../getDistance";
import { getFaceoffPosition, playerPositionList } from "../getPlayerLocation";
import Team from "./Team";
import { AWAY_NET, AWAY_SCORING_POSITIONS, HOME_NET, HOME_SCORING_POSITIONS, MAX_PASS_SPEED, MAX_SHOOTING_SPEED, MAX_SKATING_SPEED, PUCK_FRICTION, RESOLUTION, RINK_HEIGHT, RINK_WIDTH } from "./config";

export const runSimulation = async (homeData, awayData) => {
  let state = setupSimulation(homeData, awayData);

  console.log('-------------------------');
  let period = 1;
  const HomeScoringMap = generateScoringMap(HOME_SCORING_POSITIONS, RESOLUTION);
  const AwayScoringMap = generateScoringMap(AWAY_SCORING_POSITIONS, RESOLUTION);

  //for (let period = 1; period <= 3; period++) {
    console.log(` --- Period: ${period}`);
    state.period = period;
    
    let time = state.time = 0;
    state = actions['faceoff'](state, {x:0, y:0});

    let players = [
      state.home.active.leftWing,
      state.home.active.center,
      state.home.active.rightWing,
      state.home.active.leftDefense,
      state.home.active.rightDefense,
      state.away.active.leftWing,
      state.away.active.center,
      state.away.active.rightWing,
      state.away.active.leftDefense,
      state.away.active.rightDefense,
    ];
    
    state.state.push({
      time: ((state.period - 1) * 1200) + state.time,
      period: state.period,
      puck: structuredClone(state.puck.position),
      players: structuredClone([...players.map(player => {
        return {
          teamId: player.team.id,
          playerId: player.id,
          position: player.position,
        }
      })]),
    })

    
    let homePlayerMap = generateMap(state);
    let awayPlayerMap = generateMap(state);
    let homeMap = mergeMaps([
      { value: homePlayerMap, weight: 0.5 },
      { value: HomeScoringMap, weight: 3 },
      { value: inverseMap(awayPlayerMap), weight: 1}
    ]);
    
    let awayMap = mergeMaps([
      { value: awayPlayerMap, weight: 0.5 },
      { value: AwayScoringMap, weight: 3 },
      { value: inverseMap(homePlayerMap), weight: 1}
    ]);

    while (time < 300) {
      if (Math.round(time * 100) / 100 % 5 === 0) {
        console.log(` --- Time: ${time}`);
      
        homeMap = mergeMaps([
          { value: homePlayerMap, weight: 0.5 },
          { value: HomeScoringMap, weight: 3 },
          { value: inverseMap(awayPlayerMap), weight: 1}
        ]);
        
        awayMap = mergeMaps([
          { value: awayPlayerMap, weight: 0.5 },
          { value: AwayScoringMap, weight: 3 },
          { value: inverseMap(homePlayerMap), weight: 1}
        ]);
      }

      players = [
        state.home.active.leftWing,
        state.home.active.center,
        state.home.active.rightWing,
        state.home.active.leftDefense,
        state.home.active.rightDefense,
        state.home.active.goaltender,
        state.away.active.leftWing,
        state.away.active.center,
        state.away.active.rightWing,
        state.away.active.leftDefense,
        state.away.active.rightDefense,
        state.away.active.goaltender,
      ];

      const originalPuckPlayerId = state.puck.player ? state.puck.player.id : '';
      // Puck carrier thinks and then acts
      if (state.puck.player) {
        state = state.puck.player.resolve(state);
        
        state = state.puck.player.think(state,
          state.puck.player.team.id === state.home.id ? homeMap : awayMap,
          state.puck.player.team.id === state.home.id ? HomeScoringMap : AwayScoringMap
        );
      }

      for (const player of players) {
        if (!state.puck.player || (state.puck.player && player.id !== state.puck.player.id)) {
          
          state = player.resolve(state);
          state = player.think(state, homeMap);
        }
      }

      if (state.puck.player && state.puck.state === 'carried')
        state = actions[state.puck.player.selectedAction.action](state, state.puck.player);

      for (const player of players) {
        if (state.puck.player && (player.id !== state.puck.player.id || player.id !== originalPuckPlayerId)){
          if (reactions[player.selectedAction.action])
            state = reactions[player.selectedAction.action](state, player);
          else console.log('No reaction for', player.selectedAction.action);
        }

        state = player.move(state);
        state = player.updateFatigue(state);
      }

      state = updatePuck(state);

      state = state.home.resolve(state);
      state = state.away.resolve(state);

      // Record the current gameState
      // Should I optimize this to only record when the players or puck are in a new position?
      
      state.time += state.delta;
      time = state.time;

      state.state.push({
        time: ((state.period - 1) * 1200) + state.time,
        period: state.period,
        action: state.puck.player ? state.puck.player.selectedAction.action : null,
        target: state.puck.player ? state.puck.player.selectedAction.target : null,
        puck: structuredClone(state.puck.position),
        players: structuredClone([...players.map(player => {
          return {
            teamId: player.team.id,
            playerId: player.id,
            position: player.position,
          }
        })]),
      })

    //}

    //state.home.reset(state);
    //state.away.reset(state);
  }

  console.log('-------------------------');
  console.log('Final State:', state);

  return state;
};

const actions = {
  faceoff: (state, location) => {
    // Set the players positions
    for (const type of playerPositionList) {
      state.home.active[type].position = getFaceoffPosition(location, type, false);
    }
    state.home.active.goaltender.position = HOME_NET;

    for (const type of playerPositionList) {
      state.away.active[type].position = getFaceoffPosition(location, type, true);
    }
    state.away.active.goaltender.position = AWAY_NET;


    // opposing checks to see who wins
    // give a small advantage for handedness?
    let winner = null
    if (Math.random() < state.home.active.center.skills.faceoff / state.home.active.center.skills.faceoff + state.away.active.center.skills.faceoff) {
      winner = state.home.active.center;
    } else {
      winner = state.away.active.center;
    }

    // set the puck carrier
    state.puck.player = winner;
    state.puck.position = winner.position;
    state.puck.velocity = { x: 0, y: 0 };
    state.puck.touches = [winner.id];
    state.puck.state = 'carried';

    return state;
  },
  skate: (state, player) => {
    return skate(state, player);
  },
  pass: (state, player) => {
    let passDirection = {
      x: player.selectedAction.target.x - player.position.x,
      y: player.selectedAction.target.y - player.position.y,
    };

    passDirection = normalizeVector(passDirection);
  
    // calculate the pass speed based on the player's passing skill
    let passSpeed = player.skills.passingPower * MAX_PASS_SPEED;
  
    // calculate the pass velocity
    let passVelocity = {
      x: passDirection.x * passSpeed * state.delta,
      y: passDirection.y * passSpeed * state.delta
    };
  
    // apply the pass velocity to the puck
    player.selectedAction.confidence = 0;
    player.selectedAction.action = 'idle';
    state.puck.velocity = passVelocity;

    // Test is the pass is successful
    // if its not shift the velocity to the left or right

    // should I set the receiver to a 'Receive' reaction?
    state.puck.player.selectedAction.targetPlayer.selectedAction = {
      action: 'receive',
      target: player.selectedAction.target,
      confidence: 1
    };
    state.puck.state = 'pass';

    recordEvent(state, {
      player: state.puck.player.id,
      target: state.puck.player.selectedAction.targetPlayer.id,
      action: 'pass'
    });
    return state;
  },
  shoot: (state, player) => {
    return shoot(state, player);
  },
  dump: (state) => {
    recordEvent(state, {
      player: state.puck.player.id,
      action: 'dump'
    });
    return state;
  },
  clear: (state) => {
    recordEvent(state, {
      player: state.puck.player.id,
      action: 'clear'
    });
    return state;
  },
  idle: (state) => {
    return state;
  }
}

const reactions = {
  skate: (state, player) => {
    return skate(state, player);
  },
  challenge: (state, player) => {
    // move towards the hit
    return state;
  },
  intercept: (state, player) => {
    // move into the line of the pass
    return state;
  },
  recover: (state, player) => {
    // move towards the puck
    // set the target to the puck
    // May need to update the state?
    const distance = getDistance(state.puck.position, player.position);

    // now we need to see what the max distance the player can move this tick
    const maxDistance = player.skills.skatingSpeed * MAX_SKATING_SPEED * state.delta;

    // if the distance is less than the max distance, set the players velocity to hit the target
    if (distance < maxDistance) {

      // Probably want to check if there are other players close and doing the same thing

      // set the players velocity to hit the target
      player.velocity = {
        x: player.selectedAction.target.x - player.position.x,
        y: player.selectedAction.target.y - player.position.y
      }
      
      // Setup the puck
      state.puck.player = player;
      state.puck.state = 'carried';
      state.puck.velocity = { x: 0, y: 0 };
      state.puck.position = player.position;

      // Make the player think about their next action
      player.selectedAction.confidence = 0;
      player.selectedAction.action = 'idle';
    } else {
      const futurePuckPosition = {
        x: state.puck.position.x + state.puck.velocity.x,
        y: state.puck.position.y + state.puck.velocity.y
      };
      player.selectedAction.target = futurePuckPosition;
      state = skate(state, player);
    }
    return state;
  },
  receive: (state, player) => {
    // move towards the puck
    // can the player reach the target this tick?
    // calculate the distance to the target
    const distance = getDistance(player.selectedAction.target, player.position);

    // now we need to see what the max distance the player can move this tick
    const maxDistance = player.skills.skatingSpeed * MAX_SKATING_SPEED * state.delta;

    // if the distance is less than the max distance, set the players velocity to hit the target
    if (distance < maxDistance) {
      // set the players velocity to hit the target
      player.velocity = {
        x: player.selectedAction.target.x - player.position.x,
        y: player.selectedAction.target.y - player.position.y
      }
      
      // Setup the puck
      state.puck.player = player;
      state.puck.state = 'carried';
      state.puck.velocity = { x: 0, y: 0 };
      state.puck.position = player.position;

      // Make the player think about their next action
      player.selectedAction.confidence = 0;
      player.selectedAction.action = 'idle';
    } else {
      // set the velocity to move towards the receive target
      state = skate(state, player);
    }
      
    return state;
  },
  deflect: (state, player) => {
    return state;
  },
  save: (state, player) => {
    return state;
  },
  change: (state, player) => {
    // move towards the bench?
    // set the actionTimeout and teh actionCallback
    return state;
  },
  idle: (state, player) => {
    return state;
  }
}

const skate = (state, player) => {
  //if (player.linePosition === 'leftDefense') console.log('Skating Target', player.selectedAction.target)

  // Calculate the direction and distance to the target.
  let directionToTarget = { 
    x: player.selectedAction.target.x - player.position.x,
    y: player.selectedAction.target.y - player.position.y
  };
  let distance = getDistance(player.position, player.selectedAction.target);

  // now we need to see what the max distance the player can move this tick
  const maxDistance = player.skills.skatingSpeed * MAX_SKATING_SPEED * state.delta;

  // if the distance is less than the max distance, set the players velocity to hit the target
  if (distance < maxDistance) {
    // set the players velocity to hit the target
    player.velocity = {
      x: player.selectedAction.target.x - player.position.x,
      y: player.selectedAction.target.y - player.position.y
    }
    
    player.selectedAction.confidence = 0;
    player.selectedAction.action = 'idle';

    return state;
  }

  // Calculate desired direction and maximum speed.
  let desiredDirection = normalizeVector(directionToTarget);
  let maxSpeed = player.skills.skatingSpeed * MAX_SKATING_SPEED;

  // Reduce the velocity if the player is stopping or turning
  const currentPlayerDirection = normalizeVector(player.velocity);
  const currentPlayerMagnitude = getMagnitude(player.velocity);
  const desiredMagnitude = getMagnitude(desiredDirection);

  if (currentPlayerMagnitude > 0 && desiredMagnitude > 0) {
    const angleReduction = (currentPlayerDirection.x * desiredDirection.x + currentPlayerDirection.y * desiredDirection.y) /
      (currentPlayerMagnitude * desiredMagnitude);

    player.velocity.x *= 0.7 + 0.3 * (1 - ((angleReduction + 1) / 2));
    player.velocity.y *= 0.7 + 0.3 * (1 - ((angleReduction + 1) / 2));
  }

  // Calculate the maximum velocity the player could reach this frame.
  let maxFrameVelocity = {
    x: player.velocity.x + desiredDirection.x * maxSpeed * state.delta,
    y: player.velocity.y + desiredDirection.y * maxSpeed * state.delta
  };
  
  // If within stopping distance, adjust velocity so player will stop at the target.
  if (distance < maxSpeed * state.delta) {
    let requiredVelocity = {
      x: directionToTarget.x / state.delta,
      y: directionToTarget.y / state.delta
    };
    maxFrameVelocity = requiredVelocity;
  }

  // Limit the new velocity to the player's maximum speed.
  let currentSpeed = getMagnitude(maxFrameVelocity);
  if (currentSpeed > maxSpeed) {
    maxFrameVelocity = {
      x: (maxFrameVelocity.x / currentSpeed) * maxSpeed,
      y: (maxFrameVelocity.y / currentSpeed) * maxSpeed
    };
  }

  // Update the player's velocity.
  player.velocity = maxFrameVelocity;

  // Record the event.
  if (player.id === state.puck.player.id) {
    recordEvent(state, {
      player: state.puck.player.id,
      target: state.puck.player.selectedAction.target,
      action: 'skate'
    });
  }

  return state;
}

const shoot = (state, player) => {
  if (state.puck.state === 'shot') return state;

  const skill = state.puck.player.skills.shootingSkill;
  const shotSpeed = MAX_SHOOTING_SPEED * state.puck.player.skills.shootingPower;

  //if (Math.random() <= skill) { // On goal
    let netDirectionVector = {
      x: player.selectedAction.target.x - state.puck.position.x,
      y: player.selectedAction.target.y - state.puck.position.y
    }

    // normalize the vector
    const magnitude = Math.sqrt(netDirectionVector.x * netDirectionVector.x + netDirectionVector.y * netDirectionVector.y);
    netDirectionVector.x /= magnitude;
    netDirectionVector.y /= magnitude;

    // set the puck velocity
    state.puck.velocity = {
      x: netDirectionVector.x * shotSpeed * state.delta,
      y: netDirectionVector.y * shotSpeed * state.delta
    }
  /*} else {
    let directionVector = {
      x: state.puck.player.selectedAction.target.x - state.puck.position.x,
      y: state.puck.player.selectedAction.target.y - state.puck.position.y
    }

    // shift the aim direction of directionVector by 7.5 degrees in either direction
    const theta = (Math.PI / 24) * Math.random();
    directionVector.x = directionVector.x * Math.cos(theta) - directionVector.y * Math.sin(theta);
    directionVector.y = directionVector.x * Math.sin(theta) + directionVector.y * Math.cos(theta);
    
    // set the puck velocity
    state.puck.velocity = {
      x: directionVector.x * shotSpeed * state.delta,
      y: directionVector.y * shotSpeed * state.delta
    }
  }*/

  // Set the puck settings and the carrier to idle
  state.puck.player.selectedAction.confidence = 0;
  state.puck.player.selectedAction.action = 'idle';
  state.puck.state = 'shot';
  
  recordEvent(state, {
    player: player.id,
    action: 'shot'
  });
  return state;
}


const setupSimulation = (homeData, awayData) => {
  let home = new Team(homeData._id, homeData.name, homeData.forwardLines, homeData.defenseLines, homeData.starter, homeData.backup, homeData.coaches, homeData.strategy);
  let away = new Team(awayData._id, awayData.name, awayData.forwardLines, awayData.defenseLines, awayData.starter, awayData.backup, awayData.coaches, awayData.strategy);

  home.stats = away.stats = {
    goals: 0,
    saves: 0,
    shots: 0,
    hits: 0,
    blocks: 0,
    pokecheck: 0,
    interceptions: 0,
    takeaways: 0,
    rebounds: 0,
    offsides: 0,
    icings: 0,
    deflection: 0,
    faceoffWins: 0,
  }

  return {
    delta: 0.1,
    period : 1,
    time: 0,
    momentum: 0,
    home,
    away,
    events: [],
    state: [],
    puck: {
      position: {
        x: 0,
        y: 0,
      },
      velocity: {
        x: 0,
        y: 0,
      },
      state: 'faceoff',
      player: null,
      target: null,
      touches: [],
    },
  }
}

const updatePuck = (state) => {
  if (state.puck.state === 'carried') {
    state.puck.position = state.puck.player.position;
    state.puck.velocity = state.puck.player.velocity;
    return state;
  }

  // Cap the puck velocity
  const puckSpeed = getMagnitude(state.puck.velocity);
  if (puckSpeed > MAX_SHOOTING_SPEED) {
    state.puck.velocity = {
      x: (state.puck.velocity.x / puckSpeed) * MAX_SHOOTING_SPEED,
      y: (state.puck.velocity.y / puckSpeed) * MAX_SHOOTING_SPEED
    };
  }

  state.puck.position.x += state.puck.velocity.x;
  state.puck.position.y += state.puck.velocity.y;

  const adjusted = calculatePuckBoardDeflections(state.puck);

  state.puck.position = adjusted.position;
  state.puck.velocity = adjusted.velocity;

  state.puck.velocity.x *= PUCK_FRICTION;
  state.puck.velocity.y *= PUCK_FRICTION;

  return state;
}

const recordEvent = (state, data) => {
  const event = {
    time: ((state.period - 1) * 1200) + state.time,
    ...data
  }
  state.events.push(event);
}
