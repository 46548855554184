import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text, Title } from '@mantine/core';

import connection from '../../feathers';
import { AuthContext } from '../../Contexts';
import { RosterPlayer } from './RosterPlayer';
import { convertPlayerObject } from '../../lib/convertPlayerObject';
import { getSalary } from '../../lib/getSalary';

export const ManageRoster = () => {
  const [players, setPlayers] = useState([]);
  const [sortType, setSortType] = useState('ovr');
  const [ascending, setAscending] = useState(false);
  const [user, setUser] = useState(null);

  const load = async () => {
    // listen on player patch and refresh roster
    /*connection.service('players').on('patched', (res) => {
      if (res.ownerId === currentUser.user._id || res.previousOwner === currentUser.user._id) {
        load();
      }
    })*/
    const res = await connection.get('authentication');
    setUser(res.user);

    let players = await connection.service('players').find({
      paginate: false,
      query: {
        $limit: 100,
        ownerId: res.user._id
      }
    })
    setPlayers(convertPlayerObject(players.data));
  }

  useEffect(() => {
    load();
  }, [])

  //render a list of roster players
  const renderPlayers = () => {
    let list = sort();
    return list.map((player, index) => { return <RosterPlayer key={player._id} index={index} player={player} update={load} user={user} /> })
  }

  const sort = () => {
    let sorted = players;
    switch(sortType) {
      case 'name':
        sorted = players.sort((a, b) => (a.bio.getName() < b.bio.getName()) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'type':
        sorted = players.sort((a, b) => (a.type.abbr < b.type.abbr) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'pos':
        sorted = players.sort((a, b) => (a.type.role.abbr < b.type.role.abbr) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'ovr':
        sorted = players.sort((a, b) => (a.overall() < b.overall()) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'mnt':
        sorted = players.sort((a, b) => (a.overall('mental') < b.overall('mental')) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'ath':
        sorted = players.sort((a, b) => (a.overall('athletic') < b.overall('athletic')) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'tec':
        sorted = players.sort((a, b) => (a.overall('technique') < b.overall('technique')) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'sht':
        sorted = players.sort((a, b) => (a.overall('shooting') < b.overall('shooting')) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'def':
        sorted = players.sort((a, b) => (a.overall('defense') < b.overall('defense')) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'rfx':
        sorted = players.sort((a, b) => (a.overall('reflexes') < b.overall('reflexes')) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      case 'salary':
        sorted = players.sort((a, b) => (getSalary(a) < getSalary(b)) ? ((ascending) ? -1 : 1) : ((ascending) ? 1 : -1))
        break;
      default:
        break;
    }
    return sorted;
  }

  const handleSort = (type) => {
    if (sortType === type) {
      setAscending(!ascending);
    } else {
      setSortType(type);
    }
  }
  
  return (
    <div>
      <Title className='manageRosterTopInfo'>Manage Roster</Title>
      <Text className='manageRosterTopInfo' my="0.5rem">Contracts: {players.length}/35</Text>
      <div className='ManageRosterHeader'>
        <div className='ManageRosterHeaderItem hideNarrow'></div>
        <div className='ManageRosterHeaderItem'></div>
        <div className='ManageRosterHeaderItem' onClick={() => handleSort('name')}>Name</div>
        {/*<div className='ManageRosterHeaderItem centered'>Contract</div>*/}
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('type')}>Type</div>
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('pos')}>POS</div>
        <div className='ManageRosterHeaderItem highlighted centered' onClick={() => handleSort('ovr')}>OVR</div>
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('mnt')}>MNT</div>
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('ath')}>ATH</div>
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('tec')}>TEC</div>
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('sht')}>SHT</div>
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('def')}>DEF</div>
        <div className='ManageRosterHeaderItem centered hideNarrow' onClick={() => handleSort('rfx')}>RFX</div>
        <div className='ManageRosterHeaderItem centered' onClick={() => handleSort('salary')}>Salary</div>
        <div className='ManageRosterHeaderItem'></div>
      </div>
      {renderPlayers()}
    </div>
  )
}