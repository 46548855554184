import { generateScoringMap, getCell } from "./generateMap";
import { getBoundedPosition, getClosestPointAlongLine, getDistance } from "./getDistance";
import { AWAY_BLUELINE, AWAY_NET, AWAY_SCORING_POSITIONS, CENTER_ICE, HOME_BLUELINE, HOME_NET, HOME_SCORING_POSITIONS, MAX_SKATING_SPEED, RESOLUTION, RINK_HEIGHT, RINK_WIDTH } from "./simulation/config";

export const playerPositionList = [
  "leftWing",
  "rightWing",
  "center",
  "leftDefense",
  "rightDefense"
]

const OffensiveZoneFormations = {
  home: {
    CrashTheNet: {
      leftWing: { x: 175, y: 36.125 },
      rightWing: { x: 175, y: 48.875 },
      center: { x: 165, y: 42.5 },
      leftDefense: { x: 135, y: 38.25 },
      rightDefense: { x: 135, y: 46.75 }
    },
    BehindTheNet: {
      leftWing: { x: 165, y: 31.875 },
      rightWing: { x: 165, y: 53.125 },
      center: { x: 195, y: 42.5 },
      leftDefense: { x: 135, y: 34 },
      rightDefense: { x: 135, y: 51 }
    },
    HighForward: {
      leftWing: { x: 175, y: 23.375 },
      rightWing: { x: 175, y: 61.625 },
      center: { x: 160, y: 42.5 },
      leftDefense: { x: 135, y: 29.75 },
      rightDefense: { x: 135, y: 55.25 }
    }
  },
  away: {
    CrashTheNet: {
      leftWing: { x: 25, y: 48.875 },
      rightWing: { x: 25, y: 36.125 },
      center: { x: 35, y: 42.5 },
      leftDefense: { x: 65, y: 46.75 },
      rightDefense: { x: 65, y: 38.25 }
    },
    BehindTheNet: {
      leftWing: { x: 35, y: 53.125 },
      rightWing: { x: 35, y: 31.875 },
      center: { x: 5, y: 42.5 },
      leftDefense: { x: 65, y: 51 },
      rightDefense: { x: 65, y: 34 }
    },
    HighForward: {
      leftWing: { x: 25, y: 61.625 },
      rightWing: { x: 25, y: 23.375 },
      center: { x: 40, y: 42.5 },
      leftDefense: { x: 65, y: 55.25 },
      rightDefense: { x: 65, y: 29.75 }
    },
  }
}

const NeutralZoneOffensiveFormations = {
  home: {
    leftWing: { x: 125, y: 10.625 },
    rightWing: { x: 125, y: 74.375 },
    center: { x: 110, y: 42.5 },
    leftDefense: { x: 70, y: 25.5 },
    rightDefense: { x: 70, y: 59.5 }
  },
  away: {
    leftWing: { x: 75, y: 74.375 },
    rightWing: { x: 75, y: 10.625 },
    center: { x: 90, y: 42.5 },
    leftDefense: { x: 130, y: 59.5 },
    rightDefense: { x: 130, y: 25.5 }
  },
}

const FaceoffOffset = {
  home: {
    leftWing: { x: -0.04, y: -0.3 },
    rightWing: { x: -0.04, y: 0.3 },
    center: { x: -0.04, y: 0 },
    leftDefense: { x: -0.2, y: -0.2 },
    rightDefense: { x: -0.2, y: 0.2 }
  },
  away: {
    leftWing: { x: 0.04, y: 0.3 },
    rightWing: { x: 0.04, y: -0.3 },
    center: { x: 0.04, y: 0 },
    leftDefense: { x: 0.2, y: 0.2 },
    rightDefense: { x: 0.2, y: -0.2 }
  }
}

const CollapsingPositions = {
  home: {
    leftWing: { x: 35, y: 34 },
    rightWing: { x: 35, y: 51 },
    center: { x: 50, y: 42.5 },
    leftDefense: { x: 20, y: 36.125 },
    rightDefense: { x: 20, y: 48.975 }
  },
  away: {
    leftWing: { x: 165, y: 51 },
    rightWing: { x: 165, y: 34 },
    center: { x: 150, y: 42.5 },
    leftDefense: { x: 180, y: 48.975 },
    rightDefense: { x: 180, y: 36.125 }
  }
};

const NeutralZoneFormations = {
  home: {
    "OneFour": {
      leftWing: { x: 70, y: 14.875 },
      rightWing: { x: 70, y: 70.125 },
      center: { x: 130, y: 42.5 },
      leftDefense: { x: 70, y: 34 },
      rightDefense: { x: 70, y: 51 }
    },
    "OneTwoTwoRed": {
      leftWing: { x: 100, y: 31.875 },
      rightWing: { x: 100, y: 53.125 },
      center: { x: 145, y: 42.5 },
      leftDefense: { x: 72.5, y: 27.675 },
      rightDefense: { x: 72.5, y: 57.375 }
    },
    "OneTwoTwoBlue": {
      leftWing: { x: 128, y: 27.675 },
      rightWing: { x: 128, y: 57.375 },
      center: { x: 160, y: 42.5 },
      leftDefense: { x: 100, y: 31.875 },
      rightDefense: { x: 100, y: 53.125 }
    },
    "OneThreeOne": {
      leftWing: { x: 92.5, y: 21.25 },
      rightWing: { x: 92.5, y: 63.75 },
      center: { x: 140, y: 42.5 },
      leftDefense: { x: 82.5, y: 42.5 },
      rightDefense: { x: 60, y: 42.5 }
    }
  },
  away: {
    "OneFour": {
      leftWing: { x: 130, y: 70.125 },
      rightWing: { x: 130, y: 14.875 },
      center: { x: 70, y: 42.5 },
      leftDefense: { x: 130, y: 51 },
      rightDefense: { x: 130, y: 34 }
    },
    "OneTwoTwoRed": {
      leftWing: { x: 100, y: 53.125 },
      rightWing: { x: 100, y: 31.875 },
      center: { x: 65, y: 42.5 },
      leftDefense: { x: 127.5, y: 57.375 },
      rightDefense: { x: 127.5, y: 27.675 }
    },
    "OneTwoTwoBlue": {
      leftWing: { x: 72, y: 57.375 },
      rightWing: { x: 72, y: 27.675 },
      center: { x: 40, y: 42.5 },
      leftDefense: { x: 100, y: 53.125 },
      rightDefense: { x: 100, y: 31.875 }
    },
    "OneThreeOne": {
      leftWing: { x: 117.5, y: 63.75 },
      rightWing: { x: 117.5, y: 21.25 },
      center: { x: 60, y: 42.5 },
      leftDefense: { x: 117.5, y: 42.5 },
      rightDefense: { x: 140, y: 42.5 }
    }
  }
}

export const getFaceoffPosition = (faceoffLocation, linePosition, inverse) => {
  const offset = FaceoffOffset[inverse ? "away" : "home"];
  
  return convertToRink({
    x: offset[linePosition].x + faceoffLocation.x,
    y: offset[linePosition].y + faceoffLocation.y
  });
}

export function getOffensivePosition(puckPosition, selectedPlayer, offenseStrategy, inverse) {
  //console.log(offenseStrategy);
  let strategy = OffensiveZoneFormations[inverse ? "away" : "home"][offenseStrategy.offensiveStrategy];
  if ((puckPosition.x < AWAY_BLUELINE && !inverse) || (puckPosition.x > HOME_BLUELINE && inverse))
    strategy = NeutralZoneOffensiveFormations[inverse ? "away" : "home"];
    
  const playerPositionLocations = {
    leftWing: { x: 0, y: 0 },
    rightWing: { x: 0, y: 0 },
    center: { x: 0, y: 0 },
    leftDefense: { x: 0, y: 0 },
    rightDefense: { x: 0, y: 0 }
  }
  for (const type in strategy) {
    playerPositionLocations[type] = {
      x: strategy[type].x,
      y: strategy[type].y + (strategy[type].y - puckPosition.y) * 0.2
    };
  }

  return playerPositionLocations[selectedPlayer.linePosition];


  // Compute distances for each player to the puck
  /*const distances = [];
  for (const player in playerPositionLocations) {
    const playerPosition = playerPositionLocations[player];
    const dx = puckPosition.x - playerPosition.x;
    const dy = puckPosition.y - playerPosition.y;
    distances.push({
      player: player,
      distance: Math.sqrt(dx * dx + dy * dy)
    })
  }

  // Identify player closest to the puck
  const closestPlayer = distances.reduce((a, b) =>
    a.distance < b.distance ? a : b
  );


  // Position closest player at the puck's position
  const newPosition = strategy;
  console.log('newPosition', newPosition);
  newPosition[closestPlayer] = { ...puckPosition };

  // Adjust defense player's position on the side of the puck if they are not the one with the puck
  const defensePlayer = puckPosition.y < CENTER_ICE.y ? (inverse ? "leftDefense" : "rightDefense") : (inverse ? "rightDefense" : "leftDefense");
  if (
    defensePlayer !== closestPlayer &&
    (closestPlayer === "leftWing" ||
      closestPlayer === "rightWing" ||
      closestPlayer === "center")
  ) {
    newPosition[defensePlayer] = {
      ...newPosition[defensePlayer],
      y: puckPosition.y
    };
  }
*/
  // Adjust players on the same side of the puck to shift towards the puck
  let newPosition = { x:0, y:0 };
  for (const player in playerPositionLocations) {
    newPosition.x = playerPositionLocations[player].x + (puckPosition.x - playerPositionLocations[player].x) * 0.25;
    newPosition.y = playerPositionLocations[player].y + (puckPosition.y - playerPositionLocations[player].y) * 0.25;
  }

  //console.log('puckPosition', puckPosition);
  if ((selectedPlayer === "leftDefense" && puckPosition.y > CENTER_ICE.y) ||
    (selectedPlayer === "rightDefense" && puckPosition.y <= CENTER_ICE.y)) {
      newPosition.x = playerPositionLocations[selectedPlayer].x + (puckPosition.x - playerPositionLocations[selectedPlayer].x) * 0.3;
      
  }

  // Return the position of the selected player
  console.log('Offensive Zone: ', newPosition);
  return newPosition;
}

function calculateRisk(player, puck, inverse) {
  // For simplicity, we'll just use the player's distance to the net and distance to the puck
  let distanceToNet = Math.hypot(
    (!inverse ? HOME_NET.x : AWAY_NET.x) - Math.abs(player.position.x),
    (!inverse ? HOME_NET.y : AWAY_NET.y) - Math.abs(player.position.y)
  );
  
  const HomeScoringMap = generateScoringMap(HOME_SCORING_POSITIONS, RESOLUTION);
  const AwayScoringMap = generateScoringMap(AWAY_SCORING_POSITIONS, RESOLUTION);

  const scoringMap = inverse ? AwayScoringMap : HomeScoringMap;

  let distanceToPuck = Math.hypot(
    player.position.x - puck.position.x,
    player.position.y - puck.position.y
  );

  // Add risk if the player is in the slot with the puck
  let highRisk = 1;
  if (Math.abs(player.position.x) < 0.9 && Math.abs(player.position.x) > 0.65 && Math.abs(player.position.y) < 0.4)
    highRisk *= 1.25;

  if (isPuckCarrier(player, puck)) highRisk *= 1.5;

  if (inverse) {
    if (player.position.x < AWAY_BLUELINE) highRisk *= 0.75;
    // get scoring map value and increase the risk based on that
    const mapValue = Math.max(0, getCell(scoringMap, player.position)) / 2;
    highRisk *= (1 + mapValue);
  }

  // We'll define risk as a simple combination of these two factors
  // You could use more sophisticated calculations if desired
  const risk = (distanceToNet + (distanceToPuck * 0.3)) * highRisk;
  ////console.log(player.playerPosition, distanceToNet.toFixed(2), risk.toFixed(2))
  return risk;
}

function findHighestRiskOpponent(opposingPlayers, puck) {
  let highestRiskOpponent = opposingPlayers[0];
  let highestRisk = calculateRisk(highestRiskOpponent, puck);

  for (let i = 1; i < opposingPlayers.length; i++) {
    let currentRisk = calculateRisk(opposingPlayers[i], puck);
    if (currentRisk > highestRisk) {
      highestRisk = currentRisk;
      highestRiskOpponent = opposingPlayers[i];
    }
  }

  return highestRiskOpponent;
}

function assignOpponents(opposingPlayers, puck, inverse) {
  let assignments = {};

  // Check which side the puck is on
  //console.log(puck);
  let defensiveSide = puck.position.y >= CENTER_ICE.y ? "rightDefense" : "leftDefense";
  if (inverse) defensiveSide = puck.position.y >= CENTER_ICE.y ? "leftDefense" : "rightDefense";

  // Assign the defensemen on the same side as the puck to the highest risk opponent (the puck carrier)
  let opponentCandidates = opposingPlayers.filter(opponent => (opponent.position.y >= CENTER_ICE.y) === (defensiveSide === "rightDefense"));
  if (!opponentCandidates || opponentCandidates.length <= 0) opponentCandidates = opposingPlayers;
  let highestRiskOpponent = findHighestRiskOpponent(opponentCandidates, puck);
  assignments[defensiveSide] = highestRiskOpponent;

  // Remove this opponent from the list of opponents
  opposingPlayers = opposingPlayers.filter((opponent) => opponent !== highestRiskOpponent);

  // Assign the other defensemen to the next highest risk opponent
  opponentCandidates = opposingPlayers.filter(opponent => (opponent.y < CENTER_ICE.y) === (defensiveSide === "rightDefense"));
  if (!opponentCandidates || opponentCandidates.length <= 0) opponentCandidates = opposingPlayers;
  
  highestRiskOpponent = findHighestRiskOpponent(opponentCandidates, puck);
  assignments[defensiveSide === "rightDefense" ? "leftDefense" : "rightDefense"] = highestRiskOpponent;
  opposingPlayers = opposingPlayers.filter((opponent) => opponent !== highestRiskOpponent);
  
  let positionList = ["rightWing", "center", "leftWing"];
  if (defensiveSide === "rightDefense") positionList.reverse();

  // Assign the center and wingers to the closest remaining opponents
  for (let playerPosition of positionList) {
    let player = CollapsingPositions[inverse ? "away" : "home"][playerPosition];
    let closestOpponent = null;
    let closestDistance = Infinity;

    for (let opponent of opposingPlayers) {
      let distance = Math.hypot(player.x - opponent.position.x, player.y - opponent.position.y);
      if (distance < closestDistance) {
        closestOpponent = opponent;
        closestDistance = distance;
      }
    }

    if (closestOpponent) {
      assignments[playerPosition] = closestOpponent;
      opposingPlayers = opposingPlayers.filter((opponent) => opponent !== closestOpponent);
    }
  }

  return assignments;
}

function assignNeutralZoneOpponents(player, playerPositions, opposingPlayers, puck, pressure) {
  let assignments = {};
  opposingPlayers = opposingPlayers.filter(o => o.team.id !== player.team.id);
  //console.log('playerPositions', playerPositions);

  for (let position in playerPositions) {
    // Get the coordinates of the current player
    let playerCoordinates = {...playerPositions[position]};
    //console.log('playerCoordinates', playerCoordinates);

    let closestOpponent = null;
    let priority = Infinity;

    // Loop through all the opposing players
    for (let i = 0; i < opposingPlayers.length; i++) {
      const opponent = opposingPlayers[i];
      //console.log('playerCoordinates', opponent.position);
      
      // Calculate the distance to the opponent
      let distance = getDistance(playerCoordinates, opponent.position);
      let threat = Math.max(0, Math.min(1, 1 - (distance / 100)));

      if (pressure > 0.6 && isPuckCarrier(opponent, puck)) {
        if ((position === 'leftWing' && puck.position.y < 24.5) ||
          (position === 'rightWing' && puck.position.y > 60.5)) {
          threat *= 1.25;
        }
      }
      if (['leftDefense', 'rightDefense'].includes(position)) {
        threat = threat * threat;
      }

      //console.log(puck)

      if ((position === 'leftWing' && puck.position.y < 42.5) ||
      (position === 'rightWing' && puck.position.y >= 42.5)) {
        threat *= 1.25;
      }

      // If this opponent is closer than the currently closest known opponent, update the closest opponent
      if (threat < priority) {
        closestOpponent = playerCoordinates;
        priority = threat;
      }
    
    }

    //console.log('assignNeutralZoneOpponents', position, closestOpponent, priority);

    // Assign the closest opponent to this player
    assignments[position] = closestOpponent;
  }

  return assignments;
}

export function getDefensivePosition(player, opposingPlayers, strategy, puck, inverse) {
  opposingPlayers = [
    opposingPlayers.leftWing,
    opposingPlayers.center,
    opposingPlayers.rightWing,
    opposingPlayers.leftDefense,
    opposingPlayers.rightDefense,
  ]
  //console.log(strategy);
  
  const net = inverse ? AWAY_NET : HOME_NET;
  if ((!inverse && puck.position.x > HOME_BLUELINE) ||
    (inverse && puck.position.x < AWAY_BLUELINE)) {
    const assignments = assignNeutralZoneOpponents(
      player, {...NeutralZoneFormations[inverse ? "away" : "home"][strategy.defensiveStrategy]},
      opposingPlayers, puck, strategy.pressure);
    
    let closestOpposingPlayer = assignments[player.linePosition];

    //console.log('Closest in Neutral', assignments, player.linePosition);

    // If no opponents are left, don't change position
    if (!closestOpposingPlayer) {
      console.log('No Closest in Def', NeutralZoneFormations[inverse ? "away" : "home"][strategy.defensiveStrategy][player.linePosition]);
      return {...(NeutralZoneFormations[inverse ? "away" : "home"][strategy.defensiveStrategy][player.linePosition])};
    }

    let position = {...(NeutralZoneFormations[inverse ? "away" : "home"][strategy.defensiveStrategy][player.linePosition])};
    if (puck.position.y < CENTER_ICE.y && strategy.defensiveStrategy === 'OneThreeOne') {
      // flip the left and right defense positions
      if (player.linePosition === 'leftDefense') {
        position = {...(NeutralZoneFormations[inverse ? "away" : "home"][strategy.defensiveStrategy]['rightDefense'])};
      } else if (player.linePosition === 'rightDefense') {
        position = {...(NeutralZoneFormations[inverse ? "away" : "home"][strategy.defensiveStrategy]['leftDefense'])};
      }
    }
    let target = position;
    let passTarget = getClosestPointAlongLine(position, puck.position, closestOpposingPlayer);

    passTarget = closestOpposingPlayer
    passTarget.x -= 5;

    target.x = (target.x * (1-strategy.forecheck)) + (passTarget.x * strategy.forecheck);
    target.y = (target.y * (1-strategy.forecheck)) + (passTarget.y * strategy.forecheck);

    // if the playerPosition is center your position is 0.95 of the way
    // between the position and the puck carrier
    if (player.linePosition === 'center') {
      target.x = puck.position.x - 0.025;
      target.y = puck.position.y * 0.975;
    } else if (strategy.defensiveStrategy === 'OneThreeOne') {
      if (player.linePosition === 'rightDefense' && puck.position.y > 0) {
        target.x = position.x
        target.y = puck.position.y * 0.75;
      } else if (player.linePosition === 'leftDefense' && puck.position.y <= 0) {
        target.x = position.x
        target.y = puck.position.y * 0.75;
      } else {
        target.y = target.y + (((puck.position.y - target.y) / 2) * (0.5 * strategy.pressure));
      }
    } else if (['leftDefense', 'rightDefense'].includes(player.linePosition) && strategy.defensiveStrategy !== 'OneFour') {
      if (player.linePosition === 'rightDefense' &&
        puck.position.y > 0 &&
        position.y < puck.position.y) {
        target.y = puck.position.y * 0.8;
      } else if (player.linePosition === 'leftDefense' &&
        puck.position.y <= 0 &&
        position.y > puck.position.y) {
        target.y = puck.position.y * 0.8;
      }
    } else {
      target.y = target.y + (((puck.position.y - target.y) / 2) * (0.5 * strategy.pressure));
    }

    if (target.x > puck.position.x - 5 && ['leftDefense', 'rightDefense'].includes(player.linePosition)) target.x = puck.position.x - 5;
    else if (target.x > puck.position.x - 2.5) target.x += (puck.position.x - target.x) * strategy.pressure;

    //console.log('Not in D zone:', (inverse) ? "Away" : "Home", player.linePosition, target);
    return target;
  } else {
    const assignments = assignOpponents(opposingPlayers, puck, inverse);
    let closestOpposingPlayer = assignments[player.linePosition];

    // If no opponents are left, don't change position
    if (!closestOpposingPlayer) {
      console.log('collapsing', CollapsingPositions[inverse ? "away" : "home"][player.linePosition]);
      return (CollapsingPositions[inverse ? "away" : "home"][player.linePosition]);
    }

    let target;
    let collapsingPosition = (CollapsingPositions[inverse ? "away" : "home"][player.linePosition]);
    
    // Check if the assigned player is the puck carrier
    if (isPuckCarrier(opposingPlayers, puck)) {
      // Calculate the position along the shot line
      target = getClosestPointAlongLine(collapsingPosition, puck.position, inverse ? AWAY_NET : HOME_NET);
    } else {
      // Calculate the position along the passing line
      target = getClosestPointAlongLine(collapsingPosition, puck.position, closestOpposingPlayer.position);
    }

    // Adjust target based on the forecheck and shift the position
    target.x = ((closestOpposingPlayer.position.x * 0.7) + (target.x * (0.3)));
    target.y = ((closestOpposingPlayer.position.y * 0.7) + (target.y * (0.3)));
    
    // Lerp between the collapsing position and the target
    let x = (collapsingPosition.x * (1 - strategy.collapsing)) + (target.x * strategy.collapsing);
    let y = (collapsingPosition.y * (1 - strategy.collapsing)) + (target.y * strategy.collapsing);

    // Shift the position towards the puck based on the forecheck
    let adjustedForecheck = strategy.forecheck * 0.1;
    if ((collapsingPosition.y <= 0 && puck.position.y <= 0) || 
      (collapsingPosition.y >= 0 && puck.position.y >= 0)) adjustedForecheck = strategy.forecheck;
    x = x + ((puck.position.x - x) * (0.1 + (adjustedForecheck * 0.7)));
    y = y + ((puck.position.y - y) * (0.1 + (adjustedForecheck * 0.7)));
    
    // Ensure defensemen stay within a radius of 0.3 from the net
    let adjustedPressure = strategy.pressure * 0.8 + 0.1;
    if(player.linePosition.includes('Defense')) {
      let distanceToNet = Math.sqrt((x - net.x)**2 + (y - net.y)**2);
      if (distanceToNet > adjustedPressure) {
        let dx = x - net.x;
        let dy = y - net.y;
        let angle = Math.atan2(dy, dx);
        x = net.x + adjustedPressure * Math.cos(angle);
        y = net.y + adjustedPressure * Math.sin(angle);
      }
    }

    //console.log('In D zone:', (inverse) ? "Away" : "Home", player.linePosition, target);
    return { x, y };
  }
}

export const getRecoveryPosition = (player, puck, inverse) => {
  // Get a position infront of the puck based on its velocity within bounds

  // adjust the target with a projected position based on velocity
  let projectedPosition = {
    x: puck.position.x + puck.velocity.x,
    y: puck.position.y + puck.velocity.y
  }

  // Adjust the target based on the position of the player
  // and if it's heading to the defensive zone
  if (['rightDefense', 'leftDefense'].includes(player.linePosition) && !inverse && puck.velocity.x < 0) {
    projectedPosition.x = puck.position.x - (puck.velocity.x + puck.velocity.x)
  } else if (['rightDefense', 'leftDefense'].includes(player.linePosition) && inverse && puck.velocity.x > 0) {
    projectedPosition.x = puck.position.x + (puck.velocity.x + puck.velocity.x)
  }

  // Adjust the target based on the distance and the max speed of the player
  const distance = getDistance(player.position, puck.position);
  let adjustedX = distance / MAX_SKATING_SPEED;
  if (adjustedX > 1) adjustedX = 1;
  
  let target = {
    x: projectedPosition.x * adjustedX + puck.position.x * (1 - adjustedX),
    y: projectedPosition.y * adjustedX + puck.position.y * (1 - adjustedX)
  }


  // check if the puck is on the left or right side of the rink
  // compare that with the players position
  // if the player is on the same side as the puck, move towards the puck
  // if the player is on the opposite side, move towards the center of the rink in a defensive position
  if ((!inverse && target.y < 42.5 && ['leftWing', 'leftDefense'].includes(player.linePosition)) ||
    (inverse && target.y < 42.5 && ['rightWing', 'rightDefense'].includes(player.linePosition))) {
      target.y = 42.5 - (5 * (1-(target.y / 42.5)));
  } else if ((!inverse && target.y > 42.5 && ['rightWing', 'rightDefense'].includes(player.linePosition)) ||
  (!inverse && target.y >= 42.5 && ['leftWing', 'leftDefense'].includes(player.linePosition))) {
    target.y = 42.5 + (5 * ((target.y - 42.5) / 42.5));
  }

  return getBoundedPosition(target);
}


const isPuckCarrier = (position, puck) => {
  return (position.x === puck.position.x && position.y === puck.position.y && (puck.state !== 'pass' || puck.state === 'shot'));
}

const convertToRink = (position) => {
  return {
    x: (((position.x) + 1) / 2) * RINK_WIDTH,
    y: (((position.y) + 1) / 2) * RINK_HEIGHT
  }
}