import React, { useEffect, useState } from 'react';
import { Flex, LoadingOverlay, Text } from '@mantine/core';

import './NextGame.scss';

import connection from '../../feathers';
import { LiveGamePreview } from './LiveGamePreview';
import { getTotalSalary } from '../../lib/getTotalSalary';
import { convertPlayerObject } from '../../lib/convertPlayerObject';
import { notifications } from '@mantine/notifications';

export const NextGame = () => {

  const [game, setGame] = useState(null);
  const [match, setMatch] = useState(null);
  const [user, setUser] = useState(null);

  const [isCapCompliant, setIsCapCompliant] = useState(false);
  const [linesSet, setLinesSet] = useState(false);
  const [isContracted, setIsContracted] = useState(false);
  const [retiredPlayers, setRetiredPlayers] = useState(false);

  const [findingMatch, setFindingMatch] = useState(false);

  const getNewGames = async () => {
    const user = await connection.get('authentication');
    
    let res = await connection.service('games').find({
      query: {
        $limit: 1,
        $or: [
          { homeId: user.user._id },
          { awayId: user.user._id }
        ],
        $sort: {
          startTime: -1
        },
      }
    });
    let game = res.data[0];
    
    if (game)
      game.events = game.events.map((event) => {
        if (event.time > 2400) event.time += 60;
        if (event.time > 1200) event.time += 60;
        return event;
      });
    setGame(game);
  }

  useEffect(() => {
    const load = async () => {
      const user = await connection.get('authentication');
      setUser(user.user)
      // connect to the on create game hook
      connection.service('games').on('created', async (game) => {
        //console.log('game created', game);
        // if the game is for the current user's team, set the game
        if (game.homeId === user.user._id ||
            game.awayId === user.user._id) {
            
            setTimeout(() => {
              getNewGames();
            }, 1000);
        }
      });

      // get a match
      let res = await connection.service('matchmaking').find({
        query: {
          $limit: 1,
          $or: [
            { homeId: user.user._id },
            { awayId: user.user._id }
          ],
        }
      });
      setMatch(res.data[0]);

      res = await connection.service('games').find({
        query: {
          $limit: 1,
          $or: [
            { homeId: user.user._id },
            { awayId: user.user._id }
          ],
          $sort: {
            startTime: -1
          },
        }
      });
      let game = res.data[0];
      
      if (game)
        game.events = game.events.map((event) => {
          if (event.time > 2400) event.time += 60;
          if (event.time > 1200) event.time += 60;
          return event;
        });
      setGame(game);

      // Get the current user's team and set the salary and if the lines are set
      res = await connection.service('players').find({
        query: {
          $limit: 20,
          ownerId: user.user._id,
          line: { $gt: 0 }
        }
      });
      const players = convertPlayerObject(res.data);
      setRetiredPlayers(players.filter(player => player.bio.age >= player.longevity).length > 0);
      setIsContracted(players.filter(player => player.line > 0 && player.gamesPlayed < player.contractLength).length === 20);
      setIsCapCompliant(getTotalSalary(players) < 100);
      setLinesSet(res.data.filter(player => player.line > 0).length === 20);
    }

    load();
  }, [])

  const createOrFindMatch = async () => {
    setFindingMatch(true);
    let check = await connection.service('games').find({
      query: {
        $limit: 0,
        $or: [
          { homeId: user._id },
          { awayId: user._id }
        ],
        startTime: {
          $gt: Date.now() - 4020000
        }
      }
    })
    if (check.total > 0) {
      notifications.show({
        title: 'You cannot join matchmaking',
        message: 'You are already in a game',
        color: 'red',
      })
      return;
    }
    check = await connection.service('matchmaking').find({
      query: {
        $limit: 0,
        $or: [
          { homeId: user._id },
          { awayId: user._id }
        ],
      }
    })
    if (check.total > 0) {
      notifications.show({
        title: 'You cannot join matchmaking',
        message: 'You are already in matchmaking',
        color: 'red',
      })
      return;
    }

    let res = await connection.service('matchmaking').find({
      query: {
        $limit: 1,
        $and : [
          { homeId: { $ne: user._id } },
          { awayId: null },
        ],
        $sort: {
          createdAt: 1
        }
      }
    });
    if (res.total > 0) {
      //update match with id
      res = await connection.service('matchmaking').patch(res.data[0]._id, {
        awayId: user._id,
      });

      setMatch(res);
      return;
    }
    
    res = await connection.service('matchmaking').create({
      homeId: user._id,
      awayId: null,
    });
    
    setFindingMatch(false);
    setMatch(res);
  }

  const cancel = async () => {
    if (match) {
      await connection.service('matchmaking').remove(match._id);
      setMatch(null);
    }
  }

/* Used to disable matchmaking */
/*
  if (!game) return <></>
  return (
    <div>
      <Text ta="center" p="1rem" w="100%" maw="650px" m="0 auto" style={{ backgroundColor: 'var(--theme-color)' }} tt="uppercase">
        Matchmaking currently disabled, server restart after current games are complete
      </Text>
      <LiveGamePreview game={game} />
    </div>
  )*/


  if ((!game || ((Date.now() - game.startTime) / 1000 > 4020)) && match) return (
    <Flex w="100%" maw="650px" m="0 auto" gap="1rem" justify="center" align="center">
      <div className='activeMatch'>
        <Text tt="uppercase" size="1.5rem" py="0.25rem">Waiting For Opponent...</Text>
      </div>
      <div className='activeMatch' onClick={cancel}>
        <Text tt="uppercase" size="1.5rem" py="0.25rem">Cancel</Text>
      </div>
    </Flex>
  );
  if ((!game || ((Date.now() - game.startTime) / 1000 > 4020)) &&
  isCapCompliant && linesSet && isContracted && !retiredPlayers) return (
    (findingMatch) ? (
      <div className='activeMatch'>
        <Text tt="uppercase" size="1.5rem" py="0.25rem">Searching...</Text>
      </div>
     ) : (
      <div className='activeMatch' onClick={createOrFindMatch}>
        <Text tt="uppercase" size="1.5rem" py="0.25rem">Find Match</Text>
      </div>
     )
  );
  else if (!game || ((Date.now() - game.startTime) / 1000 > 4020)) return (
    <div className='activeMatch'>
      <Text tt="uppercase" size="1.5rem" py="0.25rem">{(!isContracted || retiredPlayers || !linesSet) ? 'Lineup Issues' : 'Over the cap'}</Text>
    </div>
  );
  
  return <LiveGamePreview game={game} />
}
