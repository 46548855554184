import React from 'react'
import { NotificationItem } from './NotificationItem'
import { Flex } from '@mantine/core'

import './notifications.scss';

export const Notifications = ({ notifications }) => {
  if (!notifications || notifications.length <= 0) return '';
  return (
    <Flex className='notificationsList' direction="column">
      {notifications.map((notification) => {
        return (
          <NotificationItem key={notification._id} notification={notification} />
        )
      })}
    </Flex>
  )
}
