import React, { useEffect, useState } from 'react'
import connection from '../../feathers';
import { convertPlayerObject } from '../../lib/convertPlayerObject';
import { notifications } from '@mantine/notifications';
import { Button, Flex, Text, Title } from '@mantine/core';
import { Rink } from '../Live/Rink';
import { runSimulation } from '../../lib/simulation/simulation';
import { LiveRink } from './LiveRink';

export const TestSim = () => {
  const [game, setGame] = useState(null);
  const [home, setHome] = useState(null);
  const [away, setAway] = useState(null);
  const [players, setPlayers] = useState([])

  const simulate = async () => {
    const res = await connection.service('teams').find({ query: { $limit: 50 }})

    let home = res.data[Math.floor(Math.random() * res.data.length)];
    let away = res.data[Math.floor(Math.random() * res.data.length)];


    // Get players for each team
    const players = await connection.service('players').find({
      query: {
        $limit: 100,
        line: { $gt: 0 },
        $or: [
          { ownerId: home.ownerId },
          { ownerId: away.ownerId }
        ]
      }
    });

    // Add the lines for home and away
    const homePlayers = convertPlayerObject(players.data.filter(player => player.ownerId === home.ownerId));
    const awayPlayers = convertPlayerObject(players.data.filter(player => player.ownerId === away.ownerId));
    setPlayers([...homePlayers, ...awayPlayers]);

    // make sure the players for each team >= 20
    if (homePlayers.length < 20) {
      notifications.show({
        title: 'Home lines not set',
      })
      return;
    }
    if (awayPlayers.length < 20) {
      notifications.show({
        title: 'Away lines not set',
      })
      return;
    }

    // set home and away goalies
    home.starter = homePlayers.find(player => player.position === 'starter');
    home.backup = homePlayers.find(player => player.position === 'backup');
    away.starter = awayPlayers.find(player => player.position === 'starter');
    away.backup = awayPlayers.find(player => player.position === 'backup');

    // Set lines
    home.forwardLines = [];
    for (let line = 1; line < 5; line++) {
      home.forwardLines[line-1] = {
        center: homePlayers.find(player => player.line === line && player.position === 'center'),
        leftWing: homePlayers.find(player => player.line === line && player.position === 'leftWing'),
        rightWing: homePlayers.find(player => player.line === line && player.position === 'rightWing'),
      }
    }
    home.defenseLines = [];
    for (let line = 1; line < 4; line++) {
      home.defenseLines[line-1] = {
        leftDefense: homePlayers.find(player => player.line === line && player.position === 'leftDefense'),
        rightDefense: homePlayers.find(player => player.line === line && player.position === 'rightDefense'),
      }
    }

    away.forwardLines = [];
    for (let line = 1; line < 5; line++) {
      away.forwardLines[line] = {
        center: awayPlayers.find(player => player.line === line && player.position === 'center'),
        leftWing: awayPlayers.find(player => player.line === line && player.position === 'leftWing'),
        rightWing: awayPlayers.find(player => player.line === line && player.position === 'rightWing'),
      }
    }
    away.defenseLines = [];
    for (let line = 1; line < 4; line++) {
      away.defenseLines[line] = {
        leftDefense: awayPlayers.find(player => player.line === line && player.position === 'leftDefense'),
        rightDefense: awayPlayers.find(player => player.line === line && player.position === 'rightDefense'),
      }
    }

    home.strategy = away.strategy = {
      pressure: 0.5,
      forecheck: 0.25,
      collapsing: 0.3,
      offensiveStrategy: 'HighForward',
      defensiveStrategy: 'OneThreeOne',
    }

    let state = await runSimulation(home, away);

    setGame(state);

    // Get teams from database
    const homeres = await connection.service('teams').get(home._id);
    const awayres = await connection.service('teams').get(away._id);

    setHome({
      ...homeres,
      ...state.home,
    });
    setAway({
      ...awayres,
      ...state.away,
    });
  }

  useEffect(() => {
    simulate();
  }, [])

  if (!home || !away) return null;

  return (
    <div>
      <Button size="xl" onClick={simulate}>Simulate</Button>
      
      <Flex gap="1rem" justify="center" align="center">
        <Title tt="uppercase" c={home.color}>{(window.innerWidth > 600) ? home.name : home.abbr}</Title>
        <Text size="1rem" italic>vs</Text>
        <Title tt="uppercase" c={away.color}>{(window.innerWidth > 600) ? away.name : away.abbr}</Title>
      </Flex>
{/*
      <div style={{ width: '90%', maxWidth: '600px', margin: '1rem auto'}}>
        <Flex gap="1rem" justify="space-between" align="center" w="100%">
          <Text size="1.5rem" tt="uppercase" w="50px">{home.goals}</Text>
          <Flex w="200px" justify="flex-end" align="center">
            <div style={{ width: (100 * home.goals / Math.max(home.goals, away.goals)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1rem" italic w="30px" ta="center">G</Text>
          <Flex w="200px" justify="flex-start" align="center">
            <div style={{ width: (100 * away.goals / Math.max(home.goals, away.goals)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.goals}</Text>
        </Flex>
        <Flex gap="1rem" justify="space-between" align="center" w="100%">
          <Text size="1.5rem" tt="uppercase" w="50px">{home.shots}</Text>
          <Flex w="200px" justify="flex-end" align="center">
            <div style={{ width: (100 * home.shots / Math.max(home.shots, away.shots)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1rem" italic w="30px" ta="center">SOG</Text>
          <Flex w="200px" justify="flex-start" align="center">
            <div style={{ width: (100 * away.shots / Math.max(home.shots, away.shots)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.shots}</Text>
        </Flex>
        <Flex gap="1rem" justify="space-between" align="center" w="100%">
          <Text size="1.5rem" tt="uppercase" w="50px">{away.shots-away.goals}</Text>
          <Flex w="200px" justify="flex-end" align="center">
            <div style={{ width: (100 * (away.shots-away.goals) / Math.max((away.shots-home.goals), (home.shots-away.goals))) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1rem" italic w="30px" ta="center">SV</Text>
          <Flex w="200px" justify="flex-start" align="center">
            <div style={{ width: (100 * (home.shots-home.goals) / Math.max((away.shots-home.goals), (home.shots-away.goals))) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{home.shots-home.goals}</Text>
        </Flex>
        <Flex gap="1rem" justify="space-between" align="center" w="100%">
          <Text size="1.5rem" tt="uppercase" w="50px">{((away.shots-away.goals) / away.shots).toFixed(3)}</Text>
          <Flex w="200px" justify="flex-end" align="center">
            <div style={{ width: (100 * (away.shots-away.goals) / away.shots) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1rem" italic w="30px" ta="center">SV%</Text>
          <Flex w="200px" justify="flex-start" align="center">
            <div style={{ width: (100 * (home.shots-home.goals) / home.shots) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{((home.shots - home.goals) / home.shots).toFixed(3)}</Text>
        </Flex>
        <Flex gap="1rem" justify="space-between" align="center" w="100%">
          <Text size="1.5rem" tt="uppercase" w="50px">{home.hits}</Text>
          <Flex w="200px" justify="flex-end" align="center">
            <div style={{ width: (100 * home.hits / Math.max(home.hits, away.hits)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1rem" italic w="30px" ta="center">HITS</Text>
          <Flex w="200px" justify="flex-start" align="center">
            <div style={{ width: (100 * away.hits / Math.max(home.hits, away.hits)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.hits}</Text>
        </Flex>
        <Flex gap="1rem" justify="space-between" align="center" w="100%">
          <Text size="1.5rem" tt="uppercase" w="50px">{home.blocks}</Text>
          <Flex w="200px" justify="flex-end" align="center">
            <div style={{ width: (100 * home.blocks / Math.max(home.blocks, away.blocks)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1rem" italic w="30px" ta="center">BLKS</Text>
          <Flex w="200px" justify="flex-start" align="center">
            <div style={{ width: (100 * away.blocks / Math.max(home.blocks, away.blocks)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.blocks}</Text>
        </Flex>
        <Flex gap="1rem" justify="space-between" align="center" w="100%">
          <Text size="1.5rem" tt="uppercase" w="50px">{home.takeaways}</Text>
          <Flex w="200px" justify="flex-end" align="center">
            <div style={{ width: (100 * home.takeaways / Math.max(home.takeaways, away.takeaways)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1rem" italic w="30px" ta="center">TK</Text>
          <Flex w="200px" justify="flex-start" align="center">
            <div style={{ width: (100 * away.takeaways / Math.max(home.takeaways, away.takeaways)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
          </Flex>
          <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.takeaways}</Text>
        </Flex>
  </div>*/}

      <LiveRink state={game.state} game={game} players={players} home={home} away={away} />
    </div>
  )
}
