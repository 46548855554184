import { Flex, Select, Title } from "@mantine/core";
import React, { useState, useEffect } from "react";

let opposingPlayers = [
  { x: -0.6, y: 0.2, playerPosition: "rightWing" },
  { x: -0.7, y: -0.85, playerPosition: "leftWing" },
  { x: -0.93, y: 0.6, playerPosition: "center" },
  { x: -0.3, y: -0.1, playerPosition: "leftDefense" },
  { x: -0.36, y: 0.6, playerPosition: "rightDefense" }
];

const OffensiveZoneFormations = {
  CrashTheNet: {
    leftWing: { x: -0.75, y: 0.15 },
    rightWing: { x: -0.75, y: -0.15 },
    center: { x: -0.65, y: 0 },
    leftDefense: { x: -0.35, y: 0.1 },
    rightDefense: { x: -0.35, y: -0.1 }
  },
  BehindTheNet: {
    leftWing: { x: -0.65, y: 0.25 },
    rightWing: { x: -0.65, y: -0.25 },
    center: { x: -0.9, y: 0 },
    leftDefense: { x: -0.35, y: 0.2 },
    rightDefense: { x: -0.35, y: -0.2 }
  },
  HighForward: {
    leftWing: { x: -0.75, y: 0.45 },
    rightWing: { x: -0.75, y: -0.45 },
    center: { x: -0.6, y: 0 },
    leftDefense: { x: -0.35, y: 0.3 },
    rightDefense: { x: -0.35, y: -0.3 }
  },
}

const NeutralZoneOffensiveFormations = {
  leftWing: { x: -0.25, y: 0.75 },
  rightWing: { x: -0.25, y: -0.75 },
  center: { x: -0.1, y: 0 },
  leftDefense: { x: 0.3, y: 0.4 },
  rightDefense: { x: 0.3, y: -0.4 }
}

const playerPosList = [
  "leftWing",
  "rightWing",
  "center",
  "leftDefense",
  "rightDefense"
];

const CollapsingPositions = {
  leftWing: { x: -0.65, y: -0.2 },
  rightWing: { x: -0.65, y: 0.2 },
  center: { x: -0.5, y: 0 },
  leftDefense: { x: -0.8, y: -0.15 },
  rightDefense: { x: -0.8, y: 0.15 }
};

const NeutralZoneFormations = {
  "OneFour": {
    leftWing: { x: -0.3, y: -0.65 },
    rightWing: { x: -0.3, y: 0.65 },
    center: { x: 0.3, y: 0 },
    leftDefense: { x: -0.3, y: -0.2 },
    rightDefense: { x: -0.3, y: 0.2 }
  },
  "OneTwoTwoRed": {
    leftWing: { x: 0, y: -0.25 },
    rightWing: { x: 0, y: 0.25 },
    center: { x: 0.45, y: 0 },
    leftDefense: { x: -0.275, y: -0.35 },
    rightDefense: { x: -0.275, y: 0.35 }
  },
  "OneTwoTwoBlue": {
    leftWing: { x: 0.28, y: -0.35 },
    rightWing: { x: 0.28, y: 0.35 },
    center: { x: 0.6, y: 0 },
    leftDefense: { x: 0, y: -0.25 },
    rightDefense: { x: 0, y: 0.25 }
  },
  "OneThreeOne": {
    leftWing: { x: -0.175, y: -0.5 },
    rightWing: { x: -0.175, y: 0.5 },
    center: { x: 0.4, y: 0 },
    leftDefense: { x: -0.175, y: 0 },
    rightDefense: { x: -0.4, y: 0 }
  }
}

const convertPosToReadable = (pos) => {
  switch (pos) {
    case "leftWing":
      return "LW";
    case "rightWing":
      return "RW";
    case "center":
      return "C";
    case "leftDefense":
      return "LD";
    case "rightDefense":
    default:
      return "RD";
  }
};

export const TestFormations = () => {
  const [collapsing, setCollapsing] = useState(0);
  const [pressure, setPressure] = useState(0);
  const [nzPressure, setNzPressure] = useState(0.5);
  const [nzForecheck, setNzForecheck] = useState(0.3);
  const [forecheck, setForecheck] = useState(0);
  const [offensiveStrategy, setOffensiveStrategy] = useState('HighForward');
  const [neutralZoneStrategy, setNeutralZoneStrategy] = useState('OneFour');
  const [puckPosition, setPuckPosition] = useState(opposingPlayers[2]);

  function getOffensivePosition(puckPosition, selectedPlayer) {
    let strategy = OffensiveZoneFormations[offensiveStrategy];
    if (puckPosition.x > -0.3)
      strategy = NeutralZoneOffensiveFormations;
    // Compute distances for each player to the puck
    const distances = {};
    for (const player in strategy) {
      const playerPosition = strategy[player];
      const dx = puckPosition.x - playerPosition.x;
      const dy = puckPosition.y - playerPosition.y;
      distances[player] = Math.sqrt(dx * dx + dy * dy);
    }

    // Identify player closest to the puck
    const closestPlayer = Object.keys(distances).reduce((a, b) =>
      distances[a] < distances[b] ? a : b
    );

    // Position closest player at the puck's position
    const newPosition = structuredClone(strategy);
    newPosition[closestPlayer] = { ...puckPosition };

    // Adjust defense player's position on the side of the puck if they are not the one with the puck
    const defensePlayer = puckPosition.y < 0 ? "rightDefense" : "leftDefense";
    if (
      defensePlayer !== closestPlayer &&
      (closestPlayer === "leftWing" ||
        closestPlayer === "rightWing" ||
        closestPlayer === "center")
    ) {
      newPosition[defensePlayer] = {
        ...newPosition[defensePlayer],
        y: puckPosition.y
      };
    }

    // Adjust players on the same side of the puck to shift towards the puck
    for (const player in newPosition) {
      newPosition[player].y += (puckPosition.y - newPosition[player].y) * 0.25;
    }

    if ((selectedPlayer === "leftDefense" && puckPosition.y > 0) ||
      (selectedPlayer === "rightDefense" && puckPosition.y <= 0)) {
        newPosition[selectedPlayer].x += (puckPosition.x - newPosition[selectedPlayer].x) * 0.3;
        
    }

    // Return the position of the selected player
    return newPosition[selectedPlayer];
  }

  const renderPlayers = () => {
    return playerPosList.map((position) => {
      const player = calculatePosition(position, opposingPlayers);
      return (
        <div
          style={{
            position: "absolute",
            top: "calc(" + ((player.y + 1) / 2) * 100 + "% - 12px)",
            left: "calc(" + ((player.x + 1) / 2) * 100 + "% - 12px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "0.8em",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            backgroundColor: "blue",
            color: "#fff"
          }}>
          {convertPosToReadable(position)}
        </div>
      );
    });
  };

  const renderOpposingPlayers = () => {
    return opposingPlayers.map((player) => {
      const coords = getOffensivePosition(
        puckPosition,
        player.playerPosition
      );
      player.x = coords.x;
      player.y = coords.y;
      return (
        <div
          style={{
            position: "absolute",
            top: "calc(" + ((player.y + 1) / 2) * 100 + "% - 12px)",
            left: "calc(" + ((player.x + 1) / 2) * 100 + "% - 12px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "0.8em",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            backgroundColor: "red",
            color: "#fff"
          }}
        >
          {convertPosToReadable(player.playerPosition)}
        </div>
      );
    });
  };

  const renderTargetPositions = () => {
    return playerPosList.map((position) => {
      //const player = NeutralZoneFormations['OneFour'][position];
      const player = NeutralZoneOffensiveFormations[position];
      return (
        <div
          style={{
            position: "absolute",
            top: "calc(" + ((player.y + 1) / 2) * 100 + "% - 6px)",
            left: "calc(" + ((player.x + 1) / 2) * 100 + "% - 6px)",
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "white",
          }} />
      );
    });
  };
  
  const getClosestPointAlongLine = (position, pointA, pointB) => {
    if (pointA.x === pointB.x && pointA.y === pointB.y) return pointA;

    // Calculate the vector for the line
    let lineVecX = pointB.x - pointA.x;
    let lineVecY = pointB.y - pointA.y;

    // Calculate the vector from pointA to the position
    let posVecX = position.x - pointA.x;
    let posVecY = position.y - pointA.y;

    // Calculate the dot product
    let dotProduct = posVecX * lineVecX + posVecY * lineVecY;

    // Normalize the line vector
    let magnitude = Math.sqrt(lineVecX * lineVecX + lineVecY * lineVecY);
    let normalizedLineVecX = lineVecX / magnitude;
    let normalizedLineVecY = lineVecY / magnitude;

    // Project the position onto the line
    let projectedX = pointA.x + dotProduct * normalizedLineVecX;
    let projectedY = pointA.y + dotProduct * normalizedLineVecY;

    return {
      x: projectedX,
      y: projectedY
    }
  }

  const isPuckCarrier = (position) => {
    return (position.x === puckPosition.x && position.y === puckPosition.y)
  }

  function calculateRisk(player) {
    // For simplicity, we'll just use the player's distance to the net and distance to the puck
    let distanceToNet = Math.hypot(
      -0.9 - Math.abs(player.x),
      0 - Math.abs(player.y)
    );

    let distanceToPuck = Math.hypot(
      player.x - puckPosition.x,
      player.y - puckPosition.y
    );

    // Add risk if the player is in the slot with the puck
    let highRisk = 1;
    if (Math.abs(player.x) < 0.9 && Math.abs(player.x) > 0.65 && Math.abs(player.y) < 0.4)
      highRisk *= 1.25;

    if (isPuckCarrier(player)) highRisk *= 1.5;

    if (Math.abs(player.x) < 0.6) highRisk *= 0.75;

    if (Math.abs(player.x) > 0.75) highRisk *= 1.25;

    // We'll define risk as a simple combination of these two factors
    // You could use more sophisticated calculations if desired
    const risk = (distanceToNet + (distanceToPuck * 0.3)) * highRisk;
    //console.log(player.playerPosition, distanceToNet.toFixed(2), risk.toFixed(2))
    return risk;
  }

  function findHighestRiskOpponent(opposingPlayers) {
    let highestRiskOpponent = opposingPlayers[0];
    let highestRisk = calculateRisk(highestRiskOpponent);

    for (let i = 1; i < opposingPlayers.length; i++) {
      let currentRisk = calculateRisk(opposingPlayers[i]);
      if (currentRisk > highestRisk) {
        highestRisk = currentRisk;
        highestRiskOpponent = opposingPlayers[i];
      }
    }

    return highestRiskOpponent;
  }

  function assignOpponents(opposingPlayers) {
    let assignments = {};
  
    // Check which side the puck is on
    let defensiveSide = puckPosition.y >= 0 ? "rightDefense" : "leftDefense";
  
    // Assign the defensemen on the same side as the puck to the highest risk opponent (the puck carrier)
    let opponentCandidates = opposingPlayers.filter(opponent => (opponent.y >= 0) === (defensiveSide === "rightDefense"));
    if (!opponentCandidates || opponentCandidates.length <= 0) opponentCandidates = opposingPlayers;
    let highestRiskOpponent = findHighestRiskOpponent(opponentCandidates, puckPosition);
    assignments[defensiveSide] = highestRiskOpponent;
  
    // Remove this opponent from the list of opponents
    opposingPlayers = opposingPlayers.filter((opponent) => opponent !== highestRiskOpponent);
  
    // Assign the other defensemen to the next highest risk opponent
    opponentCandidates = opposingPlayers.filter(opponent => (opponent.y < 0) === (defensiveSide === "rightDefense"));
    if (!opponentCandidates || opponentCandidates.length <= 0) opponentCandidates = opposingPlayers;
    
    highestRiskOpponent = findHighestRiskOpponent(opponentCandidates, puckPosition);
    assignments[defensiveSide === "rightDefense" ? "leftDefense" : "rightDefense"] = highestRiskOpponent;
    opposingPlayers = opposingPlayers.filter((opponent) => opponent !== highestRiskOpponent);
    
    let positionList = ["rightWing", "center", "leftWing"];
    if (defensiveSide === "rightDefense") positionList.reverse();
  
    // Assign the center and wingers to the closest remaining opponents
    for (let playerPosition of positionList) {
      let player = CollapsingPositions[playerPosition];
      let closestOpponent = null;
      let closestDistance = Infinity;
  
      for (let opponent of opposingPlayers) {
        let distance = Math.hypot(player.x - opponent.x, player.y - opponent.y);
        if (distance < closestDistance) {
          closestOpponent = opponent;
          closestDistance = distance;
        }
      }
  
      if (closestOpponent) {
        assignments[playerPosition] = closestOpponent;
        opposingPlayers = opposingPlayers.filter((opponent) => opponent !== closestOpponent);
      }
    }
  
    return assignments;
  }

  function assignNeutralZoneOpponents(playerPositions, opposingPlayers) {
    let assignments = {};
  
    for (let position in playerPositions) {
      // Get the coordinates of the current player
      let playerCoordinates = playerPositions[position];
  
      let closestOpponent = null;
      let priority = Infinity;
  
      // Loop through all the opposing players
      for (let i = 0; i < opposingPlayers.length; i++) {
        const opponent = opposingPlayers[i];
        
        // Calculate the distance to the opponent
        let distance = Math.hypot(playerCoordinates.x - opponent.x, playerCoordinates.y - opponent.y);
        let threat = (opponent.x + 1);

        if (nzPressure > 0.6 && isPuckCarrier(opponent)) {
          if ((position === 'leftWing' && puckPosition.y < -0.1) ||
            (position === 'rightWing' && puckPosition.y > 0.1)) {
            threat *= 0.5;
            distance *= 0.75;
          }
        }
        if (['leftDefense', 'rightDefense'].includes(position)) {
          threat = threat * threat * threat;
          distance = distance * 6;
        }

        if (((position === 'leftWing' && puckPosition.y < -0.1) ||
        (position === 'rightWing' && puckPosition.y > 0.1)) &&
        position === 'rightDefense') {
          threat = opponent.y;
        }

        const riskLevel = distance + threat; // lower = high priority
        
        // If this opponent is closer than the currently closest known opponent, update the closest opponent
        if (riskLevel < priority) {
          closestOpponent = opponent;
          priority = riskLevel;
        }
      
      }
  
      // Assign the closest opponent to this player
      assignments[position] = closestOpponent;
    }
  
    return assignments;
  }

  function calculatePosition(playerPosition, opposingPlayers) {
    const net = { x: -0.825, y: 0 };
    if (puckPosition.x > -0.28) {
      const assignments = assignNeutralZoneOpponents(NeutralZoneFormations[neutralZoneStrategy], opposingPlayers);
      
      let closestOpposingPlayer = assignments[playerPosition];

      // If no opponents are left, don't change position
      if (!closestOpposingPlayer) {
        return NeutralZoneFormations[neutralZoneStrategy][playerPosition];
      }

      let position = NeutralZoneFormations[neutralZoneStrategy][playerPosition];
      if (puckPosition.y < 0 && neutralZoneStrategy === 'OneThreeOne') {
        // flip the left and right defense positions
        if (playerPosition === 'leftDefense') {
          position = NeutralZoneFormations[neutralZoneStrategy]['rightDefense'];
        } else if (playerPosition === 'rightDefense') {
          position = NeutralZoneFormations[neutralZoneStrategy]['leftDefense'];
        }
      }
      let target = {...position};
      let passTarget = getClosestPointAlongLine(position, puckPosition, closestOpposingPlayer);
      passTarget = closestOpposingPlayer
      passTarget.x -= 0.05;

      target.x = (target.x * (1-nzForecheck)) + (passTarget.x * nzForecheck);
      target.y = (target.y * (1-nzForecheck)) + (passTarget.y * nzForecheck);

      // if the playerPosition is center your position is 0.95 of the way
      // between the position and the puck carrier
      if (playerPosition === 'center') {
        target.x = puckPosition.x - 0.025;
        target.y = puckPosition.y * 0.975;
      } else if (neutralZoneStrategy === 'OneThreeOne') {
        if (playerPosition === 'rightDefense' && puckPosition.y > 0) {
          target.x = position.x
          target.y = puckPosition.y * 0.75;
        } else if (playerPosition === 'leftDefense' && puckPosition.y <= 0) {
          target.x = position.x
          target.y = puckPosition.y * 0.75;
        } else {
          target.y = target.y + (((puckPosition.y - target.y) / 2) * (0.5 * nzPressure));
        }
      } else if (['leftDefense', 'rightDefense'].includes(playerPosition) && neutralZoneStrategy !== 'OneFour') {
        if (playerPosition === 'rightDefense' &&
          puckPosition.y > 0 &&
          position.y < puckPosition.y) {
          target.y = puckPosition.y * 0.8;
        } else if (playerPosition === 'leftDefense' &&
          puckPosition.y <= 0 &&
          position.y > puckPosition.y) {
          target.y = puckPosition.y * 0.8;
        }
      } else {
        target.y = target.y + (((puckPosition.y - target.y) / 2) * (0.5 * nzPressure));
      }

      if (target.x > puckPosition.x - 0.05 && ['leftDefense', 'rightDefense'].includes(playerPosition)) target.x = puckPosition.x - 0.05;
      else if (target.x > puckPosition.x - 0.025) target.x += (puckPosition.x - target.x) * nzPressure;

      return target;

    } else {
      const assignments = assignOpponents(opposingPlayers);
      let closestOpposingPlayer = assignments[playerPosition];

      // If no opponents are left, don't change position
      if (!closestOpposingPlayer) {
        return CollapsingPositions[playerPosition];
      }

      let target;
      let collapsingPosition = CollapsingPositions[playerPosition];
      // Check if the assigned player is the puck carrier
      if (isPuckCarrier(opposingPlayers)) {
        // Calculate the position along the shot line
        target = getClosestPointAlongLine(collapsingPosition, puckPosition, net);
      } else {
        // Calculate the position along the passing line
        target = getClosestPointAlongLine(collapsingPosition, puckPosition, closestOpposingPlayer);
      }

      // Adjust target based on the forecheck and shift the position
      target.x = ((closestOpposingPlayer.x * 0.7) + (target.x * (0.3)));
      target.y = ((closestOpposingPlayer.y * 0.7) + (target.y * (0.3)));
      
      // Lerp between the collapsing position and the target
      let x = (collapsingPosition.x * (1 - collapsing)) + (target.x * collapsing);
      let y = (collapsingPosition.y * (1 - collapsing)) + (target.y * collapsing);

      // Shift the position towards the puck based on the forecheck
      let adjustedForecheck = forecheck * 0.1;
      if ((collapsingPosition.y <= 0 && puckPosition.y <= 0) || 
        (collapsingPosition.y >= 0 && puckPosition.y >= 0)) adjustedForecheck = forecheck;
      x = x + ((puckPosition.x - x) * (0.1 + (adjustedForecheck * 0.7)));
      y = y + ((puckPosition.y - y) * (0.1 + (adjustedForecheck * 0.7)));

      
      // Ensure defensemen stay within a radius of 0.3 from the net
      let adjustedPressure = pressure * 0.8 + 0.1;
      if(playerPosition.includes('Defense')) {
        let distanceToNet = Math.sqrt((x - net.x)**2 + (y - net.y)**2);
        if (distanceToNet > adjustedPressure) {
          let dx = x - net.x;
          let dy = y - net.y;
          let angle = Math.atan2(dy, dx);
          x = net.x + adjustedPressure * Math.cos(angle);
          y = net.y + adjustedPressure * Math.sin(angle);
        }
      }

      // Limit the values within the rink
      x = Math.min(Math.max(x, -1), 1);
      y = Math.min(Math.max(y, -1), 1);

      return { x, y };
    }
  }

  useEffect(() => {
  }, [puckPosition]);

  return (
  <div style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }}>
    <Title size="3rem">COACHING STRATEGIES</Title>
    <Title size="2rem">TEAM STRATEGIES</Title>
    <Flex gap="2rem" m="2rem">
      <div>
        <div>Zone - Cover</div>
        <input type="range"
          defaultValue={collapsing * 100}
          onChange={(e) => setCollapsing(Number(e.currentTarget.value) / 100) }
        />
      </div>
      <div>
        <div>Forecheck</div>
        <input type="range"
          defaultValue={forecheck * 100}
          onChange={(e) => setForecheck(Number(e.currentTarget.value) / 100) }
        />
      </div>
      <div>
        <div>Def. Pressure</div>
        <input type="range"
          defaultValue={pressure * 100}
          onChange={(e) => setPressure(Number(e.currentTarget.value) / 100) }
        />
      </div>
    </Flex>
    <div
      onClick={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setPuckPosition({
          x: (Number(e.clientX - rect.x) / 600) * 2 - 1,
          y: (Number(e.clientY - rect.y) / 300.81) * 2 - 1
        });
      }}
      style={{
        position: "relative",
        width: "600px",
        height: "300.81px"
      }}
    >
      <div style={{ fontSize: '0.8em'}}>({puckPosition.x.toFixed(2)},{puckPosition.y.toFixed(2)})</div>
      <img alt="rink"
        style={{
          display: "block",
          width: "100%"
        }}
        src="https://saihleague.com/images/rink.svg"
      />
      {renderPlayers()}
      {renderOpposingPlayers()}
      {renderTargetPositions()}
      <div
        style={{
          position: "absolute",
          top: ((puckPosition.y + 1) / 2) * 100 + "%",
          left: ((puckPosition.x + 1) / 2) * 100 + "%",
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          backgroundColor: "#2d2d2d",
          border: "1px solid #111",
          boxShadow: "1px 1px 3px 1px rgba(0,0,0,0.35)"
        }}
      />
    </div>
    <Flex gap="1rem" m="1rem 0" align="center">
    <Select label="Neutral Zone Strategy"
      value={neutralZoneStrategy}
      onChange={e => setNeutralZoneStrategy(e)}
      data={[
        {value: "OneFour", label: "1-4"},
        {value: "OneTwoTwoRed", label: "1-2-2 Red"},
        {value: "OneTwoTwoBlue", label: "1-2-2 Blue"},
        {value: "OneThreeOne", label: "1-3-1"},
      ]} />
      <div>
        <div>Neutral Zone Pressure</div>
        <input type="range"
          defaultValue={nzPressure*100}
          onChange={(e) => setNzPressure(Number(e.currentTarget.value) / 100) }
        />
      </div>
      <div>
        <div>Neutral Zone Coverage</div>
        <input type="range"
          defaultValue={nzForecheck*100}
          onChange={(e) => setNzForecheck(Number(e.currentTarget.value) / 100) }
        />
      </div>
    </Flex>
    <Title size="2rem" mt="2rem">LINE STRATEGIES</Title>
    <Title size="1.5rem" mt="2rem" mb="1rem">FORWARD LINES</Title>
    <Flex gap="2rem" wrap="wrap" justify="center">
      <Flex gap="0.5rem" m="1rem 0" align="center" direction="column">
        <Title size="1.25rem" ta="left" w="170px">FORWARD LINE 1</Title>
        <Select label="Offensive Strategy"
          value={offensiveStrategy}
          onChange={e => setOffensiveStrategy(e)}
          data={[
            {value: "CrashTheNet", label: "Crash The Net"},
            {value: "HighForward", label: "High Forward"},
            {value: "BehindTheNet", label: "Behind The Net"}
          ]} />
        <div style={{ width: '170px' }}>
          <div>Carry - Dump</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Cycle - Shoot</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Block - Take Passing Lane</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Efficiency - Energy</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
      </Flex>
      <Flex gap="0.5rem" m="1rem 0" align="center" direction="column">
        <Title size="1.25rem" ta="left" w="170px">FORWARD LINE 2</Title>
        <Select label="Offensive Strategy"
          defaultValue={offensiveStrategy}
          data={[
            {value: "CrashTheNet", label: "Crash The Net"},
            {value: "HighForward", label: "High Forward"},
            {value: "BehindTheNet", label: "Behind The Net"}
          ]} />
        <div style={{ width: '170px' }}>
          <div>Carry - Dump</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Cycle - Shoot</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Block - Take Passing Lane</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Efficiency - Energy</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
      </Flex>
      <Flex gap="0.5rem" m="1rem 0" align="center" direction="column">
        <Title size="1.25rem" ta="left" w="170px">FORWARD LINE 3</Title>
        <Select label="Offensive Strategy"
          defaultValue={offensiveStrategy}
          data={[
            {value: "CrashTheNet", label: "Crash The Net"},
            {value: "HighForward", label: "High Forward"},
            {value: "BehindTheNet", label: "Behind The Net"}
          ]} />
        <div style={{ width: '170px' }}>
          <div>Carry - Dump</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Cycle - Shoot</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Block - Take Passing Lane</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Efficiency - Energy</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
      </Flex>
      <Flex gap="0.5rem" m="1rem 0" align="center" direction="column">
        <Title size="1.25rem" ta="left" w="170px">FORWARD LINE 4</Title>
        <Select label="Offensive Strategy"
          defaultValue={offensiveStrategy}
          data={[
            {value: "CrashTheNet", label: "Crash The Net"},
            {value: "HighForward", label: "High Forward"},
            {value: "BehindTheNet", label: "Behind The Net"}
          ]} />
        <div style={{ width: '170px' }}>
          <div>Carry - Dump</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Cycle - Shoot</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Block - Take Passing Lane</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Efficiency - Energy</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
      </Flex>
    </Flex>
    <Title size="1.5rem" mt="2rem" mb="1rem">DEFENSIVE LINES</Title>
    <Flex gap="2rem" wrap="wrap" justify="center">
      <Flex gap="0.5rem" m="1rem 0" align="center" direction="column">
        <Title size="1.25rem" ta="left" w="170px">DEFENSE LINE 1</Title>
        <div style={{ width: '170px' }}>
          <div>Short Passes - Long Passes</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Cycle - Shoot</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Block - Take Passing Lane</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
      </Flex>
      <Flex gap="0.5rem" m="1rem 0" align="center" direction="column">
        <Title size="1.25rem" ta="left" w="170px">DEFENSE LINE 2</Title>
        <div style={{ width: '170px' }}>
          <div>Short Passes - Long Passes</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Cycle - Shoot</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Block - Take Passing Lane</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
      </Flex>
      <Flex gap="0.5rem" m="1rem 0" align="center" direction="column">
        <Title size="1.25rem" ta="left" w="170px">DEFENSE LINE 3</Title>
        <div style={{ width: '170px' }}>
          <div>Short Passes - Long Passes</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Cycle - Shoot</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
        <div style={{ width: '170px' }}>
          <div>Block - Take Passing Lane</div>
          <input style={{ width: '170px' }} type="range" />
        </div>
      </Flex>
    </Flex>
  </div>
  )
}
