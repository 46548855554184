import React, { useContext, useEffect, useState } from 'react'
import connection from '../../feathers'
import { AuthContext } from '../../Contexts';
import { Flex, Text } from '@mantine/core';
import { shadeColor } from '../../lib/shadeColor';
import { useNavigate } from 'react-router';
import { getPeriodTimeDown } from '../../lib/getPeriodTime';
import { getGamePeriod } from '../../lib/getGamePeriod';
import { convertTime } from '../../lib/convertTime';
import { getGameProgress } from '../../lib/getGameProgress';

export const LiveGamePreview = ({game}) => {
  const navigate = useNavigate();

  const [players, setPlayers] = useState([]);

  const [time, setTime] = useState(Date.now());
  const [homeGoal, setHomeGoal] = useState(0);
  const [awayGoal, setAwayGoal] = useState(0);
  const [home, setHome] = useState(null);
  const [away, setAway] = useState(null);

  useEffect(() => {
    const load = async () => {       
      let res = await connection.service('players').find({
        query: {
          $limit: 40,
          line: { $gt: 0 },
          $or: [
            { ownerId: game.homeId.toString() },
            { ownerId: game.awayId.toString() }
          ]
        }
      })
      const playersList = res.data;

      res = await connection.service('teams').find({
        query: {
          $or: [
            { ownerId: game.homeId.toString() },
            { ownerId: game.awayId.toString() }
          ]
        }
      })
      let temp = res.data.find(e => e.ownerId === game.homeId);
      setHome(temp);
      temp = res.data.find(e => e.ownerId === game.awayId);
      setAway(temp);
      setPlayers(playersList);
      // add 60 seconds to any time over 1200 and another 60 to any time over 2400
      //setGame(game);

      return game.startTime;
    }

    load().then((startTime) => {
      if ((Date.now() - startTime) / 1000 < 4020) {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
          if (interval) clearInterval(interval);
        };
      } else {
        updateState();
      }
    });

  }, [,game]);


  useEffect(() => {
    if (game) updateState();
  }, [game, time]);

  const updateState = async () => {
    const gameState = await getGameProgress(game, players);

    setHomeGoal(gameState.home.goals);
    setAwayGoal(gameState.away.goals);
  }

  
  const isLive = () => {
    if (((Date.now() - game.startTime) / 1000) < 3720) return <div>Live</div>;
    
    return <div>Final</div>;
  }

  const launchGame = () => {
    if ((Date.now() - game.startTime) / 1000 > 3720) navigate('/recap/' + game._id);
    else navigate('/live/' + game._id);
  }

  if (!game || !home || !away) return <div></div>
  return (
    <div onClick={launchGame} style={{ cursor: 'pointer', maxWidth: '650px', marginLeft: 'auto', marginRight: 'auto' }}>
      <Flex justify="space-between">
        <Flex style={{ backgroundColor: shadeColor('#ec1668', -50), borderTop: "8px solid" + home.color }} w="50%" p="0.5rem" align="center" justify="space-between">
          {(window.innerWidth > 600) ? (
            <div>
              <Text mt="0.5rem" size="1rem" tt="uppercase">{home.city}</Text>
              <Text size="2.5rem" tt="uppercase" lh={1} mb="0.5rem">{home.name}</Text>
            </div>
          ) : (
            <div>
              <Text size="3rem" tt="uppercase">{home.abbr}</Text>
            </div>
          )}
          <Text m="1rem 1rem 0 2rem" className='numbers' size="5rem" tt="uppercase">{homeGoal}</Text>
        </Flex>
        <Flex style={{ backgroundColor: shadeColor('#ec1668', -60), borderTop: "8px solid" + away.color }} w="50%" p="0.5rem" align="center" justify="space-between">
          <Text m="1rem 2rem 0 1rem" className='numbers' size="5rem" tt="uppercase">{awayGoal}</Text>
          {(window.innerWidth > 600) ? (
            <div>
              <Text mt="0.5rem" size="1rem" tt="uppercase">{away.city}</Text>
              <Text size="2.5rem" tt="uppercase" lh={1} mb="0.5rem">{away.name}</Text>
            </div>
          ) : (
            <div>
              <Text size="3rem" tt="uppercase">{away.abbr}</Text>
            </div>
          )}
        </Flex>
      </Flex>
      <Flex align="center" justify="space-between" style={{ backgroundColor: shadeColor('#2e0414', -40), padding: '0.25rem 0.5rem' }}>
        {isLive()}
        <Flex gap="1rem" tt="uppercase">{(Date.now() / 1000 < ((game.startTime / 1000) + 4020)) ?  <>{getGamePeriod(Math.round(Date.now() / 1000) - Math.round(game.startTime / 1000), true)} {convertTime( getPeriodTimeDown(Math.round(Date.now() / 1000) - Math.round(game.startTime / 1000)))}</> : '' }</Flex>
        <div style={{ width: '50px' }}>
          <img style={{ width: '50px' }} src="/images/saihl/saihl_color.svg" alt="SAIHL Logo" />
        </div>
      </Flex>
    </div>
  )
}
