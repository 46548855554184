import { getDistance } from "./getDistance";
import { PLAYER_WEIGHT_SCALE, RESOLUTION, RINK_HEIGHT, RINK_WIDTH } from "./simulation/config";

export const generateMap = (state) => {
  const map = new Array(RINK_HEIGHT/RESOLUTION).fill(0).map(() => new Array(RINK_WIDTH/RESOLUTION).fill(0));

  let players = [
    state.home.active.center,
    state.home.active.leftWing,
    state.home.active.rightWing,
    state.home.active.leftDefense,
    state.home.active.rightDefense,
    state.away.active.center,
    state.away.active.leftWing,
    state.away.active.rightWing,
    state.away.active.leftDefense,
    state.away.active.rightDefense,
  ];

  for (let x = 0; x < RINK_WIDTH/RESOLUTION; x++) {
    for (let y = 0; y < RINK_HEIGHT/RESOLUTION; y++) {
      let score = 0;
      for (const player of players)
        score += playerInfluence({x, y}, player.position, player.team.id === state.home.id, 25);
      
      score /= players.length;
      
      map[y][x] = score;
    }
  }

  return map;
}

export const generateScoringMap = (scoringLocations) => {
  const map = new Array(RINK_HEIGHT/RESOLUTION).fill(0).map(() => new Array(RINK_WIDTH/RESOLUTION).fill(0));

  for (let x = 0; x < RINK_WIDTH/RESOLUTION; x++) {
    for (let y = 0; y < RINK_HEIGHT/RESOLUTION; y++) {
      const point = {
        x: x / (RINK_WIDTH/RESOLUTION - 1) * 2 - 1,
        y: y / (RINK_HEIGHT/RESOLUTION - 1) * 2 - 1
      }
      let score = 0;
      for (let location of scoringLocations) {
        score += location.weight * locationInfluence(point, location);
      }
      map[y][x] = score;
    }
  }

  return map;
}

export const mergeMaps = (maps) => {
  const map = new Array(RINK_HEIGHT/RESOLUTION).fill(0).map(() => new Array(RINK_WIDTH/RESOLUTION).fill(0));

  let totalWeight = 0;
  for (let map of maps) totalWeight += map.weight;

  for (let x = 0; x < RINK_WIDTH/RESOLUTION; x++) {
    for (let y = 0; y < RINK_HEIGHT/RESOLUTION; y++) {
      let score = 0;
      for (let map of maps)
        score += map.value[y][x] * (map.weight / totalWeight);
      
      map[y][x] = Number(score.toFixed(3));
    }
  }

  return map;
}

export const inverseMap = (map) => {
  const inverse = new Array(RINK_HEIGHT/RESOLUTION).fill(0).map(() => new Array(RINK_WIDTH/RESOLUTION).fill(0));

  for (let x = 0; x < RINK_WIDTH/RESOLUTION; x++) {
    for (let y = 0; y < RINK_HEIGHT/RESOLUTION; y++) {
      inverse[y][x] = map[y][x] * -1;
    }
  }

  return inverse;
}

const playerInfluence = (point, position, home, radius) => {
  const adjustedPlayerLocation = { x: position.x /RESOLUTION, y: position.y /RESOLUTION }; // Normalize to range [-1, 1]
  const distance = getDistance(point, adjustedPlayerLocation);
  
  if (distance > radius/RESOLUTION) {
    return 0;
  } else {
    if (home) return Math.min(1, Math.max(-1, Math.pow(1 - distance/(radius/RESOLUTION), 2)));
    else return -Math.min(1, Math.max(-1, Math.pow(1 - distance/(radius/RESOLUTION), 2)));
  }
}

const locationInfluence = (point, location) => {
  const distance = getDistance(point, location);
  if (distance > location.radius) {
    return 0;
  } else {
    return Math.min(1, Math.max(-1, Math.pow(1 - distance/location.radius, 2)));
  }
}

export const getCell = (map, position) => {
  let x = Math.floor(position.x / RINK_WIDTH * map[0].length);
  let y = Math.floor(position.y / RINK_HEIGHT * map.length);

  x = Math.max(0, Math.min(x, map[0].length - 1));
  y = Math.max(0, Math.min(y, map.length - 1));
 
  return map[y][x];
}