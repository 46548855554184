import { Flex, Tooltip } from '@mantine/core'
import React from 'react'
import { getContrast, isColorClose } from '../../lib/getContrast'
import { convertTime } from '../../lib/convertTime'
import { getPeriodTime } from '../../lib/getPeriodTime'

export const Rink = ({ events, time, players, home, away }) => {

  if (!events) events = []

  if (time >= 2460) events = events.filter(e => e.time >= 2520)
  else if (time >= 1200) events = events.filter(e => e.time >= 1260)

  const getLabel = (type) => {
    switch(type) {
      case 'Goal':
        return 'G';
      /*case 'Faceoff':
        return 'F';*/
      case 'Hit':
      case 'Big Hit':
        return 'H';
      case 'Wrap Around':
        return 'W';
      case 'High Danger Shot':
      case 'Rebound':
      case 'Deflection':
      case 'Shot On Net':
      case 'Point Shot':
      default:
        return 'S';
    }
  }

  const getTeamColor = (id) => {
    const player = players.find(p => p._id === id);
    if (player.ownerId === home.ownerId) return home.color;
    if (isColorClose(away.color, home.color)) return getContrast(home.color);
    return away.color;
  }


  return (
    <Flex w="600px" m="0 auto" maw="100%" justify="center">
      <div style={{ position: 'relative'}}>
        <img style={{ width: '100%' }} src='/images/rink.svg' alt='rink' />
        <div style={{
          position: 'absolute',
          top: '5%',
          left: '0%',
          width: 'calc(100% - 24px)',
          height: '82%'
        }}>
        {events.filter(e => (
          e.type === 'Goal' ||
          e.type === 'Shot On Net' ||
          e.type === 'Point Shot' ||
          e.type === 'Breakaway' || 
          e.type === 'High Danger Shot' || 
          e.type === 'Rebound' || 
          e.type === 'Deflection' || 
          e.type === 'Wrap Around' ||
          e.type === 'Hit' ||
          //e.type === 'Faceoff' ||
          e.type === 'Big Hit')).map((event, index) => (
          <Tooltip key={index} label={'[' + convertTime(getPeriodTime(event.time)) + '] ' + event.type + ' (' + (event.x * 100).toFixed(1) + ',' + (event.y * 100).toFixed(1) + ')'} placement="top">
            <div style={{
              cursor: 'pointer',
              position: 'absolute',
              top: (Math.min(Math.max(-1, event.y), 1) + 1) * 50 + '%',
              left: (Math.min(Math.max(-1, event.x), 1) + 1) * 50 + '%',
              backgroundColor: getTeamColor(event.playerId), //'var(--theme-color)',
              borderRadius: (event.type === 'Goal') ? '25%' : '50%',
              width: '24px',
              height: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: '0.8',
              zIndex: (event.type === 'Goal') ? 2 : 1,
              color: getContrast(getTeamColor(event.playerId)),
              fontweight: 'bold',
              boxShadow: '1px 2px 3px 1px rgba(0,0,0,0.2)',
            }}>
              {getLabel(event.type)}
            </div>
          </Tooltip>
        ))}
        </div>
      </div>
    </Flex>
  )
}
