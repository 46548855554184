import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TransitionIn } from '../Launch/TransitionIn';
import { TransitionOut } from '../Launch/TransitionOut';

import { TeamOverview } from './TeamOverview';
import { PackProgress } from './PackProgress';
import { ActiveGames } from './ActiveGames';

import './Dashboard.css';
import connection from '../../feathers';

export const Dashboard = () => {
  const navigate = useNavigate();
  const [transitionIn, setTransitionIn] = useState(true);
  const [transitionOut, setTransitionOut] = useState(false);
  
  const load = async () => {
    try {
      const user = await connection.get('authentication');
      const res = await connection.service('teams').find({ query: { ownerId: user.user._id } });
      if (res.total <= 0) navigate('/join');
    } catch (error) {
      console.error(error);
      navigate('/');
    }
  }
  useEffect(() => {
    load();

    setTimeout(() => {
      setTransitionIn(false);
    }, 1500);
  }, [])
  
  const navTo = (path) => {
    setTransitionOut(true);

    setTimeout(() => {
      navigate(path);
    }, 1000);
  }

  return (
    <div className='dashboard'>
      <ActiveGames />
      <TeamOverview navigate={navTo} />
      <PackProgress />
      <div>
        {/*
        Show team overview and last 10 match records
        Show top players in Goals, Points, and Hits?
        */}
      </div>
      
      {(transitionIn) ? <TransitionIn /> : ''}
      {(transitionOut) ? <TransitionOut /> : ''}
    </div>
  )
}
