import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useInputState } from '@mantine/hooks';
import { Button, ColorInput, Container, Flex, Stepper, Text, TextInput, Title } from '@mantine/core'

import { TransitionIn } from '../Launch/TransitionIn';
import { TransitionOut } from '../Launch/TransitionOut';
import { CardPack } from '../Packs/CardPack';

import './CreateTeam.scss';
import { DisplayPack } from '../Packs/DisplayPack';
import connection from '../../feathers';
import { AuthContext } from '../../Contexts';
import { notifications } from '@mantine/notifications';

const tempPacks = [
  {
    id: '4308JTH43G0',
    type: 'B',
  },
]

export const CreateTeam = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [color, setColor] = useState('');
  const [city, setCity] = useInputState('');
  const [name, setName] = useInputState('');
  const [abbr, setAbbr] = useInputState('');
  const [transitionIn, setTransitionIn] = useState(true);
  const [transitionOut, setTransitionOut] = useState(false);
  const [user, setUser] = useState(null)

  const load = async () => {

    try {
      await connection.reAuthenticate();
      let user = await connection.get('authentication')
      //console.log(user);
      setUser(user.user);
      const team = await connection.service('teams').find({ query: { ownerId: user.user._id } });
      if (team.total > 0) navigate('/dashboard');
    } catch (error) {
      //console.log(error);
      navigate('/');
    }
  }

  useEffect(() => {
    load();

    setTimeout(() => {
      setTransitionIn(false);
    }, 1500);
  }, [])

  const goToOpenPacks = async () => {
    const team = await connection.service('teams').create({
      ownerId: user._id, 
      name: name,
      abbr: abbr,
      city: city,
      color: color,
    });
    //console.log('team', team);

    setTransitionOut(true);

    setTimeout(() => {
      navigate('/packs');
    }, 1000);
  }

  const next = () => {
    if (name.length > 0
    && abbr.length > 0
    && city.length > 0
    && color.length > 0) {
      if (abbr.length < 4) {
        setActive(1);
      } else {
        notifications.show({ title: 'Team Abbreviation must be 4 characters or less', color: 'red' });
      }
    } else
      notifications.show({ title: 'Please fill out all fields', color: 'red' });
  }

  return (
    <div className='background join'>
      {(active === 0) ? (
        <div className='joinSection'>
          <TextInput value={city} onChange={setCity} size="lg" label="City" placeholder="Enter the city your team is from" />
          <TextInput value={name} onChange={setName} size="lg" label="Team Name" placeholder="Enter a team name" />
          <TextInput value={abbr} onChange={setAbbr} size="lg" label="Team Abbreviation" placeholder="Enter a team abbreviation" max="5" />
        
          <ColorInput color={(color) ? color : ''} label="Home Color" withPreview={false}
            placeholder="Enter a home color for your team"
            value={color}
            onChange={setColor}
            radius="xl"
            size="lg"
            withAsterisk  />
          <Button size="lg" mt="1rem" onClick={next}>Next</Button>
        </div>
      ) : ''}
      {(active === 1) ? (
        <Flex direction="column">
          <Title align='center'>You Have Been Gifted Starter Packs</Title>
          <Flex wrap="wrap" mt="2rem" justify="center" gap="0.5rem">
            <Flex align="center" direction="column">
              <DisplayPack type="B" count={tempPacks.filter((pack) => pack.type === "B").length} />
              <Title mt="0.5rem" size="1.5rem">B Pack</Title>
            </Flex>
            {/*<Flex align="center" direction="column">
              <DisplayPack type="C" count={tempPacks.filter((pack) => pack.type === "C").length} />
              <Title mt="0.5rem" size="1.5rem">C Packs</Title>
            </Flex>*/}
          </Flex>
          <Button size="lg" mt="2rem" onClick={goToOpenPacks}>Open Packs</Button>
        </Flex>
      ) : ''}

      {(transitionIn) ? <TransitionIn /> : ''}
      {(transitionOut) ? <TransitionOut /> : ''}
    </div>
  )
}
