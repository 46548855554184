export const getGameProgress = async (game, players) => {
  const gameTime = (Date.now() - game.startTime) / 1000;

  if (gameTime < 0) {
    return {
      home: {
        goals: 0,
        shots: 0,
      },
      away: {
        goals: 0,
        shots: 0,
      },
    }
  } else if (gameTime > 3720) {
    // game is over but final cooldown is up
    return {
      home: {
        goals: game.homeStats.goals,
        shots: game.homeStats.shots,
      },
      away: {
        goals: game.awayStats.goals,
        shots: game.awayStats.shots,
      },
    }
  } else {
    // calculate based on events
    // get the goal count
    const goals = game.events.filter((event) => event.type === 'Goal' && event.time <= gameTime);
    const shots = game.events.filter((event) => (event.type === 'Save' || event.type === 'Goal') && event.time <= gameTime);
    // get the goals by team based on playerId
    let homeGoals = 0;
    let awayGoals = 0;
    let homeShots = 0;
    let awayShots = 0;
    goals.forEach((goal) => {
      const player = players.find((player) => player._id === goal.playerId);
      if (player) {
        if (player.ownerId === game.homeId) {
          homeGoals++;
        } else {
          awayGoals++;
        }
      }
    })
    shots.forEach((shot) => {
      const player = players.find((player) => player._id === shot.playerId);
      if (player) {
        if ((shot.type === 'Save' && player.ownerId === game.homeId) || 
        (shot.type === 'Goal' && player.ownerId === game.awayId)) {
          awayShots++;
        } else {
          homeShots++;
        }
      }
    })
    return {
      home: {
        goals: homeGoals,
        shots: homeShots,
      },
      away: {
        goals: awayGoals,
        shots: awayShots,
      },
      events: game.events.filter((event) => event.time <= gameTime).reverse()
    }
  }
}