export const getPackClass = (type) => {
  switch(type) {
    case 'SSS+':
      return 'cardPack float sssplus-pack';
    case 'SSS':
      return 'cardPack float sss-pack';
    case 'SS':
      return 'cardPack float ss-pack';
    case 'S':
      return 'cardPack float s-pack';
    case 'B':
    case 'C':
    case 'R':
    default:
      return 'cardPack float b-pack';
  }
}

export const getSimplePackClass = (type) => {
  switch(type) {
    case 'SSS+':
      return 'cardPack sssplus-pack';
    case 'SSS':
      return 'cardPack sss-pack';
    case 'SS':
      return 'cardPack ss-pack';
    case 'S':
      return 'cardPack s-pack';
    case 'B':
    case 'C':
    case 'R':
    default:
      return 'cardPack b-pack';
  }
}

export const getPackSVG = (type) => {
  switch(type) {
    case 'SSS+':
      return '/images/saihl/sssplus_card_pack.svg';
    case 'SSS':
      return '/images/saihl/sss_card_pack.svg';
    case 'SS':
      return '/images/saihl/ss_card_pack.svg';
    case 'S':
      return '/images/saihl/s_card_pack_v2.svg';
    case 'B':
      return '/images/saihl/b_card_pack.svg';
    case 'C':
      return '/images/saihl/c_card_pack.svg';
    case 'R':
    default:
      return '/images/saihl/r_card_pack.svg';
  }
}