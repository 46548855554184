export const getPeriodTimeDown = (time) => {
  if (time < 1200) {
    return 1200 - time;
  }
  if (time < 1260) {
    return 60 - (time - 1200);
  }
  if (time < 2460) {
    return 1200 - (time - 1260);
  }
  if (time < 2520) {
    return 60 - (time - 2460);
  }
  if (time < 3720) {
    return 1200 - (time - 2520);
  }
  if (time < 4020) {
    return 300 - (time - 3720);
  }
  return null;
}

export const getPeriodTime = (time) => {
  if (time <= 1200) {
    return time;
  }
  if (time < 1260) {
    return time - 1200;
  }
  if (time <= 2460) {
    return time - 1260;
  }
  if (time < 2520) {
    return time - 2460;
  }
  if (time <= 3720) {
    return time - 2520;
  }

  return time - 3720;
}