export const getContrast = (color) => {
  const rgb = hexToRgb(color);
  const brightness = Math.round(((parseInt(rgb.r) * 299) +
                    (parseInt(rgb.g) * 587) +
                    (parseInt(rgb.b) * 114)) / 1000);
  if (brightness > 150) return "#000000";
  return "#ffffff";
}

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const isColorClose = (colorA, colorB) => {
  const rgbA = hexToRgb(colorA);
  const rgbB = hexToRgb(colorB);
  
  const distance = Math.abs(rgbA.r - rgbB.r) + Math.abs(rgbA.g - rgbB.g) + Math.abs(rgbA.b - rgbB.b);
  
  return distance < 100;
}