import React, { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { Button, Flex, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import connection from '../../feathers';
import { TransitionIn } from '../Launch/TransitionIn';
import { TransitionOut } from '../Launch/TransitionOut';
import { DisplayPack } from '../Packs/DisplayPack';
import { OpenPack } from './OpenPack';

import './OpenPacks.scss';

const packTypes = ['SSS+', 'SSS', 'SS', 'S', 'B', 'C', 'R'];

export const PackList = () => {
  const [transitionIn, setTransitionIn] = useState(true);
  const [transitionOut, setTransitionOut] = useState(false);
  const [selectedPackType, setSelectedPackType] = useState(null);
  const [players, setPlayers] = useState([]);
  
  const navigate = useNavigate();

  const [packs, setPacks] = useState([]);

  useEffect(() => {
    const load = async () => {
      let user = await connection.get('authentication');
      user = user.user;

      let packs = await connection.service('packs').find({
        query: {
          ownerId: user._id,
        }
      });
      setPacks(packs.data);

      let data = await connection.service('players').find({
        paginate: false,
        query: {
          $limit: 100,
          ownerId: user._id,
        }
      });
      setPlayers(data.data);
    }
    load();
    connection.service('packs').on('removed', load);

    setTimeout(() => {
      setTransitionIn(false);
    }, 1500);
  }, [])

  const renderPacks = () => {
    let results = [];

    packTypes.forEach((type) => {
      let packList = packs.filter((pack) => pack.type === type);

      results.push(
        <div key={type} onClick={() => {
          if (!selectedPackType && packList.filter(e => e.type === type).length > 0 && players.length < 34)
            setSelectedPackType(type)
          else {
            if (players.length >= 34)
              notifications.show({
                title: 'Too Many Contracts',
                message: 'You have too many contracts! Release some players to make room for more.',
                autoClose: 5000,
                color: 'red',
                withCloseButton: true,
              })
          }
        }}>
          <DisplayPack type={type} count={packList.length} />
        </div>
      )
    })

    return results;
  }

  const setLines = () => {
    // Transition out
    setTransitionOut(true);

    setTimeout(() => {
      navigate('/lines');
    }, 1500);
  }

  return (
    <div className='background openPacks'>
      <Title>Open Packs</Title>

      <Flex wrap="wrap" justify="center">
        {renderPacks()}
      </Flex>

      {(selectedPackType) ? (
        <OpenPack packs={packs.filter(e => e.type === selectedPackType)} type={selectedPackType} callback={() => setSelectedPackType(null)} />
      ) : ''}


      <Button w="100%" mt="2rem" size="lg" onClick={setLines}>Set Lines</Button>
      
      {(transitionIn) ? <TransitionIn /> : ''}
      {(transitionOut) ? <TransitionOut /> : ''}
    </div>
  )
}
