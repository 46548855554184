import { Flex, Text, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import connection from '../../feathers';
import { Link } from 'react-router-dom';

export const Standings = () => {
  const [teams, setTeams] = useState(null);
  
  const load = async () => {
    // get all teams
    const res = await connection.service('teams').find({
      query: {
        $limit: 100,
        $sort: {
          wins: -1,
        }
      }
    });
    //console.log('teams', res);
    setTeams(res.data.map((team) => {
      return {
        ...team,
        total: team.wins + team.losses + team.draws,
        pointsPercentage: (team.wins * 3 + team.draws) / ((team.wins + team.losses + team.draws)* 3),
      }
    }));
  }

  useEffect(() => {
    load();
  }, []);

  const renderTeams = () => {
    return teams.filter((e) => e.gamesPlayed >= 10).sort((a,b) => a.wins < b.wins ? 1 : -1).sort((a,b) => a.pointsPercentage < b.pointsPercentage ? 1 : -1).map((team, index) => {
      if (!team) return '';
      if (team.name.length <= 0) return '';
      return (
        <Link to={`/teams/${team._id}`} key={team._id} className='teamLink'>
          <Flex key={team._id} gap="1rem" justify="center">
            <Text className='centered' w="30px">{index+1}</Text>
            <Text ta="left" w="200px" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>[<span style={{ display: 'inline-block', textAlign: 'center', width: '26px', textTransform: 'uppercase' }}>{team.abbr}</span>] {team.city} {team.name}</Text>
            <Text className='centered' w="30px">{Math.round(team.pointsPercentage * 100)}%</Text>
            <Text className='centered' w="30px">{team.total}</Text>
            <Text className='centered' w="60px" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{team.wins}-{team.losses}-{team.draws}</Text>
          </Flex>
        </Link>
      )
    })
  }

  const renderTeamsLimited = () => {
    return teams.filter((e) => e.gamesPlayed < 10).sort((a,b) => a.abbr < b.abbr ? 1 : -1).map((team, index) => {
      if (!team) return '';
      if (team.name.length <= 0) return '';
      return (
        <Link to={`/teams/${team._id}`} key={team._id} className='teamLink'>
          <Flex key={team._id} gap="1rem" justify="center" style={{ color: 'rgba(255,255,255,0.3)' }}>
            <Text className='centered' w="30px">{index+1+teams.filter((e) => e.gamesPlayed >= 10).length}</Text>
            <Text ta="left" w="200px" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>[<span style={{ display: 'inline-block', textAlign: 'center', width: '26px', textTransform: 'uppercase' }}>{team.abbr}</span>] {team.city} {team.name}</Text>
            <Text className='centered' w="30px">---</Text>
            <Text className='centered' w="30px">{team.total}</Text>
            <Text className='centered' w="60px" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{team.wins}-{team.losses}-{team.draws}</Text>
          </Flex>
        </Link>
      )
    })
  }

  if (!teams) return '';

  return (
    <div style={{ padding: '1rem', textAlign: 'center' }}>
      <Title>Standings</Title>
      <Text italic>Need a minimum of 10 games to rank in the standings.</Text>
      <Flex justify="center" gap="1rem">
        <Text className='centered' w="30px">Rank</Text>
        <Text ta="left" w="200px">Name</Text>
        <Text className='centered' w="30px">Pt%</Text>
        <Text className='centered' w="30px">GP</Text>
        <Text className='centered' w="60px">Record</Text>
      </Flex>
      {renderTeams()}
      {renderTeamsLimited()}
    </div>
  )
}
