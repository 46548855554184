import { Flex, Text, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'

import { convertSinglePlayerObject } from '../../lib/convertPlayerObject';

import './PlayerCard.scss';
import { PlayerTypes } from '../../data/types';
import { getShortenedPlayerName } from '../../lib/getShortenedName';

export const PlayerCard = ({ playerTarget }) => {
  const [revealed, setRevealed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [dropped, setDropped] = useState(false);
  const [player, setPlayer] = useState(convertSinglePlayerObject(playerTarget));

  useEffect(() => {
    setTimeout(() => {
      setDropped(true);
    }, 500);
  }, [])

  if (!player) return '';
  return (
    <div className={(revealed) ? 'playerCard revealed' : 'playerCard'} onClick={() => setRevealed(true)} key={player._id} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} >
      <div className='front'>
        <img className={(player.overall() >= 0.9) ? 'sss-glow' : (player.overall() >= 0.8) ? 'ss-glow' : ''} src="/images/saihl/card_front.svg" alt={player.bio.getName()} />
      </div>
      <div className={(!dropped) ? 'drop' : 'back'}>
        <img className={(hovered && player.overall() >= 0.9) ? 'sss-glow' : (hovered && player.overall() >= 0.8) ? 'ss-glow' : ''} src="/images/saihl/card_back.svg" alt={player.bio.getName()} />
      </div>
      <Flex className='playerCardInfo front' direction="column" h="75%" w="140px">
        <Flex className='playerCardTop'>
          <Flex justify="center" direction="column" align="center">
            <Title className='numbers' weight={400} size="4rem" m="0" mt="2rem" p="0" h="55px">{(player.overall() * 100).toFixed(0)}</Title>
            <Text size="1.25rem" mt="-1.5rem">{player.type.abbr}</Text>
            <img className='flag' src={player.bio.nationality.flag} alt={player.bio.nationality.iso3 + " flag"} />
          </Flex>
          <div className='statChart'>
            <Flex justify="flex-end">
              <div>MNT</div>
              <div className='statValue'>{(player.overall('mental') * 100).toFixed(0)}</div>
            </Flex>
            <Flex justify="flex-end">
              <div>ATH</div>
              <div className='statValue'>{(player.overall('athletic') * 100).toFixed(0)}</div>
            </Flex>
            <Flex justify="flex-end">
              <div>TEC</div>
              <div className='statValue'>{(player.overall('technique') * 100).toFixed(0)}</div>
            </Flex>
            {(player.type !== PlayerTypes.Goaltender) ? <Flex justify="flex-end">
              <div>SHT</div>
              <div className='statValue'>{(player.overall('shooting') * 100).toFixed(0)}</div>
            </Flex> : ''}
            {(player.type !== PlayerTypes.Goaltender) ? <Flex justify="flex-end">
              <div>DEF</div>
              <div className='statValue'>{(player.overall('defense') * 100).toFixed(0)}</div>
            </Flex> : ''}
            {(player.type === PlayerTypes.Goaltender) ? <Flex justify="flex-end">
              <div>RFX</div>
              <div className='statValue'>{(player.overall('reflexes') * 100).toFixed(0)}</div>
            </Flex> : ''}
          </div>
        </Flex>
        <Text mt="auto" size="1.5rem" align='center'>{getShortenedPlayerName(player)}</Text>
      </Flex>
    </div>
  )
}
