import React from 'react'
import { getPackClass, getPackSVG } from './PackUtility';

import './CardPack.scss';

export const CardPack = ({ type, callback, offset=0, jitter=false }) => {
  return (
    <div className="cardPackWrapper opening" style={{ marginTop: offset + 'px'}}>
      <div className={getPackClass(type)}>
        <div className={(jitter) ? 'jitter' : ''}>
          <div className='yaw' style={{ animationDelay: Math.random() * 5 + 's', animationDuration: Math.random() * 8 + 12 + 's' }}>
            <img src={getPackSVG(type)} alt="sss card pack float" style={{ animationDelay: Math.random() * 2 + 's', animationDuration: Math.random() * 5 + 9 + 's' }} />
          </div>
        </div>
      </div>
    </div>
  )
}
