import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MantineProvider theme={{
        // Override any other properties from default theme
        colorScheme: 'dark',
        fontFamily: 'Oswald, sans serif',
        colors: {
          'brand': ['#2e0414', '#EC1668', '#f52373', '#fc3581'],
        },
        primaryColor: 'brand',
        primaryShade: 1
      }}>
        <Notifications />
        <App />
    </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
