import React from 'react'
import { PlayerCard } from './PlayerCard'

import './PlayerCard.scss';

export const PlayerCardList = ({ onClick, players }) => {
  const renderPlayers = () => {
    return players.map((player, index) => {
      return <PlayerCard key={player._id} playerTarget={player} />
    })
  }

  return (
    <div onClick={onClick} className='playerCards'>
      {renderPlayers()}
    </div>
  )
}
