import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, Flex, Indicator, Text } from '@mantine/core';
import { IoMenu } from 'react-icons/io5';
import { useDisclosure } from '@mantine/hooks';
import { IoMdNotificationsOutline } from 'react-icons/io';

import connection from '../feathers';
import { TransitionIn } from './Launch/TransitionIn';
import { TransitionOut } from './Launch/TransitionOut';

import './Layout.scss';
import { Notifications } from './Notifications/Notifications';

export const Layout = ({ children }) => {
  const [navOpened, { open, close }] = useDisclosure(false);
  const [notificationsPanel, { open: openPanel, close: closePanel }] = useDisclosure(false);
  const navigate = useNavigate();

  const [transitionIn, setTransitionIn] = useState(true);
  const [transitionOut, setTransitionOut] = useState(false);

  const [user, setUser] = useState(null)
  const [balance, setBalance] = useState(0);

  const [notifications, setNotifications] = useState([]);

  const loadNotifications = async () => {
    let currentUser = await connection.get('authentication');
    const res = await connection.service('notifications').find({
      query: {
        userId: currentUser.user._id,
        $sort: {
          createdAt: -1
        }
      }
    });
    setNotifications(res.data);
  }

  useEffect(() => {
    const load = async () => {
      try {
        await connection.reAuthenticate();
        let user = await connection.get('authentication');
        //console.log('authentication', user)
        setUser(user.user);
        setBalance(user.user.balance);

        // update the balance on user patch
        connection.service('users').on('patched', (u) => {
          if (user.user._id === u._id) setBalance(u.balance);
        })

        connection.service('notifications').on('patched', (n) => {
          if (user.user._id === n.userId) loadNotifications();
        })

        connection.service('notifications').on('removed', (n) => {
          if (user.user._id === n.userId) loadNotifications();
        })

        connection.service('notifications').on('created', (n) => {
          if (user.user._id === n.userId) loadNotifications();
        })

        loadNotifications();
      } catch (error) {
        //console.log(error);
        navigate('/');
      }
    }

    setTransitionIn(true);
    load();

    setTimeout(() => {
      setTransitionIn(false);
    }, 1500);
  }, [])


  const logout = async () => {
    connection.logout();
    navigate('/');
  }

  const linkTo = (route) => {
    close();
    setTransitionOut(true);

    setTimeout(() => {
      navigate(route);
      setTimeout(() => {
        setTransitionOut(false);
      }, 200);
    }, 1000);
  }

  // Change Transition to use the same card for both in and out

  if (!user) return null;
  return (
    <div className='layout'>
      <Drawer className='saihlNotificationsList' opened={notificationsPanel} onClose={closePanel} title="Notifications">
        <Notifications notifications={notifications} />
      </Drawer>
      <Flex className='layoutNav' justify="space-between" align="center">
        <Drawer opened={navOpened} position="right" onClose={close} title="Navigation">
          <Flex direction="column" align="center" justify="flex-start" className='layoutNavMenu' style={{ textAlign: 'center' }}>
            <div className='layoutNavMenuLink' onClick={() => linkTo('/dashboard')}>Dashboard</div>
            <div className='layoutNavMenuLink' onClick={() => linkTo('/freeagents')}>Free Agents</div>
            <div className='layoutNavMenuLink' onClick={() => linkTo('/packs')}>Packs</div>
            <div className='layoutNavMenuLink' onClick={() => linkTo('/roster')}>Manage Roster</div>
            <div className='layoutNavMenuLink' onClick={() => linkTo('/lines')}>Set Lines</div>
            <div className='layoutNavMenuLink' onClick={() => linkTo('/standings')}>Standings</div>
            {/*<div className='layoutNavMenuLink' onClick={() => linkTo('/shop')}>Free Packs</div>*/}
            <div className='layoutNavMenuLink' style={{ marginTop: '5rem'}} onClick={logout}>Logout</div>
            <a style={{ marginTop: '1rem', textTransform: 'uppercase'}} href="https://discord.gg/wmgJ7C2RwW" target='_blank' rel="noreferrer">Discord</a>
          </Flex>
        </Drawer>
        <div className='layoutLogo' onClick={() => linkTo('/dashboard')}>
          <img src="/images/saihl/saihl_color.svg" alt="SAIHL Logo" />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Text>{(balance || 0).toFixed(3)}M</Text>
        </div>
        <div onClick={openPanel} style={{ padding: "1rem", marginLeft: '1rem', cursor: 'pointer' }}>
          <Indicator position="bottom-end" processing size={8} disabled={notifications.filter(e => !e.read).length <= 0}>
            <IoMdNotificationsOutline size="1.5rem" />
          </Indicator>
        </div>
        <div className='layoutNavMenuButton' onClick={open}>
          <IoMenu size="2rem" />
        </div>
      </Flex>
      {children}

      <div style={{ position: 'fixed', bottom: '0', left: '0', padding: '0.25rem', fontSize: '0.8rem', color: 'rgba(255,255,255,0.25)' }}>
        V0.1.5
      </div>

      {(transitionIn) ? <TransitionIn /> : ''}
      {(transitionOut) ? <TransitionOut /> : ''}
    </div>
  )
}
