import io from 'socket.io-client'
import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import authentication from '@feathersjs/authentication-client'

//export const PUBLIC_API = 'http://localhost:9050'
export const PUBLIC_API = 'https://api.saihleague.com'

const socket = io(PUBLIC_API)
const connection = feathers()
const socketClient = socketio(socket)

connection.configure(socketClient)
connection.configure(authentication())
connection.use('claim', socketClient.service('claim'), {
  methods: ['claim']
})

export default connection;