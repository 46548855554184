import React, { useEffect, useState } from 'react'
import connection from '../../feathers';
import { Flex } from '@mantine/core';
import { ActiveGamePreview } from './ActiveGamePreview';

export const ActiveGames = () => {
  const [games, setGames] = useState([]);

  const getGames = async () => {
    let user = await connection.get('authentication');
    user = user.user;

    const res = await connection.service('games').find({
      query: {
        $limit: (window.innerWidth > 600) ? 4 : 2,
        $and: [
          { homeId: { $ne : user._id }},
          { awayId: { $ne : user._id }}
        ],
        startTime: {
          $gt: Date.now() - 4020000
        }
      }
    })

    setGames(res.data);
  }

  useEffect(() => {
    const load = async () => {
      // connect to the on create game hook
      connection.service('games').on('created', async (game) => {
        setTimeout(() => {
          getGames();
        }, 1000);
      });

      getGames();
    }

    load();
  }, []);

  const renderGames = () => {
    return games.map((game) => {
      return <ActiveGamePreview key={game._id} game={game} />
    })
  }

  if (games.length <= 0) return '';
  return (
    <Flex gap="1rem" align="center" justify="center" maw="100%" style={{ overflow: 'hidden' }}>
      {renderGames()}
    </Flex>
  )
}
