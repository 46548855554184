export const getGamePeriod = (time, shortened=false) => {
  if (time <= 1200) {
    return (!shortened) ? '1st Period' : '1st';
  }
  if (time < 1260) {
    return (!shortened) ? '1st Intermission' : 'INT';
  }
  if (time <= 2460) {
    return (!shortened) ? '2nd Period' : '2nd';
  }
  if (time < 2520) {
    return (!shortened) ? '2nd Intermission' : 'INT';
  }
  if (time <= 3720) {
    return (!shortened) ? '3rd Period' : '3rd';
  }

  return 'Final';
}