import { Flex, Tabs, Text, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import connection from '../../feathers';
import { RosterPlayer } from '../Roster/RosterPlayer';
import { convertPlayerObject } from '../../lib/convertPlayerObject';
import { shadeColor } from '../../lib/shadeColor';
import { getShortenedPlayerName } from '../../lib/getShortenedName';

import './Team.scss'

export const Team = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [team, setTeam] = useState(null);
  const [activeTab, setActiveTab] = useState('record');

  const [players, setPlayers] = useState([]);
  const [user, setUser] = useState(null);
  const [games, setGames] = useState([]);


  useEffect(() => {
    const load = async () => {
      const res = await connection.service('teams').get(id);
      setTeam(res);

      const playersRes = await connection.service('players').find({
        query: {
          $limit: 50,
          ownerId: res.ownerId,
          $sort: {
            overallValue: -1
          }
        }
      });
      setPlayers(convertPlayerObject(playersRes.data));

      const userRes = await connection.get('authentication');
      setUser(userRes.user);

      const gamesRes = await connection.service('games').find({
        query: {
          $limit: 10,
          $sort: {
            startTime: -1
          },
          $or: [
            { homeId: res.ownerId },
            { awayId: res.ownerId }
          ],
          startTime: {
            $lt: Date.now() - 3720000
          },
        }
      });
      setGames(gamesRes.data);
    }
    load();
  }, []);

  const renderPlayers = () => {
    return players.map((player, index) => {
      return <RosterPlayer key={player._id} index={index} player={player} update={()=>{}} user={user} />
    })
  }

  const renderLastGames = () => {
    let results = [];

    games.forEach((game, index) => {
      const homeTeam = game.homeId === team.ownerId;
      const tie = game.homeStats.goals === game.awayStats.goals;
      const won = (homeTeam && game.homeStats.goals > game.awayStats.goals) || (!homeTeam && game.homeStats.goals < game.awayStats.goals);
      results.push(
        <Flex onClick={() => navigate((((Date.now() - game.startTime) / 1000 < 3700) ? '/live/' : '/recap/') + game._id)} key={game._id} className='lastTenGameItem' gap="1rem" justify="space-between" align="center" style={{
          textAlign: 'center',
          cursor: 'pointer',
          padding: '0.75rem',
          width: '100%',
          backgroundColor: (index % 2 === 0) ? shadeColor('#ec1668', -70) : shadeColor('#ec1668', -75),
        }}>
          <div style={{ textAlign: 'left', fontSize: '1.25rem', width: ((window.innerWidth < 600)) ? '100px' : '300px' }}>
            {(window.innerWidth < 600) ? (
              (homeTeam) ? game.away.abbr : game.home.abbr
            ) : (
              (homeTeam) ? game.away.city + ' ' + game.away.name : game.home.city + ' ' + game.home.name
            )}
          </div>
          <div style={{ width: '50px', textAlign: 'left' }}>{new Date(game.startTime).toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
          })}</div>
          <div>{(homeTeam) ? 'H' : 'A'}</div>
          <div style={{ width: '50px' }}>{((Date.now() - game.startTime) / 1000 < 3700) ? '' : (homeTeam) ? game.homeStats.goals + ' - ' + game.awayStats.goals : game.awayStats.goals + ' - ' + game.homeStats.goals}</div>
          <div style={{ width: '35px', textAlign: 'right' }}>{((Date.now() - game.startTime) / 1000 < 3700) ? 'Live' : (tie) ? 'Draw' : (won) ? 'Win' : 'Lose'}</div>
        </Flex>
      )
    })

    return results;
  }

  const getPlayerPosition = (line, position) => {
    const player = players.find(player => player.line === line && player.position === position);
    if (!player) return (<div className='linePlayer empty'>Empty</div>)
    return (
      <div key={player._id} className='linePlayer'>
        <div>{getShortenedPlayerName(player)}</div>
        <div style={{ fontSize: '0.8rem', marginTop: '0.25rem', color: 'rgba(255, 255, 255, 0.75)'}}>{player.type.abbr + ' | ' + (player.overall() * 100).toFixed(0)}</div>
      </div>
    )
  }

  if (!team) return (<div>Loading...</div>)

  return (
    <div>
      <Flex direction="column" m="1rem 0.5rem">
        <Text lh="1" size="1.5rem" tt="uppercase">{team.city}</Text>
        <Text lh="1" size="3rem" tt="uppercase">{team.name}</Text>
      </Flex>
      
      <Tabs defaultValue={activeTab} onTabChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="record">Last 10</Tabs.Tab>
          <Tabs.Tab value="roster">Roster</Tabs.Tab>
          <Tabs.Tab value="line">Lines</Tabs.Tab>
        </Tabs.List>
      </Tabs>

      {(activeTab === 'record') ? (
        <div>
          <Flex align="center" gap="1rem">
            <Title lh='1' size="1.5rem" tt="uppercase" mt="1rem">Record</Title>
            <Title lh='1' size="1.5rem" tt="uppercase" mt="1rem">{team.wins}-{team.losses}-{team.draws}</Title>
          </Flex>
          <div>
            <Title size="1.5rem" tt="uppercase" mt="1rem">Last 10</Title>
            {renderLastGames()}
          </div>
        </div>
      ) : ''}
      {(activeTab === 'roster') ? (
        <div>
          {renderPlayers()}
        </div>
      ) : ''}
      {(activeTab === 'line') ? (
        <div>
          <Title my="1rem" size="1.5rem">Forwards</Title>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 1</Text>
          {getPlayerPosition(1, 'leftWing')}
          {getPlayerPosition(1, 'center')}
          {getPlayerPosition(1, 'rightWing')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 2</Text>
          {getPlayerPosition(2, 'leftWing')}
          {getPlayerPosition(2, 'center')}
          {getPlayerPosition(2, 'rightWing')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 3</Text>
          {getPlayerPosition(3, 'leftWing')}
          {getPlayerPosition(3, 'center')}
          {getPlayerPosition(3, 'rightWing')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 4</Text>
          {getPlayerPosition(4, 'leftWing')}
          {getPlayerPosition(4, 'center')}
          {getPlayerPosition(4, 'rightWing')}
        </Flex>
        <Title my="1rem" size="1.5rem">Defense</Title>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 1</Text>
          {getPlayerPosition(1, 'leftDefense')}
          {getPlayerPosition(1, 'rightDefense')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 2</Text>
          {getPlayerPosition(2, 'leftDefense')}
          {getPlayerPosition(2, 'rightDefense')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Line 3</Text>
          {getPlayerPosition(3, 'leftDefense')}
          {getPlayerPosition(3, 'rightDefense')}
        </Flex>
        <Title my="1rem" size="1.5rem">Goalies</Title>
        <Flex gap="0.5rem">
          <Text className='lineText'>Starter</Text>
          {getPlayerPosition(1, 'starter')}
        </Flex>
        <Flex gap="0.5rem">
          <Text className='lineText'>Backup</Text>
          {getPlayerPosition(1, 'backup')}
        </Flex>
        </div>
      ) : ''}
    </div>
  )
}
