import { Badge, Flex, Text } from '@mantine/core'
import React from 'react'
import { getShortenedPlayerName } from '../../lib/getShortenedName';
import { getGamePeriod } from '../../lib/getGamePeriod';
import { getPeriodTime } from '../../lib/getPeriodTime';
import { convertTime } from '../../lib/convertTime';
import { getContrast, isColorClose } from '../../lib/getContrast';

export const Event = ({ event, players, home, away }) => {

  const getPlayer = (id) => {
    return players.find(p => p._id === id);
  }

  const getEventText = () => {
    const player = getPlayer(event.playerId);
    //console.log(event.playerId, player, players)
    let target = '';
    if (event.targetId) target = getPlayer(event.targetId);

    if (!player.bio) return 'OOPS'

    switch (event.type) {
      case 'Goal':
        return <Text size="1.75rem" mb="0.5rem">
          {getShortenedPlayerName(player)} scores!
          <Text size="1.25rem">Assisted By: {event.assists.map((a, index) => 
          getShortenedPlayerName(getPlayer(a)) + ((index < 1 && event.assists.length > 1) ? ', ' : ''))}</Text>
        </Text>
      case 'Shot':
      case 'Shot Attempt':
        return `${getShortenedPlayerName(player)} shoots!`;
      case 'High Danger Shot':
        return `${getShortenedPlayerName(player)} has a dangerous chance!`;
      case 'Rebound':
        return `${getShortenedPlayerName(player)} gets a rebound!`;
      case 'Hit':
        return `${getShortenedPlayerName(player)} hits ${getShortenedPlayerName(target)}!`;
      case 'Big Hit':
        return `${getShortenedPlayerName(player)} delivers some punishment to ${getShortenedPlayerName(target)}!`;
      case 'Block':
        return `${getShortenedPlayerName(player)} blocks the shot!`;
      case 'Takeaway':
        return `${getShortenedPlayerName(player)} steals the puck from ${getShortenedPlayerName(target)}!`;
      case 'Faceoff':
        return `${getShortenedPlayerName(player)} faces off against ${getShortenedPlayerName(target)}!`;
      case 'Faceoff Win':
        if (event.playerId === event.targetId) return `${getShortenedPlayerName(player)} wins the faceoff clean!`;
        return `${getShortenedPlayerName(player)} wins the faceoff and gets it to ${getShortenedPlayerName(target)}!`;
      case 'Save':
        return `${getShortenedPlayerName(player)} makes the save!`;
      case 'Interception':
        return `${getShortenedPlayerName(player)} intercepts the pass!`;
      case 'Deflection':
        return `${getShortenedPlayerName(player)} deflects the puck!`;
      case 'Breakaway':
        return `${getShortenedPlayerName(player)} gets a breakaway!`;
      case 'Pass Attempt':
        if (event.playerId === event.targetId) return `${getShortenedPlayerName(player)} banks it off the boards!`;
        return `${getShortenedPlayerName(player)} passes to ${getShortenedPlayerName(target)}!`;
      case 'Clear':
        return `${getShortenedPlayerName(player)} clears the puck!`;
      case 'Dump In':
        return `${getShortenedPlayerName(player)} dumps the puck in!`;
      case 'Wrap Around':
        return `${getShortenedPlayerName(player)} tries to wrap around the net!`;
      case 'One Timer':
        return `${getShortenedPlayerName(player)} one times the puck to ${getShortenedPlayerName(target)}!`;
      case 'Dangle':
        return `${getShortenedPlayerName(player)} dekes!`;
      case 'Shot Missed':
        return `${getShortenedPlayerName(player)} misses the net!`;
      case 'Pass Missed':
        return `${getShortenedPlayerName(player)} misses the pass!`;
      case 'Recovered Puck':
        if (event.time % 3 === 0) return `${getShortenedPlayerName(player)} gets the loose puck!`;
        if (event.time % 2 === 0) return `${getShortenedPlayerName(player)} beats ${getShortenedPlayerName(target)} to the puck!`;
        return `${getShortenedPlayerName(player)} recovers the puck!`;
      case 'Goalie Stoppage':
        return `${getShortenedPlayerName(player)} covers the puck!`;
      case 'Pokecheck':
        return `${getShortenedPlayerName(player)} gets their stick in the way!`;
      case 'Offside':
        return `${getShortenedPlayerName(player)} is offside!`;
      case 'Icing':
        return `${getShortenedPlayerName(player)} ices the puck!`;
      case 'Redirect':
        return `${getShortenedPlayerName(player)} redirects the puck on net!`;
      case 'Long Pass':
        return `${getShortenedPlayerName(player)} makes a stretch pass to ${getShortenedPlayerName(target)}!`;
      case 'Point Shot':
        return `${getShortenedPlayerName(player)} takes a shot from the point!`;
      case 'Board Battle':
        return `${getShortenedPlayerName(player)} battles ${getShortenedPlayerName(target)} along the boards!`;
      case 'Clean Hit':
        return `${getShortenedPlayerName(player)} lays out ${getShortenedPlayerName(target)}!`;
      default:
        console.log(event.type)
        return `${getShortenedPlayerName(player)} does ${event.type}!`;
    }
  }

  const getTeamColor = (id) => {
    const player = getPlayer(id);
    if (player.ownerId === home.ownerId) return home.color;
    if (isColorClose(away.color, home.color)) return getContrast(home.color);
    return away.color;
  }
  
  return (
    <Flex direction="column" w="100%" maw="650px" justify="center" mx="auto" my="0.5rem" p="1rem" 
      style={{
        backgroundColor: 'rgba(0,0,0,0.4)',
        borderLeft: '6px solid ' + getTeamColor(event.playerId),
        borderRadius: '0.25rem',        
      }}>
      <Text size="1.75rem" mb="0.5rem">{getEventText()}</Text>
      <Flex gap="1rem" align="center">
        <Badge size="xl" variant='filled' style={{ backgroundColor: getTeamColor(event.playerId), color: getContrast(getTeamColor(event.playerId)) }}>{event.type}</Badge>
        <Text tt="uppercase">{convertTime(getPeriodTime(event.time))}</Text>
        <div>|</div>
        <Text>{getGamePeriod(event.time)}</Text>
      </Flex>
      
    </Flex>
  )
}