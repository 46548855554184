import React from 'react'
import { Event } from './Event'

export const EventLog = ({ events, players, home, away }) => {
  if (!events) events = []
  return (
    <div style={{ marginTop: "3rem" }}>
      {events.filter(e => e.type !== 'Shot On Net' && e.type !== 'Redirect').map((event, index) => {
        return (index < 20) ? <Event key={index} event={event} players={players} home={home} away={away} /> : ''
      })}
    </div>
  )
}
