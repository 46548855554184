import React, { useEffect, useState } from 'react'
import { Flex, Pagination, Tabs, Text, Title } from '@mantine/core';
import { useNavigate, useParams } from 'react-router';

import connection from '../../feathers';
import { FreeAgentPlayer } from './FreeAgentPlayer';
import { convertPlayerObject, convertSinglePlayerObject } from '../../lib/convertPlayerObject';
import { getShortenedPlayerName } from '../../lib/getShortenedName';

import './FreeAgents.scss';

export const FreeAgents = () => {
  const { page } = useParams();
  const navigate = useNavigate();

  const [players, setPlayers] = useState([]);
  const [activePage, setActivePage] = useState(parseInt(page) || 1);
  const [total, setTotal] = useState(0);
  const [activeTab, setActiveTab] = useState('freeagents');
  const [userOffers, setUserOffers] = useState([]);
  
  const load = async () => {
    console.log('get free agent players')
    const res = await connection.service('players').find({
      query: {
        $limit: 50,
        $skip: 50 * (activePage - 1),
        ownerId: null,
        revealed: true,
        $sort: {
          overallValue: -1
        }
      }
    })
    console.log('players', res)
    
    setTotal(Math.ceil(res.total/50));
    setPlayers(convertPlayerObject(res.data));

    // get the user
    const user = await connection.get('authentication');
    console.log('user', user)

    // Get the offers for the user
    const offers = await connection.service('offers').find({
      query: {
        $limit: 50,
        ownerId: user.user._id,
        $sort: {
          createdAt: -1
        }
      }
    })
    console.log('offers', offers)
    // loop through the offers and add the playerid to a list
    // then get the players from the list
    let offeredPlayers = [];
    offers.data.forEach((offer) => {
      offeredPlayers.push(offer.playerId);
    })
    console.log('offeredPlayers', offeredPlayers)
    const offeredPlayersRes = await connection.service('players').find({
      query: {
        _id: {
          $in: offeredPlayers
        }
      }
    })
    console.log('offeredPlayersRes', offeredPlayersRes)
    
    // loop through the offers and add the player object to the offer
    offers.data.forEach((offer) => {
      offer.player = offeredPlayersRes.data.find((e) => e._id === offer.playerId);
    })

    setUserOffers(offers.data);
  }

  useEffect(() => {
    load();
  }, [])

  useEffect(() => {
    load();

    // add new page to url history in react-router-dom
    window.history.pushState({}, '', `/freeagents/${activePage}`);
  }, [activePage])

  useEffect(() => {
    console.log('page', page)
    setActivePage(parseInt(page) || 1);
  }, [page])

  const renderPlayers = () => {
    let results = [];

    players.forEach((player) => {
      results.push(
        <FreeAgentPlayer key={player._id} player={player} />
      )
    })

    return results;
  }

  const renderOffers = () => {
    let results = [];

    userOffers.forEach((offer) => {
      const player = convertSinglePlayerObject(offer.player);
      console.log('render offer player', player);
      results.push(
        <Flex gap="1rem" key={offer._id} p="0.5rem"  onClick={() => navigate('/player/' + player._id)}>
          <div style={{ width: '100px' }}>{getShortenedPlayerName(player)}</div>
          <div style={{ width: '50px' }}>{player.overallValue} OVR</div>
          <div style={{ width: '40px' }}>{player.type.abbr}</div>
          <div style={{ width: '40px' }}>{player.type.role.abbr} | {player.bio.handedness}</div>
          <div className=''>{offer.amount}M</div>
          {/*<div className=''>{offer.term}</div>*/}
          {/*<div className=''>{offer.bonus}</div>*/}
        </Flex>
      )
    })

    return results;
  }

  return (
    <div>
      <Title tt="uppercase">Free Agents</Title>
      <Text tt="uppercase" mb="1rem">Offers are resolved at midnight AST.</Text>
      
      <Tabs defaultValue={activeTab} onTabChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="freeagents">Free Agents</Tabs.Tab>
          <Tabs.Tab value="offers">Offers</Tabs.Tab>
        </Tabs.List>
      </Tabs>

      {(activeTab === 'offers') ? (
        renderOffers()
      ) : (
        <>
        <div className='FreeAgentsHeader'>
          <div className='FreeAgentsHeaderItem hideNarrow'></div>
          <div className='FreeAgentsHeaderItem'>Name</div>
          <div className='FreeAgentsHeaderItem centered'>Age</div>
          <div className='FreeAgentsHeaderItem centered'>Type</div>
          <div className='FreeAgentsHeaderItem centered hideNarrow'>POS</div>
          <div className='FreeAgentsHeaderItem highlighted centered'>OVR</div>
          <div className='FreeAgentsHeaderItem centered hideNarrow'>MNT</div>
          <div className='FreeAgentsHeaderItem centered hideNarrow'>ATH</div>
          <div className='FreeAgentsHeaderItem centered hideNarrow'>TEC</div>
          <div className='FreeAgentsHeaderItem centered hideNarrow'>SHT</div>
          <div className='FreeAgentsHeaderItem centered hideNarrow'>DEF</div>
          <div className='FreeAgentsHeaderItem centered hideNarrow'>RFX</div>
          <div className='FreeAgentsHeaderItem centered'>Asking</div>
          <div className='FreeAgentsHeaderItem centered'>Offers</div>
          <div className='FreeAgentsHeaderItem centered'>Waivers</div>
          <div className='FreeAgentsHeaderItem'></div>
        </div>
        {renderPlayers()}
        {(players.length <= 0) ? (
          <Flex justify="center" my="2rem">
            <Text italic c="dimmed">No Free Agents At The Moment</Text>
          </Flex>
        ) : ''}
        {(total > 1) ? (
          <Flex justify="center" align="center" mt="1rem" pb="1rem">
            <Pagination value={activePage} onChange={setActivePage} total={total} />
          </Flex>
        ) : ''}
        </>
      )}
    </div>
  )
}
