import { PlayerTypes } from "../data/types";

export const getSalary = (player) => {
  let minContract = 0.95
  let scaleFactor = 0.435;
  let salaryAvg = 10;
  
  let shotScaling = (Math.max(player.overall('shooting')-0.7, 0) / 0.3) * 3;
  salaryAvg += shotScaling;

  let tecScaling = (Math.max(player.overall('technique')-0.7, 0) / 0.3) * 1;
  salaryAvg += tecScaling;

  let faceoffScaling = (Math.max(player.technique.faceoff-0.7, 0) / 0.3) * 2;
  salaryAvg += faceoffScaling;

  let overall = (player.overall() - 0.5) / scaleFactor;
  let scaling = overall * overall * overall;
  if (player.type === PlayerTypes.Goaltender) {
    salaryAvg = 9.5;
    scaling = overall * overall;
  }
  if (player.overall > 0.7) scaling = scaling * 1.2;
  else scaling = scaling * 0.9;

  let adjusted = scaling * 0.4;
  adjusted = adjusted * (player.adjustedSalary - 0.5);

  let value = Math.max((scaling + adjusted) * salaryAvg, minContract);

  return value;
}

export const getUnadjustedSalary = (player) => {
  let minContract = 0.95
  let scaleFactor = 0.435;
  let salaryAvg = 10;
  
  let shotScaling = (Math.max(player.overall('shooting')-0.7, 0) / 0.3) * 3;
  salaryAvg += shotScaling;

  let tecScaling = (Math.max(player.overall('technique')-0.7, 0) / 0.3) * 1;
  salaryAvg += tecScaling;

  let faceoffScaling = (Math.max(player.technique.faceoff-0.7, 0) / 0.3) * 2;
  salaryAvg += faceoffScaling;

  let overall = (player.overall() - 0.5) / scaleFactor;
  let scaling = overall * overall * overall;
  if (player.type === PlayerTypes.Goaltender) {
    salaryAvg = 9.5;
    scaling = overall * overall;
  }
  if (player.overall > 0.7) scaling = scaling * 1.2;
  else scaling = scaling * 0.9;

  let value = Math.max(scaling * salaryAvg, minContract);

  return value;
}