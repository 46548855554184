import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import connection from '../../feathers';
import { Button, Flex, Modal, Tabs, Text, Title } from '@mantine/core';
import { convertSinglePlayerObject } from '../../lib/convertPlayerObject';
import { getSalary } from '../../lib/getSalary';

import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { PlayerTypes } from '../../data/types';

import './Roster.scss';
import { getShortenedPlayerName } from '../../lib/getShortenedName';

export const PlayerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [resignModal, { open: openResign, close: closeResign }] = useDisclosure(false);
  
  const [dismissModal, { open: openDismiss, close: closeDismiss }] = useDisclosure(false);
  const [hireModal, { open: openHire, close: closeHire }] = useDisclosure(false);

  const [dismissingPlayer, setDismissingPlayer] = useState(false);

  const [player, setPlayer] = useState(null);
  const [activeTab, setActiveTab] = useState('ratings');
  const [salary, setSalary] = useState('');
  const [stats, setStats] = useState(null);

  const [user, setUser] = useState(null);

  const load = async () => {
    let userRes = await connection.get('authentication');
    setUser(userRes.user);
    //console.log('loading player');
    const data = await connection.service('players').get(id);
    const temp = convertSinglePlayerObject(data);
    setPlayer(temp);
    //console.log(data);

    const value = getSalary(temp);
    if (value < 1) setSalary(getSalary(temp).toFixed(3) + 'M');
    else setSalary(getSalary(temp).toFixed(2) + 'M');

    // get player match stats
    const res = await connection.service('player-game-stats').find({
      query: {
        $limit: 50,
        playerId: id,
        $sort: {
          createdAt: -1
        }
      }
    })

    //console.log('stats', res);
    setStats(res.data);
  }

  useEffect(() => {
    load();
  }, [, activeTab])

  const renderRatings = (stat) => {
    let results = [];

    for (let substat in player[stat]) {
      if (substat === 'positioning' && player.type.role.abbr !== 'G') continue;
      if (player.type.role.abbr === 'G' && (
          substat === 'discipline' || 
          substat === 'faceoff' ||
          substat === 'passing' ||
          substat === 'strength' ||
          substat === 'endurance' ||
          substat === 'speed' ||
          substat === 'dangling'
      )) continue;
      results.push(
        <Flex key={substat} className='rating'>
          <div className='ratingLabel substat'>{substat}</div>
          <div className='ratingValue numbers'>{(player[stat][substat] * 100).toFixed(0)}</div>
        </Flex>
      );
    }

    return <div className='ratingResults'>{results}</div>;
  }

  const dateOption = { day: 'numeric', month: 'short' };

  const renderGameStats = () => {
    let results = [];

    if (player.type === PlayerTypes.Goaltender) {
      for (let stat in stats) {
        results.push(
          <div key={stats[stat]._id} style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(3, 1fr) 1.5fr', padding: '0.25rem 0' }}>
            <div style={{ textAlign: 'left' }}>{(stats[stat].createdAt) ? (new Date(stats[stat].createdAt)).toLocaleDateString('en-US', dateOption) : (new Date('May 3, 2023')).toLocaleDateString('en-US', dateOption)}</div>
            <div>{stats[stat].goalsAgainst || 0}</div>
            <div>{stats[stat].saves || 0}</div>
            <div>{(stats[stat].shotsAgainst > 0) ? (stats[stat].saves / stats[stat].shotsAgainst).toFixed(3) : '0.000'}</div>
            <div>{(stats[stat].timeOnIce) ? (Math.floor(stats[stat].timeOnIce / 60).toString().padStart(2, '0') + ':' + (stats[stat].timeOnIce % 60).toString().padStart(2, '0')) : 0}</div>
          </div>
        );
      }
      return (
      <div className='ratingResults'>
        <div style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(3, 1fr) 1.5fr' }}>
          <div style={{ textAlign: 'left' }}>Game</div>
          <div>GAA</div>
          <div>SV</div>
          <div>SV%</div>
          <div>TOI</div>
        </div>
        {results}
      </div>
      );

    }
    //if (player.type)

    for (let stat in stats) {
      results.push(
        <div key={stats[stat]._id} style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(9, 1fr) 1.5fr', padding: '0.25rem 0' }}>
          <div style={{ textAlign: 'left' }}>{(stats[stat].createdAt) ? (new Date(stats[stat].createdAt)).toLocaleDateString('en-US', dateOption) : (new Date('May 3, 2023')).toLocaleDateString('en-US', dateOption)}</div>
          <div>{stats[stat].goals || 0}</div>
          <div>{stats[stat].assists || 0}</div>
          <div>{stats[stat].shots || 0}</div>
          <div>{stats[stat].hits || 0}</div>
          <div>{stats[stat].blocks || 0}</div>
          <div>{stats[stat].takeaways || 0}</div>
          <div>{stats[stat].plusMinus || 0}</div>
          <div>{stats[stat].faceoffs || 0}</div>
          <div>{stats[stat].faceoffsWon || 0}</div>
          <div>{(stats[stat].timeOnIce) ? (Math.floor(stats[stat].timeOnIce / 60).toString().padStart(2, '0') + ':' + (stats[stat].timeOnIce % 60).toString().padStart(2, '0')) : 0}</div>
        </div>
      );
    }

    return (
    <div className='ratingResults'>
      <div style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(9, 1fr) 1.5fr' }}>
        <div style={{ textAlign: 'left' }}>Game</div>
        <div>G</div>
        <div>A</div>
        <div>SOG</div>
        <div>H</div>
        <div>B</div>
        <div>TA</div>
        <div>+/-</div>
        <div>FO</div>
        <div>FW</div>
        <div>TOI</div>
      </div>
      {results}
    </div>
    );
  }

  const resignPlayer = async () => {
    // If players gamesplayed >= contract length
    let currentPlayer = await connection.service('players').get(player._id);
    currentPlayer = convertSinglePlayerObject(currentPlayer);
    //console.log(currentPlayer)
    if (!(currentPlayer.gamesPlayed >= currentPlayer.contractLength)) {
      notifications.show({
        title: 'Cannot Resign Player',
        message: 'Player must play out their contract before resigning',
        color: 'red'
      })
      return;
    }

    // if players balance is enough to cover the contract
    // subtract the player salary from the players balance
    // get the user
    const currentUser = await connection.service('users').get(user._id);
    if (!(currentUser.balance >= getSalary(player))) {
      notifications.show({
        title: 'Cannot Resign Player',
        message: 'You do not have enough money to resign this player',
        color: 'red'
      })
      return;
    }
    
    //console.log('adjusting balance', getSalary(currentPlayer) * 1.35)
    connection.service('users').patch(currentUser._id, {
      balance: currentUser.balance - getSalary(currentPlayer),
    });

    const newBio = {
      ...currentPlayer.bio,
      age: currentPlayer.bio.age + 1,
    }

    let res = await connection.service('players').patch(currentPlayer._id, {
      gamesPlayed: 0,
      bio: newBio,
    });

    // if the player is in the last year of their career notify the user
    // have it sound like it is coming from the players agent
    if (currentPlayer.bio.age > currentPlayer.longevity - 2)
      await connection.service('notifications').create({
        userId: currentUser._id,
        message: `${getShortenedPlayerName(currentPlayer)}'s agent has informed us that they are considering retirement after this season.`,
      });

    //console.log(res)
    load();
    closeResign();
  }

  const createRetiredPlayer = async () => {
    const res = await connection.service('player-game-stats').find({
      query: {
        $limit: 1000,
        playerId: id,
        $sort: {
          createdAt: -1
        }
      }
    })
    let careerStats = {
      gamesPlayed: 0,
      goals: 0,
      assists: 0,
      shots: 0,
      hits: 0,
      blocks: 0,
      takeaways: 0,
      plusMinus: 0,
      faceoffs: 0,
      faceoffWins: 0,
      saves: 0,
      goalsAgainst: 0,
      shotsAgainst: 0,
      shutouts: 0,
      wins: 0,
      draws: 0,
      timeOnIce: 0,
    }
    
    res.data.forEach(game => {
      careerStats.gamesPlayed++;
      careerStats.goals += game.goals || 0;
      careerStats.assists += game.assists || 0;
      careerStats.shots += game.shots || 0;
      careerStats.hits += game.hits || 0;
      careerStats.blocks += game.blocks || 0;
      careerStats.takeaways += game.takeaways || 0;
      careerStats.plusMinus += game.plusMinus || 0;
      careerStats.faceoffs += game.faceoffs || 0;
      careerStats.faceoffWins += game.faceoffsWon || 0;
      careerStats.saves += game.saves || 0;
      careerStats.goalsAgainst += game.goalsAgainst || 0;
      careerStats.shotsAgainst += game.shotsAgainst || 0;
      careerStats.shutouts += game.shutouts || 0;
      careerStats.wins += game.wins || 0;
      careerStats.draws += game.draws || 0;
      careerStats.timeOnIce += game.timeOnIce || 0;
    })

    const retiredPlayer = {
      ownerId: player.ownerId,
      bio: player.bio,
      careerStats: careerStats,
    }

    // Legends row add? 
    
    await connection.service('retired-players').create(retiredPlayer);
  }

  const dismiss = async () => {
    // Create a retiredPlayer db entry that totals the players stats
    setDismissingPlayer(true);

    //notifications loading thing
    
    
    await createRetiredPlayer();

    // Remove the player from the db
    const res = await connection.service('players').remove(id);
    notifications.show({
      title: 'Player Dismissed',
      message: `${res.bio.forename[0] + '.' + res.bio.surname} has been dismissed from the team.`,
    })

    setDismissingPlayer(false);

    navigate('/roster');
    closeDismiss();
  }

  const hire = async () => {
    const currentUser = await connection.service('users').get(user._id);
    if (!(currentUser.balance >= getSalary(player) / 2)) {
      notifications.show({
        title: 'Cannot Hire Player',
        message: 'You do not have enough money to hire this player',
        color: 'red'
      })
      return;
    }

    await createRetiredPlayer();

    //console.log('adjusting balance', getSalary(player) * 1.35)
    /*connection.service('users').patch(user._id, {
      balance: user.balance - getSalary(player),
    });

    console.log('hiring player', player)
    // create a front office player with a game knowledge of their rating
    // Should I add something with their stats?
    const newStaff = {
      bio: player.bio,
      gameKnowledge: Math.round(player.overall * 100),
      salary: getPlayerValue() / 2,
    }
    connection.service('staff').create(newStaff);*/

    closeHire();
  }

  const getPlayerValue = () => {
    if (getSalary(player) < 1) return (Math.ceil(getSalary(player) * 1000) / 1000)
    return (Math.ceil(getSalary(player) * 100) / 100)
  }

  const renderTotals = () => {
    
    if (player.type === PlayerTypes.Goaltender) {
      let totalStats = {
        gamesPlayed: 0,
        wins: 0,
        losses: 0,
        draws: 0,
        goalsAgainst: 0,
        shotsAgainst: 0,
        saves: 0,
        shutouts: 0,
        timeOnIce: 0,
      }

      for (let stat in stats) {
        console.log(stats[stat])
        totalStats.gamesPlayed += (stats[stat].timeOnIce > 0) ? 1 : 0;
        totalStats.wins += (stats[stat].draws <= 0) ? stats[stat].wins : 0;
        totalStats.losses += stats[stat].losses;
        totalStats.draws += stats[stat].draws;
        totalStats.goalsAgainst += stats[stat].goalsAgainst || 0;
        totalStats.shotsAgainst += stats[stat].shotsAgainst || 0;
        totalStats.saves += stats[stat].saves || 0;
        totalStats.shutouts += stats[stat].shutouts || 0;
        totalStats.timeOnIce += stats[stat].timeOnIce || 0;
      }


      // goalies
      return (
        <div style={{ margin: '2rem 0'}}>
          <div style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(7, 1fr) 1.5fr' }}>
            <div></div>
            <div>RECORD</div>
            <div>GP</div>
            <div>GA</div>
            <div>GAA</div>
            <div>SA</div>
            <div>SV</div>
            <div>SV%</div>
            <div>TOI</div>
          </div>
          <div key={totalStats._id} style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(7, 1fr) 1.5fr', padding: '0.25rem 0' }}>
            <div style={{ textAlign: 'left' }}>Totals</div>
            <div>{(totalStats.wins || 0) + '-' + (totalStats.losses || 0) + '-' + (totalStats.draws || 0)}</div>
            <div>{totalStats.gamesPlayed || 0}</div>
            <div>{isNaN(totalStats.goalsAgainst) ? 0 : totalStats.goalsAgainst.toFixed(0)}</div>
            <div>{(totalStats.gamesPlayed > 0) ? (totalStats.goalsAgainst / totalStats.gamesPlayed).toFixed(2) : 0}</div>
            <div>{totalStats.shotsAgainst || 0}</div>
            <div>{totalStats.saves || 0}</div>
            <div>{(totalStats.shotsAgainst > 0) ? (totalStats.saves / totalStats.shotsAgainst).toFixed(3) : '0.000'}</div>
            <div>{(totalStats.timeOnIce) ? (Math.floor(totalStats.timeOnIce / 60).toString().padStart(2, '0') + ':' + (totalStats.timeOnIce % 60).toString().padStart(2, '0')) : 0}</div>
          </div>
        </div>
      );
    }
    
    let totalStats = {
      gamesPlayed: 0,
      goals: 0,
      assists: 0,
      shots: 0,
      hits: 0,
      blocks: 0,
      takeaways: 0,
      plusMinus: 0,
      faceoffs: 0,
      faceoffsWon: 0,
      timeOnIce: 0,
    }
    
    for (let stat in stats) {
      totalStats.gamesPlayed++;
      totalStats.goals += stats[stat].goals || 0;
      totalStats.assists += stats[stat].assists || 0;
      totalStats.shots += stats[stat].shots || 0;
      totalStats.hits += stats[stat].hits || 0;
      totalStats.blocks += stats[stat].blocks || 0;
      totalStats.takeaways += stats[stat].takeaways || 0;
      totalStats.plusMinus += stats[stat].plusMinus || 0;
      totalStats.faceoffs += stats[stat].faceoffs || 0;
      totalStats.faceoffsWon += stats[stat].faceoffsWon || 0;
      totalStats.timeOnIce += stats[stat].timeOnIce || 0;
    }
    return (
      <div style={{ margin: '2rem 0'}}>
        <div style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(11, 1fr) 1.5fr' }}>
          <div></div>
          <div>GP</div>
          <div>G</div>
          <div>A</div>
          <div>SOG</div>
          <div>H</div>
          <div>B</div>
          <div>TA</div>
          <div>+/-</div>
          <div>FW</div>
          <div>FO</div>
          <div>FO%</div>
          <div>TOI</div>
        </div>
        <div key={totalStats._id} style={{ textAlign: 'center', display: 'grid', gridTemplateColumns: '1.5fr repeat(11, 1fr) 1.5fr', padding: '0.25rem 0' }}>
          <div style={{ textAlign: 'left' }}>Totals</div>
          <div>{totalStats.gamesPlayed || 0}</div>
          <div>{totalStats.goals || 0}</div>
          <div>{totalStats.assists || 0}</div>
          <div>{totalStats.shots || 0}</div>
          <div>{totalStats.hits || 0}</div>
          <div>{totalStats.blocks || 0}</div>
          <div>{totalStats.takeaways || 0}</div>
          <div>{totalStats.plusMinus || 0}</div>
          <div>{totalStats.faceoffsWon || 0}</div>
          <div>{totalStats.faceoffs || 0}</div>
          <div>{(totalStats.faceoffsWon / totalStats.faceoffs * 100).toFixed(1) || 0}%</div>
          <div>{(totalStats.timeOnIce) ? (Math.floor(totalStats.timeOnIce / 60).toString().padStart(2, '0') + ':' + (totalStats.timeOnIce % 60).toString().padStart(2, '0')) : 0}</div>
        </div>
      </div>
    );

  }

  if (!player) return (<div>Loading...</div>)

  return (
    <div>
      <Modal opened={dismissModal} onClick={e => e.stopPropagation()} onClose={closeDismiss} title='Dismiss Player' centered>
        <Flex direction="column" gap="1rem" align="center" mb="0.5rem">
          <Title>{player.bio.getName()}</Title>
          <Text size="1.5rem" ta="center" my="1rem" w="100%">Dismiss this player?</Text>
          <Flex gap="1rem">
            <Button size="lg" color="red" variant='outline' onClick={(e) => { e.stopPropagation(); closeDismiss(); }}>Cancel</Button>
            <Button size="lg" onClick={dismiss}>Dismiss</Button>
          </Flex>
        </Flex>
      </Modal>
      <Modal opened={hireModal} onClick={(e) => e.stopPropagation()} onClose={closeHire} title="Hire Player" centered>
        <Flex direction="column" gap="1rem" align="center" mb="0.5rem">
          <Title>{player.bio.getName()} <span className='highlighted'>[{(player.overall() * 100).toFixed(0)}]</span></Title>
          <Text size="1.5rem" ta="center" my="1rem" w="100%">Hire player for your front office?  <span className='highlighted'>{(getPlayerValue()/2).toFixed(2)}M</span></Text>
          <Flex gap="1rem">
            <Button size="lg" color="red" variant='outline' onClick={(e) => { e.stopPropagation(); closeHire(); }}>Cancel</Button>
            <Button size="lg" onClick={hire}>Hire</Button>
          </Flex>
        </Flex>
      </Modal>
      <Modal opened={resignModal} onClick={(e) => e.stopPropagation()} onClose={closeResign} title="Resign Player" centered>
        <Flex direction="column" gap="1rem" align="center" mb="0.5rem">
          <Title>{player.bio.getName()} <span className='highlighted'>[{(player.overall() * 100).toFixed(0)}]</span></Title>
          <Text size="1.5rem" ta="center" my="1rem" w="100%">Resign player for <span className='highlighted'>{getPlayerValue().toFixed(2)}M</span></Text>
          <Flex gap="1rem">
            <Button size="lg" color="red" variant='outline' onClick={(e) => { e.stopPropagation(); closeResign(); }}>Cancel</Button>
            <Button size="lg" onClick={resignPlayer}>Re-sign</Button>
          </Flex>
        </Flex>
      </Modal>

      <div className='bioTopOverview'>
        <Flex justify="space-between" align="center" mx="1.25rem">
          <Flex direction="column">
            <Text size="1.5rem">{player.bio.forename}</Text>
            <Text mt="-1.25rem" size="3rem">{player.bio.surname}</Text>
          </Flex>
          <Flex className='bioFlag' direction="column" justify="center" align="center">
            <img style={{ width: '80%' }} src={player.bio.nationality.flag} alt={player.bio.getName()} />
            <Text className='bioNationality'>{player.bio.nationality.name}</Text>
          </Flex>
        </Flex>
        <Flex className='bioStats'>
          <div>
            <Text mb="0.25rem">HEIGHT</Text>
            <Text className='numbers' size="2.5rem">{player.bio.height}cm</Text>
          </div>
          <div className='verticalBar'></div>
          <div>
            <Text mb="0.25rem">WEIGHT</Text>
            <Text className='numbers' size="2.5rem">{player.bio.weight}kg</Text>
          </div>
          <div className='verticalBar'></div>
          <div>
            <Text mb="0.25rem">AGE</Text>
            <Text className='numbers' size="2.5rem">{player.bio.age}</Text>
          </div>
        </Flex>
      </div>
      <div>
        <Tabs defaultValue={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="ratings">Ratings</Tabs.Tab>
            <Tabs.Tab value="contract" disabled>Contract</Tabs.Tab>
            <Tabs.Tab value="gamelog">Game Log</Tabs.Tab>
          </Tabs.List>
        </Tabs>
        {(activeTab === 'ratings') ? (
          <div className='bioWrapper bioRatings'>
            <div className='ratingDetailWrapper'>
              <Flex className='ratingDetails' align="center">
                <Title className='playerRole' size="1.5rem">{player.type.role.abbr}</Title>
                <div className='playerTypeSeperator'></div>
                <Title className='playerType' size="1.5rem" mr="1rem">{player.type.name}</Title>
              </Flex>
              <Flex className='bioStats'>
                <Flex direction="column" align="center">
                  <Text>OVERALL</Text>
                  <Title size="1.5rem">{(player.overall() * 100).toFixed(0)}</Title>
                </Flex>
                <div className='verticalBar'></div>
                <Flex direction="column" align="center">
                  <Text>HANDED</Text>
                  <Title size="1.5rem">{player.bio.handedness}</Title>
                </Flex>
                <div className='verticalBar'></div>
                <Flex direction="column" align="center">
                  <Text>SALARY</Text>
                  <Title size="1.5rem">{salary}</Title>
                </Flex>
              </Flex>
            </div>
            <Flex className='ratingDetails' wrap="wrap" gap="2rem">
              <div className='ratingGroup'>
                <Flex className='ratingHeader' justify="space-between">
                  <Title size="1.25rem">Mental</Title>
                  <Text className='numbers' size="1.75rem" mb="-0.25rem">
                    {(player.overall('mental') * 100).toFixed(0)}
                  </Text>
                </Flex>
                {renderRatings('mental')}
              </div>
              <div className='ratingGroup'>
                <Flex className='ratingHeader' justify="space-between">
                  <Title size="1.25rem">Athletics</Title>
                  <Text className='numbers' size="1.75rem" mb="-0.25rem">
                    {(player.overall('athletic') * 100).toFixed(0)}
                  </Text>
                </Flex>
                {renderRatings('athletic')}
              </div>
              <div className='ratingGroup'>
                <Flex className='ratingHeader' justify="space-between">
                  <Title size="1.25rem">Technique</Title>
                  <Text className='numbers' size="1.75rem" mb="-0.25rem">
                    {(player.overall('technique') * 100).toFixed(0)}
                  </Text>
                </Flex>
                {renderRatings('technique')}
              </div>
              {(player.type.role.abbr !== 'G') ? (<>
              <div className='ratingGroup'>
                <Flex className='ratingHeader' justify="space-between">
                  <Title size="1.25rem">Shooting</Title>
                  <Text className='numbers' size="1.75rem" mb="-0.25rem">
                    {(player.overall('shooting') * 100).toFixed(0)}
                  </Text>
                </Flex>
                {renderRatings('shooting')}
              </div>
              <div className='ratingGroup'>
                <Flex className='ratingHeader' justify="space-between">
                  <Title size="1.25rem">Defense</Title>
                  <Text className='numbers' size="1.75rem" mb="-0.25rem">
                    {(player.overall('defense') * 100).toFixed(0)}
                  </Text>
                </Flex>
                {renderRatings('defense')}
              </div>
              </>) : ''}
              {(player.type.role.abbr === 'G') ? (
              <div className='ratingGroup'>
                <Flex className='ratingHeader' justify="space-between">
                  <Title size="1.25rem">Reflexes</Title>
                  <Text className='numbers' size="1.75rem" mb="-0.25rem">
                    {(player.overall('reflexes') * 100).toFixed(0)}
                  </Text>
                </Flex>
                {renderRatings('reflexes')}
              </div>
              ) : 
              <div className='ratingGroup'>
                <Flex className='ratingHeader' justify="space-between">
                  <Title size="1.25rem">Scouting Report</Title>
                </Flex>
                <Flex className='rating'>
                  <div className='ratingLabel substat'>Personality</div>
                  <div className='ratingValue numbers'>{player.personality}</div>
                </Flex>
                <Flex className='rating'>
                  <div className='ratingLabel substat'>Potential</div>
                  <div className='ratingValue numbers'>{(player.potential > 0.9) ? 'Franchise' : (player.potential > 0.75) ? 'Elite' : (player.potential > 0.5) ? ('Top ' + ((player.type.role.abbr === 'F') ? '6 F' : '4 D')) : ('Bottom ' + ((player.type.role.abbr === 'F') ? '6' : 'Pair')) }</div>
                </Flex>
                <Flex className='rating'>
                  <div className='ratingLabel substat'>Adaptability</div>
                  <div className='ratingValue numbers'>{(player.adaptability > 0.8) ? 'Great' : (player.adaptability > 0.6) ? 'Good' : (player.adaptability > 0.4) ? 'Passable' : (player.adaptability > 0.2) ? 'Bad' : 'Terrible' }</div>
                </Flex>
              </div>}
            </Flex>
          </div>
        ) : ''}
        {/*(activeTab === 'contract') ? (
          <div className='bioWrapper bioContract'>
            <Title size="1.5rem" tt="uppercase">Contract Details</Title>
            {(player.longevity <= player.bio.age) ? (
              <>
                <Text my="1rem" size="1.5rem" tt="uppercase" className='highlighted'>RETIRED</Text>
                <Button tt="uppercase" size="lg" mr="1rem" onClick={openDismiss}>Dismiss</Button>
                <Button tt="uppercase" size="lg" disabled onClick={openHire}>Hire as Front Office</Button>
              </>
            ) : (<>
              <Text my="1rem" size="1.5rem" tt="uppercase">{player.gamesPlayed}/{player.contractLength} Games Played</Text>
              <Button tt="uppercase" size="lg" disabled={player.gamesPlayed < player.contractLength} onClick={openResign}>{(player.contractLength - player.gamesPlayed > 0) ? (player.contractLength - player.gamesPlayed) + ' GAMES REMAINING' : 'Re-sign'}</Button>
            </>)}
          </div>
            ) : ''*/}
        {(activeTab === 'gamelog') ? (
          <div className='bioWrapper bioGameLog' style={{ width: "100%", maxWidth: "650px", margin: "0 auto"}}>
            <Title size="1.5rem" fw="400">LAST 50 GAMES</Title>
            {renderTotals()}
            {renderGameStats()}
          </div>
        ) : ''}
      </div>
    </div>
  )
}
