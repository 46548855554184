import { Player } from "./Player.js";

export default class Team {
  constructor(id, name, forwardLines, defenseLines, goaltender, backup, coaches, strategy) {
    this.id = id;
    this.name = name;
    this.forwardLines = this.convertForwardLines(forwardLines, id);
    this.defenseLines = this.convertDefensiveLines(defenseLines, id);
    this.goaltender = new Player(goaltender, this, 'goaltender');
    this.backup = backup;
    this.coaches = coaches;
    this.strategy = strategy;

    this.targetLine = {
      forward: 0,
      defense: 0,
    }
    this.lastLineChange = {
      forward: 0,
      defense: 0,
    }
    this.active = {
      center: this.forwardLines[0].center,
      leftWing: this.forwardLines[0].leftWing,
      rightWing: this.forwardLines[0].rightWing,
      leftDefense: this.defenseLines[0].leftDefense,
      rightDefense: this.defenseLines[0].rightDefense,
      goaltender: this.goaltender,
    }
  }

  resolve(state) {
    return state;
  }

  evaluate(state) {
    // Figure out the target lines that should be playing based on fatigue
    // line match?
    return state;
  }

  changePlayer(state, position) {
    // swap out the active player with the player on the target line
    return state;
  }

  reset(state) {
    // reset the active players to the first line
    // and set the target lines to the first line
    this.targetLine = {
      forward: 0,
      defense: 0,
    }
    this.lastLineChange = {
      forward: 0,
      defense: 0,
    }
    this.active = {
      center: this.forwardLines[0].center,
      leftWing: this.forwardLines[0].leftWing,
      rightWing: this.forwardLines[0].rightWing,
      leftDefense: this.defenseLines[0].leftDefense,
      rightDefense: this.defenseLines[0].rightDefense
    }

    // reset the fatigue of all the players

    // set the state to the new state
    if (state.home.id === this.id) state.home = this;
    else state.away = this;

    return state;
  }

  convertForwardLines(lines) {
    let results = [];
  
    lines.forEach((line) => {
      let newLine = {
        center: new Player(line.center, this, 'center'),
        leftWing: new Player(line.leftWing, this, 'leftWing'),
        rightWing: new Player(line.rightWing, this, 'rightWing'),
      };
      
      results.push(newLine);
    })
  
    return results;
  }
  
  convertDefensiveLines(lines) {
    let results = [];
  
    lines.forEach((line) => {
      let newLine = {
        leftDefense: new Player(line.leftDefense, this, 'leftDefense'),
        rightDefense: new Player(line.rightDefense, this, 'rightDefense'),
      };
  
      results.push(newLine);
    })
  
    return results;
  }
}

