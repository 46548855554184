import { Button, Flex, Indicator, Slider, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import connection from '../../feathers';
import { notifications } from '@mantine/notifications';

export const PackProgress = () => {
  
  const [progress, setProgress] = useState(0);
  const [user, setUser] = useState(null)

  const load = async () => {
    connection.service('users').on('patched', (u) => {
      if (user._id === u._id && progress !== u.gameStreak)
        setProgress(u.gameStreak);
    })

    let user = await connection.get('authentication');
    user = await connection.service('users').get(user.user._id)
    setUser(user);

    setProgress(user.gameStreak);
  }

  useEffect(() => {
    load();
  }, [])

  const getCurrentPack = () => {
    //if (progress >= 20) return 'CLAIM SSS+ PACK'
    if (progress >= 8) return 'CLAIM SSS PACK'
    if (progress >= 5) return 'CLAIM SS PACK'
    if (progress >= 3) return 'CLAIM S PACK'
    if (progress >= 1) return 'CLAIM B PACK'
    return 'CLAIM C PACK'
  }
  
  const claim = async () => {
    // get the live gameStreak
    const currentUser = await connection.service('users').get(user._id)

    let packType = 'C'
    if (currentUser.gameStreak >= 8) packType = 'SSS';
    else if (currentUser.gameStreak >= 5) packType = 'SS';
    else if (currentUser.gameStreak >= 3) packType = 'S';
    else if (currentUser.gameStreak >= 1) packType = 'B';

    const res = await connection.service('claim').claim({ type: packType })

    if (res.success) {
      setProgress(0);
      
      notifications.show({
        title: 'Pack Claimed',
        message: res.message,
        color: 'blue',
      })
    } else {
      notifications.show({
        title: 'Pack Claim Failed',
        message: res.message,
        color: 'red',
      })
    }
  }

  const claimRookiePack = async () => {
    await connection.service('packs').create([{
      type: 'R',
      ownerId: user._id,
    }]);

    notifications.show({
      title: 'Rookie Pack Acquired',
      message: 'You have acquired a Rookie Pack. Open it in the Packs tab.',
      color: 'blue',
    })
  }

  return (
    <Flex direction="column" align="center" maw="650px" m="0 auto" p="2rem 0">
      <Text tt="uppercase" size="1.5rem">Pack Progress</Text>
      <Text ta="center">Play games to earn progress towards packs, only your first 5 games count towards your pack progression each day.</Text>
      <Text tt='uppercase' mt="1rem">Progress: {progress}</Text>
      <Slider style={{ cursor: 'default' }} w="100%"
        value={progress} min={0} max={8} step={1}
        size="1rem" radius="xl" disabled my="1rem"
        marks={[
          { value: 1, label: 'B' },
          { value: 3, label: 'S' },
          { value: 5, label: 'SS' },
          { value: 8, label: 'SSS' },
        ]} />
      <Button onClick={claim} mt="1rem" size="xl" fw="400" disabled={progress < 1}>{getCurrentPack()}</Button>
      <Indicator inline label="FREE" size={30} mt="1rem" radius="md" position="bottom-end">
        <Button onClick={claimRookiePack} size="xl" fw="400" tt="uppercase">Acquire Rookie Pack</Button>
      </Indicator>
    </Flex>
  )
}
