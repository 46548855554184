import { CORNER_RADIUS, RINK_HEIGHT, RINK_WIDTH } from "./simulation/config";

export const getDistance = (player1, player2) => {
  const dx = player1.x - player2.x;
  const dy = player1.y - player2.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export const getDistanceToLine = (lineA, lineB, position) => {
  // This function calculates the shortest distance between a point (p3) and a line segment defined by two points (p1 and p2).
  const xDelta = lineB.x - lineA.x;
  const yDelta = lineB.y - lineA.y;

  if ((xDelta === 0) && (yDelta === 0)) {
    return getDistance(lineA, position);
  }

  const u = ((position.x - lineA.x) * xDelta + (position.y - lineA.y) * yDelta) / (xDelta * xDelta + yDelta * yDelta);

  const closestPoint = { x: 0, y: 0 };
  if (u < 0) {
    closestPoint.x = lineA.x;
    closestPoint.y = lineA.y;
  } else if (u > 1) {
    closestPoint.x = lineB.x;
    closestPoint.y = lineB.y;
  } else {
    closestPoint.x = lineA.x + u * xDelta;
    closestPoint.y = lineA.y + u * yDelta;
  }

  return getDistance(position, closestPoint);
}

export const getClosestPointAlongLine = (lineA, lineB, point) => {
  const AP = {x: point.x - lineA.x, y: point.y - lineA.y}; // Vector from A to P
  const AB = {x: lineB.x - lineA.x, y: lineB.y - lineA.y}; // Vector from A to B

  // Compute the magnitude of AB vector
  const ABMag = AB.x * AB.x + AB.y * AB.y;

  // Compute the dot product of AP and AB vectors
  const APAB = AP.x * AB.x + AP.y * AB.y;

  // Compute the relative position of the projection of point P along line AB
  let t = APAB / ABMag;

  // If t is within the range [0,1] then the point lies on the line segment AB
  // Otherwise, return the closer endpoint
  if (t < 0) {
    t = 0;
  } else if (t > 1) {
    t = 1;
  }

  // Calculate the projection point
  const projection = {x: lineA.x + AB.x * t, y: lineA.y + AB.y * t};

  return projection;
}

export const normalizeVector = (vector) => {
  const magnitude = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  
  if (magnitude === 0) {
    console.warn('Warning: Attempted to normalize a vector with magnitude 0');
    return {
      x: 0,
      y: 0
    };
  }

  return {
    x: vector.x / magnitude,
    y: vector.y / magnitude
  };
}

export const getMagnitude = (vector) => {
  return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
}

export const isInBounds = (position) => {
  if (position.x < 0 || position.x > RINK_WIDTH || position.y < 0 || position.y > RINK_HEIGHT) {
    return false;
  }

  // check corners
  const cornerArcs = [
    {
      x: CORNER_RADIUS,
      y: CORNER_RADIUS,
      startAngle: 3 * Math.PI / 2,
      endAngle: 2 * Math.PI
    },
    {
      x: CORNER_RADIUS,
      y: (RINK_HEIGHT - CORNER_RADIUS),
      startAngle: 0,
      endAngle: Math.PI / 2
    },
    {
      x: (RINK_WIDTH - CORNER_RADIUS),
      y: (RINK_HEIGHT - CORNER_RADIUS),
      startAngle: Math.PI / 2,
      endAngle: Math.PI
    },
    {
      x: (RINK_WIDTH - CORNER_RADIUS),
      y: CORNER_RADIUS,
      startAngle: Math.PI,
      endAngle: 3 * Math.PI / 2
    }
  ]

  let closestArc = {
    index: -1,
    distance: 100000
  }
  for (let i = 0; i < cornerArcs.length; i++) {
    const arc = cornerArcs[i];
    const distance = getDistance(position, arc);

    if (distance < closestArc.distance) {
      closestArc.index = i;
      closestArc.distance = distance;
    }
  }

  if (closestArc.index !== -1 && 
    closestArc.distance > CORNER_RADIUS &&
    ((position.x < CORNER_RADIUS && position.y < CORNER_RADIUS) ||
    (position.x < CORNER_RADIUS && position.y > RINK_HEIGHT - CORNER_RADIUS) ||
    (position.x > RINK_WIDTH - CORNER_RADIUS && position.y > RINK_HEIGHT - CORNER_RADIUS) ||
    (position.x > RINK_WIDTH - CORNER_RADIUS && position.y < CORNER_RADIUS))) {
    // In a corner past the corner arc
    return false;
  }

  return true;
}

const getArcIntersectionPoint = (line, arc) => {
  const dx = line.b.x - line.a.x;
  const dy = line.b.y - line.a.y;

  const A = dx * dx + dy * dy;
  const B = 2 * (dx * (line.a.x - arc.x) + dy * (line.a.y - arc.y));
  const C = (line.a.x - arc.x) * (line.a.x - arc.x) + (line.a.y - arc.y) * (line.a.y - arc.y) - arc.radius * arc.radius;
  const discriminant = B * B - 4 * A * C;
  
  let points = [];

  if (discriminant === 0) {
    // One real solution
    const t = -B / (2 * A);
    const x = line.a.x + t * dx;
    const y = line.a.y + t * dy;
    points.push({x, y});
  } else if (discriminant > 0) {
    // Two real solutions
    const t1 = (-B + Math.sqrt(discriminant)) / (2 * A);
    const x1 = line.a.x + t1 * dx;
    const y1 = line.a.y + t1 * dy;
    points.push({x: x1, y: y1});

    const t2 = (-B - Math.sqrt(discriminant)) / (2 * A);
    const x2 = line.a.x + t2 * dx;
    const y2 = line.a.y + t2 * dy;
    points.push({x: x2, y: y2});
  }

  let point = null
  points.forEach((p) => {
    // make sure the angle is within the arc
    if ((arc.quadrant === 1 && p.x < arc.x && p.y < arc.y) ||
      (arc.quadrant === 2 && p.x > arc.x && p.y < arc.y) ||
      (arc.quadrant === 3 && p.x > arc.x && p.y > arc.y) ||
      (arc.quadrant === 4 && p.x < arc.x && p.y > arc.y))
        point = {...p, arc};
  })

  return point;
}

const getAssociatedArc = (position) => {
  let corner = {
    x: CORNER_RADIUS,
    y: CORNER_RADIUS,
    radius: CORNER_RADIUS,
    quadrant: 1
  }

  if (position.x < RINK_WIDTH / 2 && position.y >= RINK_HEIGHT - CORNER_RADIUS) {
    corner = {
      x: CORNER_RADIUS,
      y: RINK_HEIGHT - CORNER_RADIUS,
      radius: CORNER_RADIUS,
      quadrant: 4
    }
  } else if (position.x > RINK_WIDTH / 2) {
    if (position.y < CORNER_RADIUS) {
      corner = {
        x: RINK_WIDTH - CORNER_RADIUS,
        y: CORNER_RADIUS,
        radius: CORNER_RADIUS,
        quadrant: 2
      }
    } else {
      corner = {
        x: RINK_WIDTH - CORNER_RADIUS,
        y: RINK_HEIGHT - CORNER_RADIUS,
        radius: CORNER_RADIUS,
        quadrant: 3
      }
    }
  }

  return corner;
}

const getAdjustedPositioning = (velocity, line, intersection) => {
  const distanceToIntersection = getDistance(line.a, intersection);
  const velocityDistance = getDistance(line.a, line.b);

  const remainingForce = (velocityDistance - distanceToIntersection) / velocityDistance;

  // get the normal vector of the intersection point to the center of the arc
  const normalVector = normalizeVector({
    x: intersection.arc.x - intersection.x,
    y: intersection.arc.y - intersection.y
  });

  // reflect the puck velocity across the normal vector
  // Get the dot product of the puck velocity and the normal vector
  const dot = velocity.x * normalVector.x + velocity.y * normalVector.y;
  const C = 2 * dot;
  const reflectionVelocty = {
    x: velocity.x - C * normalVector.x,
    y: velocity.y - C * normalVector.y
  }

  // set the puck position to continued along the line based on newVelocity and remainingForce
  const boardFriction = 0.75; // adjust this later based on the angle of the reflection
  return {
    velocity: {
      x: reflectionVelocty.x * boardFriction,
      y: reflectionVelocty.y * boardFriction
    },
    position: {
      x: intersection.x + reflectionVelocty.x * remainingForce,
      y: intersection.y + reflectionVelocty.y * remainingForce
    }
  }
}

export const calculatePuckBoardDeflections = (puck) => {
  // if the pucks position is in bounds and the position+velocity is in bounds, return\
  if (isInBounds(puck.position) && isInBounds({x: puck.position.x + puck.velocity.x, y: puck.position.y + puck.velocity.y}))
    return {
      position: structuredClone(puck.position),
      velocity: structuredClone(puck.velocity)
    }

  const line = {
    a: {
      x: puck.position.x,
      y: puck.position.y
    },
    b: {
      x: puck.position.x + puck.velocity.x,
      y: puck.position.y + puck.velocity.y
    },
  };
  const arc = getAssociatedArc(puck.position);
  const intersection = getArcIntersectionPoint(line, arc);

  if (intersection) {
    console.log('Intersection', intersection);
        
    // get the distance along the line to the intersection point and for the velocity
    let newPositioning = getAdjustedPositioning(puck.velocity, line, intersection);
    let lastPositioning = {
      position: puck.position,
      velocity: puck.velocity
    }

    // check if the new position is in bounds
    // if not, we need to bring the puck back along the new velocity until it is in bounds
    // then we need to repeat the reflection process until the puck is no longer out of bounds
    while (!isInBounds(newPositioning.position)) {
      // find the intersection point between the new position and the arc
      const newLine = {
        a: {
          x: lastPositioning.position.x,
          y: lastPositioning.position.y
        },
        b: {
          x: newPositioning.position.x,
          y: newPositioning.position.y
        }
      }
      const newIntersection = getArcIntersectionPoint({
        a: {
          x: lastPositioning.position.x,
          y: lastPositioning.position.y
        },
        b: {
          x: newPositioning.position.x,
          y: newPositioning.position.y
        }
      }, arc);

      if (newIntersection) {
        // we hit the arc again so we need to reflect the puck velocity again
        newPositioning = getAdjustedPositioning(newPositioning.velocity, newLine, newIntersection);
      } else {
        // must be regular boards now so set the position to the bounded position
        // and reflect the velocity
        newPositioning.velocity = {
          x: (newPositioning.position.x < 0 || newPositioning.position.x > RINK_WIDTH) ? newPositioning.velocity.x * -1 : newPositioning.velocity.x,
          y: (newPositioning.position.y < 0 || newPositioning.position.y > RINK_HEIGHT) ? newPositioning.velocity.y * -1 : newPositioning.velocity.y
        };
        newPositioning.position = getBoundedPosition(newPositioning.position);
        newPositioning.velocity = {
          x: 0,
          y: 0
        }
      }

      lastPositioning = structuredClone(newPositioning);
    }
    return {
      ...puck,
      position: newPositioning.position,
      velocity: newPositioning.velocity
    };
  }

  // otherwise reflect off boards
  let adjustments = {
    position: {...puck.position},
    velocity: {...puck.velocity}
  }
  // Check if puck is hitting the boards (top or bottom)
  if (adjustments.position.y < 0 || adjustments.position.y > RINK_HEIGHT) {
    // calculate the angle of deflection and the energy transfer
    // Set the position of the puck to be on the edge of the rink and adjust the velocity
    adjustments.position.y = Math.min(Math.max(adjustments.position.y, 0), RINK_HEIGHT);
    adjustments.velocity.y = -adjustments.velocity.y * 0.75;
  }

  // Check if puck is hitting the boards (left or right)
  if (adjustments.position.x < 0 || adjustments.position.x > RINK_WIDTH) {
    adjustments.position.x = Math.min(Math.max(adjustments.position.x, 0), RINK_WIDTH);
    adjustments.velocity.x = -adjustments.velocity.x * 0.75;
  }

  // TODO adjust the position based on velocity

  return adjustments;
}


export const getBoundedPosition = (position) => {
  //if (isInBounds(position)) return position;

  let adjustmented = {...position}

  // Check if puck is hitting the boards (top or bottom)
  if (adjustmented.y < 0 || adjustmented.y > RINK_HEIGHT) {
    // calculate the angle of deflection and the energy transfer
    // Set the position of the puck to be on the edge of the rink and adjust the velocity
    adjustmented.y = Math.min(Math.max(adjustmented.y, 0), RINK_HEIGHT);
  }

  // Check if puck is hitting the boards (left or right)
  if (adjustmented.x < 0 || adjustmented.x > RINK_WIDTH) {
    adjustmented.x = Math.min(Math.max(adjustmented.x, 0), RINK_WIDTH);
  }

  return {...adjustmented};
};
