import { getMagnitude, normalizeVector } from "../getDistance";
import { AWAY_BLUELINE, AWAY_NET, HOME_BLUELINE, HOME_NET, RINK_HEIGHT, RINK_WIDTH } from "../simulation/config";

export const getPosition = (state, player) => {
  const opponents = player.team.id === state.home.id ? state.away.active : state.home.active;

  switch (player.linePosition) {
    case 'center':
      return getCenterPosition(state, player, opponents);
    case 'leftWing':
    case 'rightWing':
      return getWingerPosition(state, player, opponents);
    case 'leftDefense':
    case 'rightDefense':
      return getDefensePosition(state, player, opponents);
    case 'goaltender':
      return getGoaltenderPosition(state, player, opponents);
    default:
      console.error('Invalid line position');
      return { x: RINK_WIDTH/2, y: RINK_HEIGHT/2 };
  }
}

const getCenterPosition = (state, player, opponents) => {

}

const getWingerPosition = (state, player, opponents) => {
  
}

const getDefensePosition = (state, player, opponents) => {

}

const getGoaltenderPosition = (state, player, opponents) => {
  // Above center line, get position in net
  let netPosition = structuredClone(AWAY_NET);
  let targetPosition = structuredClone(state.puck.position);
  /*if (state.puck.state === 'shot')
    targetPosition = {...state.puck.player.position};*/
  if (state.puck.state === 'pass')
    targetPosition = structuredClone(state.puck.player.selectedAction.target);
  if (targetPosition.x > netPosition.x) targetPosition.x = netPosition.x;
  
  if (player.team.id === state.home.id) {
    // Home starter
    netPosition = structuredClone(HOME_NET);
    if (targetPosition.x < netPosition.x) targetPosition.x = netPosition.x;
    if (targetPosition.x > HOME_BLUELINE) {
      netPosition.x += 2;
      return netPosition;
    }
  } else {
    if (targetPosition.x < AWAY_BLUELINE) {
      netPosition.x -= 2;
      return netPosition;
    }
  }

  // get the center of the triangle between the net and the puck
  const target = getCenterOfTriangle(
    { x: netPosition.x, y: netPosition.y + 3 },
    { x: netPosition.x, y: netPosition.y - 3 },
    targetPosition);
  
  return target
}

const getClosestPlayer = (players, position) => {
  let closestPlayer = null;
  let closestDistance = Infinity;
  for (const player of players) {
    const distance = Math.sqrt(
      (player.position.x - position.x) * (player.position.x - position.x) +
      (player.position.y - position.y) * (player.position.y - position.y)
    );
    if (distance < closestDistance) {
      closestPlayer = player;
      closestDistance = distance;
    }
  }
  return closestPlayer;
}

const getCenterOfTriangle = (p1, p2, p3) => {
  const x = (p1.x + p2.x + p3.x) / 3;
  const y = (p1.y + p2.y + p3.y) / 3;
  return { x, y };
}