import { Flex, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import { convertPlayerObject } from '../../lib/convertPlayerObject';
import connection from '../../feathers';
import { getPeriodTimeDown } from '../../lib/getPeriodTime';
import { convertTime } from '../../lib/convertTime';
import { getGamePeriod } from '../../lib/getGamePeriod';
import { useNavigate } from 'react-router';
import { getGameProgress } from '../../lib/getGameProgress';

export const ActiveGamePreview = ({ game }) => {
  const [teams, setTeams] = useState([]);
  const [time, setTime] = useState(Date.now());
  const [homeGoals, setHomeGoals] = useState(0);
  const [awayGoals, setAwayGoals] = useState(0);
  const [players, setPlayers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      // connect to the on create game hook
      const teamRes = await connection.service('teams').find({
        query: {
          $or: [
            { ownerId: game.homeId },
            { ownerId: game.awayId }
          ]
        }
      });
      //console.log('teamRes', teamRes)
      setTeams(teamRes.data);

      const playersRes = await connection.service('players').find({
        query: {
          $limit: 200,
          $or: [
            { ownerId: game.homeId },
            { ownerId: game.awayId }
          ],
        }
      });
      setPlayers(convertPlayerObject(playersRes.data.filter(p => p.line > 0)));

    }

    load();

    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [game]);

  const update = async () => {
    const state = await getGameProgress(game, players);

    setHomeGoals(state.home.goals);
    setAwayGoals(state.away.goals);
  }

  useEffect(() => {
    update();
  }, [time]);


  if (!teams || !game || teams.length <= 0) return;
  return (
    <Flex onClick={() => (getGamePeriod(Math.round(time / 1000) - Math.round(game.startTime / 1000), true) === 'Final') ? '' : navigate('/live/' + game._id)} key={game._id} gap="1rem" p="0.5rem 0.75rem" align="center" style={{
      cursor: 'pointer',
      backgroundColor: 'var(--theme-color)',
      borderRadius: '0.25rem',
      boxShadow: '1px 1px 3px rgba(0,0,0,0.2)',
      minWidth: '150px'
    }}>
      <div style={{
        fontSize: '1.25rem',
      }}>
        <Flex justify="space-between" align="center" gap="1rem">
          <Text tt='uppercase'>{teams.find(t => t.ownerId === game.homeId).abbr}</Text>
          <Text>{homeGoals}</Text>
        </Flex>
        <Flex justify="space-between" align="center" gap="1rem">
          <Text tt='uppercase'>{teams.find(t => t.ownerId === game.awayId).abbr}</Text>
          <Text>{awayGoals}</Text>
        </Flex>
      </div>
      <Flex direction="column" align="center" pl="1rem" style={{ borderLeft: '1px solid rgba(255,255,255,0.4)'}}>
        <Text>
          {convertTime(getPeriodTimeDown(Math.round(time / 1000) - Math.round(game.startTime / 1000)))}
        </Text>
        <Text tt="uppercase">
          {getGamePeriod(Math.round(time / 1000) - Math.round(game.startTime / 1000), true)}
        </Text>
      </Flex>
    </Flex>
  )
}
