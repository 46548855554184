import React, { useEffect, useState } from 'react'
import { Flex, Indicator, Loader, Skeleton, Text, Title } from '@mantine/core';

import connection from '../../feathers';
import { getTotalSalary } from '../../lib/getTotalSalary';
import { convertPlayerObject } from '../../lib/convertPlayerObject';
import { NextGame } from './NextGame';
import { shadeColor } from '../../lib/shadeColor';

import './TeamOverview.scss';
import { getContrast } from '../../lib/getContrast';

export const TeamOverview = ({ navigate }) => {
  const [user, setUser] = useState(null);
  const [players, setPlayers] = useState([]);
  const [location, setLocation] = useState('');
  const [teamName, setTeamName] = useState('');
  const [abbr, setAbbr] = useState('');
  const [color, setColor] = useState('');
  const [colorShade, setColorShade] = useState('');
  const [record, setRecord] = useState('0-0-0');
  const [packCount, setPackCount] = useState(0);
  const [games, setGames] = useState([]);

  const [loading, setLoading] = useState(true)

  const load = async () => {
    const currentUser = await connection.get('authentication');
    setUser(currentUser.user);
    // listen on create for packs from this user to update the packCount
    connection.service('packs').on('created', async (pack) => {
      if (pack.ownerId === currentUser.user._id)
        setPackCount(packCount + 1);
    });

    // listen on remove for packs from this user to update the packCount
    connection.service('packs').on('removed', async (pack) => {
      if (pack.ownerId === currentUser.user._id)
        setPackCount(packCount - 1);
    });
    

    let res = await connection.service('packs').find({
      query: {
        $limit: 0,
        ownerId: currentUser.user._id
      }
    })
    setPackCount(res.total);

    res = await connection.service('players').find({
      paginate: false,
      query: {
        $limit: 100,
        ownerId: currentUser.user._id
      }
    })
    setPlayers(convertPlayerObject(res.data));

    res = await connection.service('games').find({
      query: {
        $limit: 10,
        $or: [
          { homeId: currentUser.user._id },
          { awayId: currentUser.user._id }
        ],
        startTime: {
          $lt: Date.now() - 3720000
        },
        $sort: {
          startTime: -1
        },
      }
    });
    setGames(res.data);

    let wins = 0;
    let losses = 0;
    let ties = 0;

    res.data.filter(g => Date.now() / 1000 > (g.startTime / 1000) + 3600).forEach(game => {
      const homeTeam = game.homeId === currentUser.user._id;
      const tie = game.homeStats.goals === game.awayStats.goals;
      const won = (homeTeam && game.homeStats.goals > game.awayStats.goals) || (!homeTeam && game.homeStats.goals < game.awayStats.goals);

      if (tie) ties++;
      else if (won) wins++;
      else losses++;
    })

    setRecord(`${wins}-${losses}-${ties}`);

    res = await connection.service('teams').find({
      query: {
        ownerId: currentUser.user._id
      }
    })

    const team = res.data.find(e => e.ownerId === currentUser.user._id)

    setLocation(team.city);
    setTeamName(team.name);
    setAbbr(team.abbr);
    setColor(team.color);
    setColorShade(shadeColor(team.color, -30));
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, [])

  const getAverageOverall = () => {
    let total = 0;
    players.filter(e => e.line > 0).forEach(player => {
      total += player.overall();
    })
    return (total / players.filter(e => e.line > 0).length * 100).toFixed(0);
  }
  const getAverageOverallForwards = () => {
    let total = 0;
    players.forEach(player => {
      if (player.type.role.abbr === 'F' && player.line > 0) {
        total += player.overall();
      }
    })
    return (total / 12 * 100).toFixed(0);
  }
  const getAverageOverallDefense = () => {
    let total = 0;
    players.forEach(player => {
      if (player.type.role.abbr === 'D' && player.line > 0) {
        total += player.overall();
      }
    })
    return (total / 6 * 100).toFixed(0);
  }

  const renderLastGames = () => {
    let results = [];

    games.forEach((game, index) => {
      const homeTeam = game.homeId === user._id;
      const tie = game.homeStats.goals === game.awayStats.goals;
      const won = (homeTeam && game.homeStats.goals > game.awayStats.goals) || (!homeTeam && game.homeStats.goals < game.awayStats.goals);
      results.push(
        <Flex onClick={() => navigate((((Date.now() - game.startTime) / 1000 < 3700) ? '/live/' : '/recap/') + game._id)} key={game._id} className='lastTenGameItem' gap="1rem" justify="space-between" align="center" style={{
          textAlign: 'center',
          cursor: 'pointer',
          padding: '0.75rem',
          width: '100%',
          backgroundColor: (index % 2 === 0) ? shadeColor('#ec1668', -70) : shadeColor('#ec1668', -75),
        }}>
          <div style={{ textAlign: 'left', fontSize: '1.25rem', width: ((window.innerWidth < 600)) ? '100px' : '300px' }}>
            {(window.innerWidth < 600) ? (
              (homeTeam) ? game.away.abbr : game.home.abbr
            ) : (
              (homeTeam) ? game.away.city + ' ' + game.away.name : game.home.city + ' ' + game.home.name
            )}
          </div>
          <div style={{ width: '50px', textAlign: 'left' }}>{new Date(game.startTime).toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
          })}</div>
          <div>{(homeTeam) ? 'H' : 'A'}</div>
          <div style={{ width: '50px' }}>{((Date.now() - game.startTime) / 1000 < 3700) ? '' : (homeTeam) ? game.homeStats.goals + ' - ' + game.awayStats.goals : game.awayStats.goals + ' - ' + game.homeStats.goals}</div>
          <div style={{ width: '35px', textAlign: 'right' }}>{((Date.now() - game.startTime) / 1000 < 3700) ? 'Live' : (tie) ? 'Draw' : (won) ? 'Win' : 'Lose'}</div>
        </Flex>
      )
    })

    return results;
  }

  if (!color) return;
  return (
    <div>
      <div style={{
        backgroundColor: 'var(--theme-color)',
        maxWidth: '650px',
        margin: '1rem auto 0 auto',
        width: '100%',
        textAlign: 'center',
        padding: '0.5rem',
        textTransform: 'uppercase',
      }}>
        Currently testing limited rosters, reduced earnings, and a 5 game daily progression limit on packs
      </div>
      <div className='teamOverview' style={{
        background: `linear-gradient(120deg, ${colorShade} 0%, ${color} 20%, ${colorShade} 100%)`,
        color: getContrast(color),
      }}>
        <Flex align="center" gap="1rem">
          {(window.innerWidth < 650) ? '' : (
          <div>
            <Title size="6rem">{abbr}</Title>
          </div>
          )}
          <div>
            <Text tt="uppercase" weight={400} size={(window.innerWidth < 650) ? "1rem" : "1.5rem"} mb={(window.innerWidth < 650) ? "-0.75rem" : "-1rem"} ml="0.1rem">{location}</Text>
            <Title tt="uppercase" weight={400} size={(window.innerWidth < 650) ? "2.5rem" : "4rem"}>{teamName}</Title>
          </div>
        </Flex>
        <div style={{ marginTop: '0.5rem' }} className='overviewRecord'>
          <div className='label'>LAST 10</div>
          <div className='record'>{record}</div>
        </div>
        
        <Flex mt="3rem" gap="1rem" align="flex-end" justify="space-between">
          <div>
            <Text>Remaining Cap: ${(100 - getTotalSalary(players)).toFixed(2)}M</Text>
            <Text>Contracts: {players.length}/35</Text>
          </div>
          <Flex gap="1rem" ta="center">
            <div>
              <Title>{getAverageOverallForwards()}</Title>
              <div>OFF</div>
            </div>
            <div>
              <Title>{getAverageOverallDefense()}</Title>
              <div>DEF</div>
            </div>
            <div>
              <Title>{getAverageOverall()}</Title>
              <div>OVR</div>
            </div>
          </Flex>
        </Flex>
      </div>
      <NextGame />
      <div className='overviewButtons'>
        <Flex my={(window.innerWidth < 650) ? "0.5rem" : "2rem"} gap={(window.innerWidth < 650) ? "0.5rem" : "1rem"} justify="space-between">
          <div className='managementLink' onClick={() => navigate('/roster')}>
            Manage Roster
          </div>
          <div className='managementLink' onClick={() => navigate('/lines')}>
            Set Lines
          </div>
          <div className='managementLink' onClick={() => navigate('/packs')}>
            <Indicator disabled={packCount < 1} w="85%" ta="center" inline size={16} processing>
              <div className='managementLinkInside'>
                Packs
              </div>
            </Indicator>
          </div>
        </Flex>
      </div>
      <Flex style={{
        width: '100%',
        maxWidth: '650px',
        marginRight: 'auto',
        marginLeft: 'auto'
      }}>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', backgroundColor: shadeColor('#ec1668', -40), padding: '0.25rem 0.5rem', textTransform: 'uppercase' }}>
            <Text size="1.25rem">Last 10 Games</Text>
          </div>
          {renderLastGames()}
        </div>
        {/*RHS: Top Players | Goals | Points | Hits*/}
      </Flex>
    </div>
  )
}
