import React, { useEffect, useState } from 'react'
import { Flex, Tabs, Text, Title } from '@mantine/core'
import { useParams } from 'react-router';

import connection from '../../feathers';
import { getShortenedPlayerName } from '../../lib/getShortenedName';
import { convertTime } from '../../lib/convertTime';
import { PlayerTypes } from '../../data/types';

export const Recap = () => {
  const { id } = useParams();
  const [game, setGame] = useState(null);
  const [home, setHome] = useState(null);
  const [away, setAway] = useState(null);
  const [stats, setStats] = useState(null);
  const [players, setPlayers] = useState(null);

  const load = async () => {
    const res = await connection.service('games').get(id);
    //console.log('game', res);
    setGame(res);
    setHome({
      ...res.home,
      ...res.homeStats
    });
    setAway({
      ...res.away,
      ...res.awayStats
    });

    // player-game-stats
    const statsRes = await connection.service('player-game-stats').find({
      query: {
        $limit: 100,
        gameId: res._id,
      }
    });
    //console.log('stats', statsRes);
    setStats(statsRes.data);

    // get all players in this game
    const playersRes = await connection.service('players').find({
      query: {
        $limit: 1000,
        $or: [
          { ownerId: res.homeId },
          { ownerId: res.awayId }
        ],
      }
    });
    //console.log('players', playersRes);

    setPlayers(playersRes.data.filter(p =>
      statsRes.data.filter(s =>
        s.playerId === p._id).length > 0));

  }

  useEffect(() => {
    load();
  }, []);

  const renderPlayers = (ownerId) => {
    const list = players.filter(p => p.ownerId === ownerId &&
    p.type !== "Goaltender").map(player => {
      return {
        player: { ...player },
        type: PlayerTypes[player.type.split(' ').join('').split('-').join('')],
        stats: { ...stats.find(s => s.playerId === player._id) }
      }
    })
    //console.log(list);
    return list
      .sort((a, b) => a.stats.timeOnIce < b.stats.timeOnIce ? 1 : -1)
      .map(player => {
      return (
        <div key={player._id} style={{ textAlign: 'center', display: 'grid', alignItems: 'center', gridTemplateColumns: (window.innerWidth < 650) ? '2fr repeat(5, 1fr)' : '3fr repeat(11, 1fr)', padding: '0.25rem 0' }}>
          <Text ta="left">{getShortenedPlayerName(player.player)}</Text>
          <div className='hideNarrow'>{player.type.abbr}</div>
          <div>{player.stats.goals || 0}</div>
          <div>{player.stats.assists || 0}</div>
          <div>{player.stats.shots || 0}</div>
          <div>{player.stats.plusMinus || 0}</div>
          <div className='hideNarrow'>{player.stats.hits || 0}</div>
          <div className='hideNarrow'>{player.stats.blocks || 0}</div>
          <div className='hideNarrow'>{player.stats.takeaways || 0}</div>
          <div className='hideNarrow'>{player.stats.faceoffsWon || 0}</div>
          <div className='hideNarrow'>{player.stats.faceoffs || 0}</div>
          <div>{convertTime(player.stats.timeOnIce)}</div>
        </div>
      )
    })
  }

  if (!game || !home || !away || !stats || !players) return '';

  return (
    <div>
      <Flex gap="1rem" justify="center" align="center">
        <Title tt="uppercase">{(window.innerWidth > 600) ? home.name : home.abbr}</Title>
        <Text size="1rem" italic>vs</Text>
        <Title tt="uppercase">{(window.innerWidth > 600) ? away.name : away.abbr}</Title>
      </Flex>

      <Tabs defaultValue="matchup" mt="1rem">
        <Tabs.List position="center">
          <Tabs.Tab value="home">{home.name}</Tabs.Tab>
          <Tabs.Tab value="matchup">Matchup</Tabs.Tab>
          <Tabs.Tab value="away">{away.name}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="home">
          <div style={{ width: '100%', maxWidth: '650px', margin: '1rem auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{ fontSize: '1.25rem', margin: '0.5rem 0', textAlign: 'center', display: 'grid', alignItems: 'center', gridTemplateColumns: (window.innerWidth < 650) ? '2fr repeat(5, 1fr)' : '3fr repeat(11, 1fr)' }}>
              <div style={{ textAlign: 'left' }}>Name</div>
              <div className='hideNarrow'>Type</div>
              <div>G</div>
              <div>A</div>
              <div>SOG</div>
              <div>+/-</div>
              <div className='hideNarrow'>H</div>
              <div className='hideNarrow'>B</div>
              <div className='hideNarrow'>TA</div>
              <div className='hideNarrow'>FW</div>
              <div className='hideNarrow'>FO</div>
              <div>TOI</div>
            </div>
            {renderPlayers(home.ownerId)}
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="matchup">
          <div style={{ width: '90%', maxWidth: '600px', margin: '1rem auto'}}>
            <Flex mb="1rem" gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="2rem" tt="uppercase" w="280px" style={{ whiteSpace: 'nowrap', overflow: 'hidden'}}>{home.name}</Text>
              <Text size="1rem" italic ta="center" w="20px">vs</Text>
              <Text size="2rem" tt="uppercase" w="280px" ta="right" style={{ whiteSpace: 'nowrap', overflow: 'hidden'}}>{away.name}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{home.goals}</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * home.goals / Math.max(home.goals, away.goals)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">G</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * away.goals / Math.max(home.goals, away.goals)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.goals}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{home.shots}</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * home.shots / Math.max(home.shots, away.shots)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">SOG</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * away.shots / Math.max(home.shots, away.shots)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.shots}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{away.shots-away.goals}</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * (away.shots-away.goals) / Math.max((away.shots-home.goals), (home.shots-away.goals))) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">SV</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * (home.shots-home.goals) / Math.max((away.shots-home.goals), (home.shots-away.goals))) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{home.shots-home.goals}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{((away.shots-away.goals) / away.shots).toFixed(3)}</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * (away.shots-away.goals) / away.shots) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">SV%</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * (home.shots-home.goals) / home.shots) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{((home.shots - home.goals) / home.shots).toFixed(3)}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{home.hits}</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * home.hits / Math.max(home.hits, away.hits)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">HITS</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * away.hits / Math.max(home.hits, away.hits)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.hits}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{home.blocks}</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * home.blocks / Math.max(home.blocks, away.blocks)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">BLKS</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * away.blocks / Math.max(home.blocks, away.blocks)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.blocks}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{home.takeaways}</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * home.takeaways / Math.max(home.takeaways, away.takeaways)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">TK</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * away.takeaways / Math.max(home.takeaways, away.takeaways)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{away.takeaways}</Text>
            </Flex>
            <Flex gap="1rem" justify="space-between" align="center" w="100%">
              <Text size="1.5rem" tt="uppercase" w="50px">{Math.round(100 * home.faceoffsWon / (home.faceoffsWon + away.faceoffsWon))}%</Text>
              <Flex w="200px" justify="flex-end" align="center">
                <div style={{ width: (100 * home.faceoffsWon / Math.max(home.faceoffsWon, away.faceoffsWon)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1rem" italic w="30px" ta="center">FO%</Text>
              <Flex w="200px" justify="flex-start" align="center">
                <div style={{ width: (100 * away.faceoffsWon / Math.max(home.faceoffsWon, away.faceoffsWon)) + '%', height: '10px', backgroundColor: 'var(--theme-color)' }} />
              </Flex>
              <Text size="1.5rem" tt="uppercase" w="50px" ta="right">{Math.round(100 * away.faceoffsWon / (home.faceoffsWon + away.faceoffsWon))}%</Text>
            </Flex>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="away">
          <div style={{ width: '100%', maxWidth: '650px', margin: '1rem auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{ fontSize: '1.25rem', margin: '0.5rem 0', textAlign: 'center', display: 'grid', alignItems: 'center', gridTemplateColumns: (window.innerWidth < 650) ? '2fr repeat(5, 1fr)' : '3fr repeat(11, 1fr)' }}>
              <div style={{ textAlign: 'left' }}>Name</div>
              <div className='hideNarrow'>Type</div>
              <div>G</div>
              <div>A</div>
              <div>SOG</div>
              <div>+/-</div>
              <div className='hideNarrow'>H</div>
              <div className='hideNarrow'>B</div>
              <div className='hideNarrow'>TA</div>
              <div className='hideNarrow'>FW</div>
              <div className='hideNarrow'>FO</div>
              <div>TOI</div>
            </div>
            {renderPlayers(away.ownerId)}
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  )
}
