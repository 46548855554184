import { Button, Flex, Tooltip } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { getContrast, isColorClose } from '../../lib/getContrast'
import { convertTime } from '../../lib/convertTime'
import { getPeriodTime } from '../../lib/getPeriodTime'
import { RINK_HEIGHT, RINK_WIDTH } from '../../lib/simulation/config'

export const LiveRink = ({ state, game, players, home, away }) => {
  const [currentState, setCurrentState] = useState(state[0]);
  const [index, setIndex] = useState(0);

  const getPositionLabel = (playerId) => {
    const position = players.find(p => p._id === playerId).position;
    switch(position) {
      case 'center': return 'C';
      case 'leftWing': return 'LW';
      case 'rightWing': return 'RW';
      case 'leftDefense': return 'LD';
      case 'rightDefense': return 'RD';
      case 'goaltender': default: return 'G';
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (index < state.length - 1) setIndex(index + 1);
    }, 50);
    return () => clearInterval(interval);
  }, [index]);

  useEffect(() => {
    console.log('Next Tick', index, state[index].action, state[index].puck, state[index].target);
    setCurrentState(state[index]);
  }, [index]);

  const getTeamColor = (id) => {
    const player = players.find(p => p._id === id);
    if (player.ownerId === home.ownerId) return home.color;
    if (isColorClose(away.color, home.color)) return getContrast(home.color);
    return away.color;
  }

  const renderPuck = (puck) => {
    return (
      <Tooltip label={'(' + puck.x.toFixed(0) + ', ' + puck.y.toFixed(0) + ')'} position="top">
      <div style={{
        cursor: 'pointer',
        position: 'absolute',
        top: 'calc(' + puck.y / RINK_HEIGHT * 100 + '% - 6px)',
        left: 'calc(' + puck.x / RINK_WIDTH * 100 + '% - 6px)',
        backgroundColor: '#666666',
        width: '12px',
        height: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '0.8',
        color: '#FFFFFF',
        fontweight: 'bold',
        fontSize: '0.5em',
        borderRadius: '50%',
        boxShadow: '1px 2px 3px 1px rgba(0,0,0,0.2)',
      }}>
        P
      </div>
      </Tooltip>
    )
  }

  if (!currentState) return null;
  return (
    <div>
      <Flex w="600px" m="0 auto" maw="100%" justify="center">
        <div style={{ position: 'relative'}}>
          <img style={{ width: '100%' }} src='/images/rink.svg' alt='rink' />
          <div style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%'
          }}>
          {currentState.players.map((player, index) => (
            <Tooltip label={player.playerId + ' | (' + player.position.x.toFixed(0) + ', ' + player.position.y.toFixed(0) + ')'} position="top">
            <div style={{
              cursor: 'pointer',
              position: 'absolute',
              top: 'calc(' + player.position.y / RINK_HEIGHT * 100 + '% - 12px)',
              left: 'calc(' + player.position.x / RINK_WIDTH * 100 + '% - 12px)',
              backgroundColor: getTeamColor(player.playerId),
              width: '24px',
              height: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: '0.8',
              borderRadius: '6px',
              color: getContrast(getTeamColor(player.playerId)),
              fontweight: 'bold',
              boxShadow: '1px 2px 3px 1px rgba(0,0,0,0.2)',
            }}>
              {getPositionLabel(player.playerId)}
            </div>
            </Tooltip>
          ))}
          {renderPuck(currentState.puck)}
          </div>
        </div>
      </Flex>
      <Button onClick={() => setIndex(index + 1)}>Next Tick</Button>
    </div>
  )
}
