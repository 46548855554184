import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import connection from '../../feathers';
import { Rink } from './Rink';
import { Flex, Text } from '@mantine/core';
import { EventLog } from './EventLog';
import { convertPlayerObject } from '../../lib/convertPlayerObject';
import { getPeriodTimeDown } from '../../lib/getPeriodTime';
import { getGamePeriod } from '../../lib/getGamePeriod';
import { convertTime } from '../../lib/convertTime';
import { getGameProgress } from '../../lib/getGameProgress';

export const Live = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [game, setGame] = useState(null);
  const [events, setEvents] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [time, setTime] = useState(Date.now());
  const [currentEvents, setCurrentEvents] = useState([]);
  const [players, setPlayers] = useState([])
  const [home, setHome] = useState({
    name: '',
    abbr: '',
    goals: 0,
    shots: 0,
    hits: 0,
    blocks: 0,
    takeaways: 0,
    faceoffs: 0,
    faceoffsWon: 0,
  })
  const [away, setAway] = useState({
    name: '',
    abbr: '',
    goals: 0,
    shots: 0,
    hits: 0,
    blocks: 0,
    takeaways: 0,
    faceoffs: 0,
    faceoffsWon: 0,
  })

  const load = async () => {
    const res = await connection.service('games').get(id);
    const playersRes = await connection.service('players').find({
      query: {
        $limit: 1000,
        $or: [
          { ownerId: res.homeId },
          { ownerId: res.awayId }
        ],
      }
    });
    setPlayers(convertPlayerObject(playersRes.data.filter(p => p.line > 0)));
    //console.log(res);
    const teams = await connection.service('teams').find({ query: {
      $or: [
        { ownerId: res.homeId },
        { ownerId: res.awayId }
      ],
    }});
    //console.log(res, teams);
    setHome({
      ...home,
      ...teams.data.find(e => e.ownerId === res.homeId),
      name: teams.data.find(e => e.ownerId === res.homeId).name,
      abbr: teams.data.find(e => e.ownerId === res.homeId).abbr,
      city: teams.data.find(e => e.ownerId === res.homeId).city,
    })
    setAway({
      ...away,
      ...teams.data.find(e => e.ownerId === res.awayId),
      name: teams.data.find(e => e.ownerId === res.awayId).name,
      abbr: teams.data.find(e => e.ownerId === res.awayId).abbr,
      city: teams.data.find(e => e.ownerId === res.awayId).city,
    })

    setStartTime(Math.round(res.startTime / 1000));
    setGame(res);

    // add 60 time to events after 1200 and an addition 60 after 2400
    const events = res.events.map((event) => {
      if (event.time >= 2400) {
        event.time += 60;
      }
      if (event.time >= 1200) {
        event.time += 60;
      }
      return event;
    })
    setEvents(events);
    //console.log('events', events);
  }

  const update = async () => {
    if ((Date.now() - game.startTime) / 1000 > 3730) navigate('/recap/' + game._id);
    const gameState = await getGameProgress(game, players);

    setHome({
      ...home,
      goals: gameState.home.goals,
      shots: gameState.home.shots,
    })
    setAway({
      ...away,
      goals: gameState.away.goals,
      shots: gameState.away.shots,
    })

    // get the shot count

    setCurrentEvents(gameState.events);
    
  }

  useEffect(() => {
    if (game) update();
    if (game && ((Date.now() - game.startTime) / 1000 > 3720)) navigate('/recap/' + game._id);
  }, [time])

  useEffect(() => {
    load();

    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [])

  return (
    <div style={{ paddingBottom: "2rem" }}>
      <Flex justify="center" align="center" gap="1rem" my="2rem" style={{ textTransform: 'uppercase' }}>
        <Flex direction="column" align="center" w={(window.innerWidth < 600) ? "150px" : "300px"} style={{ borderLeft: '6px solid ' + home.color }}>
          <div>
            {(window.innerWidth < 600) ? (
              <Text size="3rem" mt="-1rem">{home.abbr}</Text>
            ) : (
              <>
              <Text size="1rem">{home.city}</Text>
              <Text lh="1" size="3rem">{home.name}</Text>
              </>
            )}
          </div>
          <div>{home.shots} SHOTS</div>
        </Flex>
        <Flex direction="column" align="center" w="150px" mx="0.5rem">
          <Text style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} p="0 1rem" size={(window.innerWidth < 600) ? "2rem" : "3rem"}>{home.goals} - {away.goals}</Text>
          <Text mt="0.5rem">{convertTime(getPeriodTimeDown(Math.round(time / 1000) - startTime))}</Text>
          <Text>{getGamePeriod(Math.round(time / 1000) - startTime)}</Text>
        </Flex>
        <Flex direction="column" align="center" maw="300px" w={(window.innerWidth < 600) ? "150px" : "300px"} style={{ borderRight: '6px solid ' + away.color }}>
          <div>
            {(window.innerWidth < 600) ? (
              <Text size="3rem" mt="-1rem">{away.abbr}</Text>
            ) : (
              <>
              <Text size="1rem">{away.city}</Text>
              <Text lh="1" size="3rem">{away.name}</Text>
              </>
            )}
          </div>
          <div>{away.shots} SHOTS</div>
        </Flex>
      </Flex>
      <Rink events={currentEvents} time={Math.round(time / 1000) - startTime} players={players} home={home} away={away} />
      <EventLog events={currentEvents} players={players} home={home} away={away} />
    </div>
  )
}
