import React, { useEffect, useState } from 'react'
import { Button, Flex, Modal, Text, TextInput, Title } from '@mantine/core';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

import { getShortenedPlayerName } from '../../lib/getShortenedName';
import { getSalary } from '../../lib/getSalary';
import connection from '../../feathers';

import './FreeAgents.scss';

export const FreeAgentPlayer = ({ player }) => {
  const [salary, setSalary] = useState('')
  const [releaseModal, { open, close }] = useDisclosure(false);
  const [amount, setAmount] = useInputState();
  const [offers, setOffers] = useState(player.offers);
  const [user, setUser] = useState(null)

  const navigate = useNavigate();

  const load = async () => {
    let userRes = await connection.get('authentication');
    setUser(userRes.user);
  }

  useEffect(() => {
    load();
  })

  useEffect(() => {
    const value = getSalary(player);
    if (value < 1) setSalary(getSalary(player).toFixed(3) + 'M');
    else setSalary(getSalary(player).toFixed(2) + 'M');
  }, [player])

  const bid = async (e) => {
    // test is amount is a number
    if (isNaN(amount)) {
      notifications.show({
        title: 'Invalid Bid',
        color: '#ff0000',
        message: 'Please enter a valid bid amount.',
      });
      return;
    }

    // test if amount is greater than 0
    if (amount < 0.95) {
      notifications.show({
        title: 'Invalid Bid',
        color: '#ff0000',
        message: 'Please enter a valid bid amount. You must offer at least league min of 0.95M.',
      });
      return;
    }

    let currentUser = await connection.service('users').get(user._id);

    // test if the user has enough money
    console.log(currentUser)
    if (typeof currentUser.balance === 'undefined' || currentUser.balance < amount) {
      notifications.show({
        title: 'Insufficient Funds',
        color: '#ff0000',
        message: 'You do not have enough money to make this bid.',
      });
      return;
    }

    // test if the user has enough roster space
    const roster = await connection.service('players').find({
      query: {
        ownerId: user._id,
        $limit: 100,
      }
    });
    if (roster.total >= 30) {
      notifications.show({
        title: 'Roster Full',
        color: '#ff0000',
        message: 'You do not have enough roster space to make this bid.',
      });
      return;
    }

    if (amount < getSalary(player) * 0.75) {
      notifications.show({
        title: 'Low Bid',
        message: 'The player is likely to feel insulted after an offer like this.',
      });
    } else if (amount < getSalary(player) * 0.9) {
      notifications.show({
        title: 'Low Bid',
        message: 'The player is likely to reject this offer.',
      });
    }
    
    const res = await connection.service('offers').create({
      playerId: player._id,
      ownerId: user._id,
      amount: parseFloat(amount),
    })
    console.log(res)
    if (res) {
      if (amount < getSalary(player) * 0.7) {
        notifications.show({
          title: 'Bid Placed',
          message: `Your bid has been placed on ${getShortenedPlayerName(player)}.`,
        });
      }
    }
    setOffers(offers + 1);
    close();
  }

  return (
    <div key={player._id} className='freeAgentPlayer' onClick={() => navigate('/player/' + player._id)}>
      <Modal opened={releaseModal} onClick={(e) => e.stopPropagation() } onClose={close} title="Release Player" centered>
        <Flex direction="column" gap="1rem" align="center" mb="0.5rem">
          <Title>{player.bio.getName()} <span className='highlighted'>[{(player.overall() * 100).toFixed(0)}]</span></Title>
          <Text size="1.1rem">Would you like to place a bid on this player?</Text>
          <Text size="0.9rem" mt="-0.8rem" italic c="dimmed">Remember to keep enough money for this to resolve.</Text>
          <TextInput onChange={setAmount} label="Bid Amount" placeholder="0.00M" />
          <Flex gap="1rem">
            <Button color="red" variant='outline' onClick={(e) => { e.stopPropagation(); close(); }}>Cancel</Button>
            <Button onClick={bid}>Bid</Button>
          </Flex>
        </Flex>
      </Modal>
      
      <div className='hideNarrow'><img width="20px" height="16px" src={player.bio.nationality.flag} alt={player.bio.nationality.name} /></div>
      <div>{getShortenedPlayerName(player)}</div>

      <div className='centered'>{player.bio.age}</div>
      <div className='centered'>{player.type.abbr}</div>
      <div className='centered hideNarrow'>{player.type.role.abbr} | {player.bio.handedness}</div>

      <div className='highlighted centered'>{(player.overall() * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('mental') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('athletic') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('technique') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('shooting') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('defense') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('reflexes') * 100).toFixed(0)}</div>

      <div className='centered'>{salary}</div>
      <div className='centered'>{offers}</div>
      <div className='centered hideNarrow'>{timeSince(player.releasedAt)}</div>
      <div style={{ textAlign: 'right' }}><Button size="xs" onClick={(e) => { e.stopPropagation(); console.log(player); open(); }}>Bid</Button></div>
    </div>
  )
}


const timeSince = (date) => {
  const diff = 86400000 - (Date.now() - new Date(date).getTime());
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));
  const seconds = Math.floor(diff / (1000));

  if (hours > 0) return hours + 'h';
  else if (minutes > 0) return minutes + 'm';
  else if (seconds > 0) return '1m';
  else return '-';
}