import React, { useEffect, useState } from 'react'
import { Button, Flex, Modal, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { VscJersey } from 'react-icons/vsc';

import { getSalary, getUnadjustedSalary } from '../../lib/getSalary';
import connection from '../../feathers';

import './Roster.scss';
import { notifications } from '@mantine/notifications';
import { getShortenedPlayerName } from '../../lib/getShortenedName';

export const RosterPlayer = ({ index, player, update, user, allowRelease = true }) => {
  const [salary, setSalary] = useState('')
  const [releaseModal, { open, close }] = useDisclosure(false);
  const [lock, setLock] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const value = getSalary(player);
    if (value < 1) setSalary(getSalary(player).toFixed(3) + 'M');
    else setSalary(getSalary(player).toFixed(2) + 'M');
  }, [player])

  const getValue = () => {
    if (player.overall() < 0.55) return 0;
    const valueRating = (player.overall() - 0.5) * 2;
    const contractValue = (1 - ((player.bio.age - 18) / 18) * 0.4) + 0.4;

    let adjuster = 1;
    if (player.adjustedSalary < 0.5) adjuster = 1 + 0.25 * (((1 - player.adjustedSalary) - 0.5) * 2);
    
    return Math.min(getUnadjustedSalary(player) * valueRating * contractValue * adjuster, getSalary(player) * 0.3);
  }

  const releasePlayer = async (e) => {
    e.stopPropagation();
    setLock(true);

    notifications.show({
      title: 'Player Released',
      message: 'You have released ' + player.bio.getName() + ' and gained ' + getValue().toFixed(3) + 'M.',
    });

    //console.log('release', currentUser.user);
    const currentUser = await connection.service('users').get(user._id);
    if (typeof currentUser.balance === 'undefined') currentUser.balance = 0;
    connection.service('users').patch(player.ownerId, {
      balance: currentUser.balance + getValue()
    });
    if (player.overall() < 0.6) {
      // remove player
      await connection.service('players').remove(player._id);
    } else {
      await connection.service('players').patch(player._id, {
        ownerId: null,
        gamesPlayed: 0,
        contractLength: 20,
        previousOwner: player.ownerId,
        releasedAt: Date.now()
      });
    }
    setLock(false);
    close();
    update();
  }

  return (
    <div key={player._id} className='rosterPlayer' onClick={() => navigate('/player/' + player._id)}>
      <Modal opened={releaseModal} onClick={(e) => e.stopPropagation()} onClose={close} title="Release Player" centered>
        <Flex direction="column" gap="1rem" align="center" mb="0.5rem">
          <Title>{player.bio.getName()}</Title>
          <Text>Are you sure you want to release this player?</Text>
          <Text>You will gain: <span className='highlighted'>{getValue().toFixed(3)}M</span></Text>
          <Flex gap="1rem">
            <Button color="red" variant='outline' onClick={(e) => { e.stopPropagation(); close(); }}>Cancel</Button>
            <Button disabled={lock} onClick={releasePlayer}>Release</Button>
          </Flex>
        </Flex>
      </Modal>
      
      <div style={{ width: '20px' }}>{(player.line) ? <VscJersey /> : ''}</div>
      <div className='hideNarrow'><img width="20px" height="16px" src={player.bio.nationality.flag} alt={player.bio.nationality.name} /></div>
      <div>{getShortenedPlayerName(player)}</div>
      
      {/*<div className={(player.gamesPlayed >= player.contractLength || player.longevity < player.bio.age) ? 'highlighted centered' : 'centered'}>{(player.longevity >= player.bio.age) ?  (player.contractLength - player.gamesPlayed) + ' LEFT' : 'RETIRED'}</div>*/}

      <div className='centered hideNarrow'>{player.type.abbr}</div>
      <div className='centered hideNarrow'>{player.type.role.abbr} | {player.bio.handedness}</div>

      <div className='highlighted centered'>{(player.overall() * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('mental') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('athletic') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('technique') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('shooting') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('defense') * 100).toFixed(0)}</div>
      <div className='centered hideNarrow'>{(player.overall('reflexes') * 100).toFixed(0)}</div>

      <div className='centered'>{salary}</div>
      {(allowRelease && player.longevity >= player.bio.age) ? (
        <div><Button disabled={lock || player.ownerId !== user._id} size="xs" onClick={(e) => {
          e.stopPropagation();
          if (player.line) {
            notifications.show({
              title: 'Cannot release player',
              message: 'You cannot release a player that is currently in your lineup.',
              color: 'red',
            })
            return;
          }
          open();
        }}>
          {(player.ownerId === user._id) ? 'Release' : 'Trade'}
        </Button></div>
      ) : ''}
    </div>
  )
}
