
import { PlayerTypes } from '../data/types';

export const convertPlayerObject = (players) => {
  let results = [];
  
  players.forEach(player => {
    results.push({
      ...player,
      bio: {
        ...player.bio,
        getName: function () {
          return this.forename + ' ' + this.surname;
        }
      },
      type: PlayerTypes[player.type.split(' ').join('').split('-').join('')],
      overall: function (category = '') {
        let e;
        switch (category) {
          case 'mental':
            e = this.mental;
            if (this.type === PlayerTypes.Goaltender)
              return (e.awareness + e.tenacity + e.vision) / 3;
            else
              return (e.awareness + e.discipline + e.tenacity + e.vision) / 4;
          case 'athletic':
            e = this.athletic;
            if (this.type === PlayerTypes.Goaltender)
              return (e.balance + e.agility) / 2;
            else
              return (e.balance + e.strength + e.endurance + e.agility + e.speed) / 5;
          case 'technique':
            e = this.technique;
            if (this.type === PlayerTypes.Goaltender)
              return (e.positioning + e.control) / 2;
            else
              return (e.control + e.dangling + e.faceoff + e.passing) / 4;
          case 'shooting':
            if (this.type === PlayerTypes.Goaltender) return 0
            e = this.shooting;
            return (e.accuracy + e.power + e.range + e.skill) / 4;
          case 'defense':
            if (this.type === PlayerTypes.Goaltender) return 0
            e = this.defense;
            return (e.zone + e.cover + e.intercept + e.hitting) / 4;
          case 'reflexes':
            if (this.type !== PlayerTypes.Goaltender) return 0
            e = this.reflexes;
            return (e.low + e.high + e.stick + e.glove) / 4;
          default:
            let sum = 0;
            if (this.type === PlayerTypes.Goaltender) {
              sum += this.mental.awareness + this.mental.tenacity + this.mental.vision;
              sum += this.athletic.balance + this.athletic.agility
              sum += this.technique.positioning + this.technique.control;
              e = this.reflexes;
              sum += e.low + e.high + e.stick + e.glove;
              return sum / 11;
            } else {
              e = this.mental;
              sum += e.awareness + e.discipline + e.tenacity + e.vision;
              e = this.athletic;
              sum += e.balance + e.strength + e.endurance + e.agility + e.speed;
              e = this.technique;
              sum += e.control + e.dangling + e.faceoff + e.passing;
              e = this.shooting;
              sum += e.accuracy + e.power + e.range + e.skill;
              e = this.defense;
              sum += e.zone + e.cover + e.intercept + e.hitting;
              return sum / 21;
            }
        }
      }
    })
  })

  return results;
}

export const convertSinglePlayerObject = (player) => {
  return {
    ...player,
    bio: {
      ...player.bio,
      getName: function () {
        return this.forename + ' ' + this.surname;
      }
    },
    type: PlayerTypes[player.type.split(' ').join('').split('-').join('')],
    overall: function (category = '') {
      let e;
      switch (category) {
        case 'mental':
          e = this.mental;
          if (this.type === PlayerTypes.Goaltender)
            return (e.awareness + e.tenacity + e.vision) / 3;
          else
            return (e.awareness + e.discipline + e.tenacity + e.vision) / 4;
        case 'athletic':
          e = this.athletic;
          if (this.type === PlayerTypes.Goaltender)
            return (e.balance + e.agility) / 2;
          else
            return (e.balance + e.strength + e.endurance + e.agility + e.speed) / 5;
        case 'technique':
          e = this.technique;
          if (this.type === PlayerTypes.Goaltender)
            return (e.positioning + e.control) / 2;
          else
            return (e.control + e.dangling + e.faceoff + e.passing) / 4;
        case 'shooting':
          if (this.type === PlayerTypes.Goaltender) return 0
          e = this.shooting;
          return (e.accuracy + e.power + e.range + e.skill) / 4;
        case 'defense':
          if (this.type === PlayerTypes.Goaltender) return 0
          e = this.defense;
          return (e.zone + e.cover + e.intercept + e.hitting) / 4;
        case 'reflexes':
          if (this.type !== PlayerTypes.Goaltender) return 0
          e = this.reflexes;
          return (e.low + e.high + e.stick + e.glove) / 4;
        default:
          let sum = 0;
          if (this.type === PlayerTypes.Goaltender) {
            sum += this.mental.awareness + this.mental.tenacity + this.mental.vision;
            sum += this.athletic.balance + this.athletic.agility
            sum += this.technique.positioning + this.technique.control;
            e = this.reflexes;
            sum += e.low + e.high + e.stick + e.glove;
            return sum / 11;
          } else {
            e = this.mental;
            sum += e.awareness + e.discipline + e.tenacity + e.vision;
            e = this.athletic;
            sum += e.balance + e.strength + e.endurance + e.agility + e.speed;
            e = this.technique;
            sum += e.control + e.dangling + e.faceoff + e.passing;
            e = this.shooting;
            sum += e.accuracy + e.power + e.range + e.skill;
            e = this.defense;
            sum += e.zone + e.cover + e.intercept + e.hitting;
            return sum / 21;
          }
      }
    }
  }
}