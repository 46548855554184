import { Indicator } from '@mantine/core'
import React from 'react'
import { notifications } from '@mantine/notifications';

import connection from '../../feathers'

import './notifications.scss';

export const NotificationItem = ({ notification }) => {

  const handleClick = async () => {
    if (notification.read) {
      // delete
      try {
        await connection.service('notifications').remove(notification._id)

        notifications.show({
          title: 'Notification deleted',
          message: 'This notification has been deleted.',
        })
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await connection.service('notifications').patch(notification._id, {
          read: true
        })
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <Indicator m="0.5rem 0" disabled={notification.read}>
      <div className='notificationItem' onClick={handleClick}>
        <div className='message'>
          {notification.message}
        </div>
        <div className='handleHover'>
          {notification.read ? 'Delete' : 'Mark as Read'}
        </div>
      </div>
    </Indicator>
  )
}
