export const PlayerRole = {
  Forward: {
    name: 'Forward',
    abbr: 'F'
  },
  Defense: {
    name: 'Defensemen',
    abbr: 'D'
  },
  Goaltender: {
    name: 'Goaltender',
    abbr: 'G'
  }
}

export const PlayerTypes = {
  Sniper: { 
    name: 'Sniper',
    abbr: 'SNP',
    role: PlayerRole.Forward,
    modify: {
      mental: {
        awareness: 0.1,
        vision: 0.3
      },
      athletic: {
        agility: 0.2,
        speed: 0.4
      },
      technique: {
        control: 0.1,
        dangling: 0.4,
        faceoff: 0.3,
      },
      shooting: {
        accuracy: 0.4,
        power: 0.35,
        skill: 0.4,
        range: 0.35,
      },
      defense: {
        zone: -0.4,
        cover: -0.2,
        hitting: -0.2,
      },
      reflexes: {}
    },
    actions: { "pass": 0.1, "carry": 0.15, "cutin": 0.1, "shoot": 0.45, "highDangerShot": 0.25, "dangle": 0.2, "wrapAround": 0.2 }
  },
  Grinder: {
    name: 'Grinder',
    abbr: 'GRN',
    role: PlayerRole.Forward,
    modify: {
      mental: {
        discipline: 0.1,
        tenacity: 0.3,
      },
      athletic: {
        balance: 0.2,
        strength: 0.1,
        agility: 0.3,
        speed: 0.5
      },
      technique: {
        control: 0.2,
        passing: 0.1
      },
      shooting: {
        accuracy: 0.25,
        power: 0.1,
        skill: 0.2,
      },
      defense: {
        zone: -0.2,
        hitting: 0.3,
      },
      reflexes: {}
    },
    actions: { "pass": 0.3, "carry": 0.1, "cutin": 0.15, "shoot": 0.25, "dump": 0.2, "clear": 0.2 }
  },
  Playmaker: {
    name: 'Playmaker',
    abbr: 'PMKR',
    role: PlayerRole.Forward,
    modify: {
      mental: {
        awareness: 0.3,
        discipline: 0.1,
        vision: 0.4,
      },
      athletic: {
        balance: 0.1,
        agility: 0.3,
        speed: 0.15,
      },
      technique: {
        control: 0.4,
        faceoff: 0.1,
        passing: 0.5
      },
      shooting: {
        accuracy: 0.2,
        range: 0.1,
        skill: 0.1,
      },
      defense: {
        zone: -0.2,
        cover: -0.1,
        intercept: 0.2,
        hitting: -0.1,
      },
      reflexes: {}
    },
    actions: { "pass": 0.4, "carry": 0.1, "cutin": 0.1, "shoot": 0.4, "dangle": 0.1, "oneTimer": 0.25, "wrapAround": 0.2, "breakoutPass": 0.2 }
  },
  PowerForward: {
    name: 'Power Forward',
    abbr: 'PWRF',
    role: PlayerRole.Forward,
    modify: {
      mental: {
        awareness: 0.1,
        tenacity: 0.2,
      },
      athletic: {
        balance: 0.3,
        strength: 0.5,
        endurance: 0.2,
        agility: -0.2
      },
      technique: {
        control: 0.4,
      },
      shooting: {
        power: 0.25,
        skill: 0.4
      },
      defense: {
        zone: -0.2,
        intercept: 0.2,
        hitting: 0.4,
      },
      reflexes: {}
    },
    actions: { "pass": 0.25, "carry": 0.25, "cutin": 0.125, "shoot": 0.45, "wrapAround": 0.2 }
  },
  TwoWayForward: {
    name: 'Two-Way Forward',
    abbr: 'TWF',
    role: PlayerRole.Forward,
    modify: {
      mental: {
        awareness: 0.1,
        vision: 0.2,
      },
      athletic: {
        endurance: -0.1,
        agility: 0.3,
        speed: 0.3
      },
      technique: {
        dangling: 0.1,
        faceoff: 0.1,
        passing: 0.3,
      },
      shooting: {
        accuracy: 0.3,
        power: 0.3,
        range: 0.4,
        skill: 0.1,
      },
      defense: {
        zone: 0.2,
        cover: -0.1,
        intercept: 0.2,
        hitting: 0.1,
      },
      reflexes: {}
    },
    actions: { "pass": 0.35, "carry": 0.3, "cutin": 0.15, "shoot": 0.3, "dangle": 0.1, "dump": 0.15, "wrapAround": 0.1 }
  },
  TwoWayDefensemen: {
    name: 'Two-Way Defensemen',
    abbr: 'TWD',
    role: PlayerRole.Defense,
    modify: {
      mental: {
        awareness: 0.3,
        vision: 0.4,
      },
      athletic: {
        endurance: 0.1,
        strength: 0.2,
        balance: 0.2,
        speed: 0.2
      },
      technique: {
        control: 0.1,
        passing: 0.3,
      },
      shooting: {
        accuracy: -0.1,
        power: 0.1,
        skill: -0.2,
      },
      defense: {
        zone: 0.5,
        cover: 0.3,
        intercept: 0.2,
        hitting: 0.1,
      },
      reflexes: {}
    },
    actions: { "pass": 0.4, "carry": 0.2, "cutin": 0.1, "dump": 0.1, "oneTimer": 0.2, "clear": 0.2, "shoot": 0.1 }
  },
  OffensiveDefender: {
    name: 'Offensive Defensemen',
    abbr: 'OD',
    role: PlayerRole.Defense,
    modify: {
      mental: {
        awareness: 0.4,
        vision: 0.2,
      },
      athletic: {
        balance: 0.1,
        agility: 0.2,
        speed: 0.2
      },
      technique: {
        passing: 0.3,
      },
      shooting: {},
      defense: {
        zone: 0.4,
        cover: 0.25,
        intercept: 0.3,
        hitting: 0.1,
      },
      reflexes: {}
    },
    actions: { "pass": 0.35, "carry": 0.2, "cutin": 0.1, "dump": 0.1, "dangle": 0.05, "pointShot": 0.15, "shoot": 0.1, "breakoutPass": 0.1 }
  },
  DefensiveDefender: {
    name: 'Defensive Defensemen',
    abbr: 'DD',
    role: PlayerRole.Defense,
    modify: {
      mental: {
        awareness: 0.2,
        vision: 0.3,
      },
      athletic: {
        balance: 0.2,
        strength: 0.1,
        endurance: 0.25,
      },
      technique: {
        passing: 0.2
      },
      shooting: {
        accuracy: -0.2,
        skill: -0.2
      },
      defense: {
        zone: 0.6,
        cover: 0.45,
        intercept: 0.2,
        hitting: 0.45,
      },
      reflexes: {}
    },
    actions: { "pass": 0.4, "carry": 0.15, "dump": 0.2, "clear": 0.25, "shoot": 0.025 }
  },
  Goaltender: {
    name: 'Goaltender',
    abbr: 'G',
    role: PlayerRole.Goaltender,
    modify: {
      mental: {
        vision: 0.2
      },
      athletic: {
        agility: 0.1,
        speed: -0.1
      },
      technique: {
        positioning: 0.3,
        control: 0.2,
      },
      shooting: {},
      defense: {},
      reflexes: {
        low: 0.25,
        high: 0.25,
        glove: 0.3,
        stick: 0.3,
      }
    }
  },
}