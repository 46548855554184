import { createContext, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import connection from './feathers';
import { Launch } from './components/Launch/Launch';
import { Landing } from './components/Landing';
import { CreateTeam } from './components/CreateTeam/CreateTeam';
import { Dashboard } from './components/Dashboard/Dashboard';

import { AuthContext } from './Contexts';

import { PackList } from './components/OpenPacks/PackList';
import { Layout } from './components/Layout';
import { ManageRoster } from './components/Roster/ManageRoster';
import { SetLines } from './components/Roster/SetLines';
import { Shop } from './components/Shop/Shop';
import { PlayerDetails } from './components/Roster/PlayerDetails';
import { Live } from './components/Live/Live';
import { FreeAgents } from './components/FreeAgents/FreeAgents';
import { Recap } from './components/Recap/Recap';
import { Standings } from './components/Standings/Standings';

import './App.css';
import { Team } from './components/Team/Team';
import { TestSim } from './components/TestSim/TestSim';
import { TestFormations } from './components/TestSim/TestFormations';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/join',
    element: <CreateTeam />
  },
  {
    path: '/dashboard',
    element: <Layout><Dashboard /></Layout>
  },
  {
    path: '/packs',
    element: <Layout><PackList /></Layout>
  },
  {
    path: '/roster',
    element: <Layout><ManageRoster /></Layout>
  },
  {
    path: '/lines',
    element: <Layout><SetLines /></Layout>
  },
  {
    path: '/shop',
    element: <Layout><Shop /></Layout>
  },
  {
    path: '/freeagents',
    element: <Layout><FreeAgents /></Layout>
  },
  {
    path: '/freeagents/:page',
    element: <Layout><FreeAgents /></Layout>
  },
  {
    path: '/standings',
    element: <Layout><Standings /></Layout>
  },
  {
    path: '/teams/:id',
    element: <Layout><Team /></Layout>
  },
  {
    path: '/player/:id',
    element: <Layout><PlayerDetails /></Layout>
  },
  {
    path: '/live/:id',
    element: <Layout><Live /></Layout>
  },
  {
    path: '/recap/:id',
    element: <Layout><Recap /></Layout>
  },
  {
    path: '/test/',
    element: <Layout><TestSim /></Layout>
  },
  {
    path: '/testformations/',
    element: <Layout><TestFormations /></Layout>
  }
]);

function App() {
  const [launched, setLaunched] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const load = async () => {
      try {
        await connection.reAuthenticate();
        let user = await connection.get('authentication')
        setCurrentUser(user);
      } catch (error) {
        console.log(error);
      }
    }

    const lastLaunched = localStorage.getItem('launched');
    if (lastLaunched && new Date(lastLaunched).getDate() === new Date().getDate()) {
      setLaunched(true);
    }
    
    load();

    setTimeout(() => {
      // set local storage launched state
      localStorage.setItem('launched', new Date());

      setLaunched(true);
    }, 6500);
  }, [])

  if (!launched) return <Launch />
  return (
  <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
    <RouterProvider router={router} />
  </AuthContext.Provider>
  );
}

export default App;
