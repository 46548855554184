import React from 'react';
import './launch.scss';

export const Launch = () => {
  return (
    <div className='launchWrapper'>
      <div className='launcher'>
        <div className='blackBackground'></div>
        <div className='logoSlide'>
          <img src="/images/saihl/saihl_color.svg" alt="s_logo" />
        </div>
        <div className='logoBounce'>
          <img src="/images/saihl/s_color.svg" alt="s_logo" />
        </div>
        <div className='outlinePulse'>
          <img src="/images/saihl/s_hollow_color.svg" alt="s_hollow" />
          <img src="/images/saihl/s_hollow_color.svg" alt="s_hollow" />
          <img src="/images/saihl/s_hollow_color.svg" alt="s_hollow" />
          <img src="/images/saihl/s_hollow_color.svg" alt="s_hollow" />
          <img src="/images/saihl/s_hollow_color.svg" alt="s_hollow" />
        </div>
        <div className='scaleCard' style={{ transform: "scale(" + window.innerHeight/2 + "px)"}}>
          <img src="/images/saihl/card.svg" alt="launch" />
        </div>
      </div>
    </div>
  )
}



/* 
 *
 * Black Screen
 * Card Scale from center
 * Mask Swipe up or move it up off screen
 * Black Screen
 * Letter Scale up from center
 * Letter Outline Pulse x 3
 * Color Swipe up
 * 
*/


/* 
 *
 * Black Screen
 * Card Scale from center
 * Mask Swipe up
 * Black Screen
 * Letter Scale up from center
 * Letter Outline Pulse x 3
 * Slide Left
 * Slide Mask right to reveal full word
 * Color Swipe up
 * Fade to 0 opacity
 * 
*/