import React from 'react'
import './CardPack.scss';
import { getSimplePackClass, getPackSVG } from './PackUtility';

export const DisplayPack = ({ type, count, onClick = ()=>{} }) => {

  return (
    <div className="displayCardPackWrapper" onClick={onClick}>
      <div className={getSimplePackClass(type)}>
        <div className='cardShakeHover'>
          <img className={(type === 'SSS+') ? 'sss-glow' : (type === 'SSS') ? 'sss-glow' : (type === 'SS') ? 'ss-glow' : ''} src={getPackSVG(type)} alt="sss card pack float" style={{ animationDelay: Math.random() * 2 + 's', animationDuration: Math.random() * 5 + 9 + 's' }} />
        </div>
      </div>
      {(!isNaN(count)) ? <div className='countLocation'>x{count}</div> : ''}
      <div className='underShadow'>
        <img src="/images/saihl/shadow.svg" alt="sss card pack shadow" />
      </div>
    </div>
  )
}
