import { Text, Flex, LoadingOverlay, Title } from '@mantine/core'
import React, { useContext, useState } from 'react'
import connection from '../../feathers'
import { AuthContext } from '../../Contexts'
import { DisplayPack } from '../Packs/DisplayPack'
import { notifications } from '@mantine/notifications'

export const Shop = () => {
  const [processing, setProcessing] = useState(false);

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const buyPack = async (type) => {
    notifications.show({
      title: 'Shop Disabled',
      message: 'The shop is currently disabled. Please check back later.',
    })
    return;
    //setProcessing(true);

    //console.log(currentUser);
    let newpack_temp = {
      ownerId: currentUser.user._id,
      type: type,
    }
    //console.log(newpack_temp);

    let pack = await connection.service('packs').create(newpack_temp)

    //console.log(pack);
    notifications.show({
      title: 'Pack Acquired!',
      message: `You have Acquired a ${type} pack!`,
    })

    setProcessing(false);
  }

  return (
    <div>
      <LoadingOverlay visible={processing} />
      <Title ta="center" w="100%" size="3rem">FREE PACKS!</Title>
      <Text mb="1rem" ta="center" w="100%" size="1.25rem">Click on a pack to add it to your inventory!</Text>
      
      <Flex justify="center" gap="1rem" wrap="wrap">
        <DisplayPack type="SSS+" onClick={() => buyPack('SSS+')} />
        <DisplayPack type="SSS" onClick={() => buyPack('SSS')} />
        <DisplayPack type="SS" onClick={() => buyPack('SS')} />
        <DisplayPack type="S" onClick={() => buyPack('S')} />
        <DisplayPack type="B" onClick={() => buyPack('B')} />
        <DisplayPack type="C" onClick={() => buyPack('C')} />
      </Flex>
    </div>
  )
}
