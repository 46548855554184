import { getCell } from "../generateMap";
import { getBoundedPosition, getClosestPointAlongLine, getDistance, getDistanceToLine } from "../getDistance";
import { getDefensivePosition, getOffensivePosition, getRecoveryPosition, playerPositionList } from "../getPlayerLocation";
import { getPosition } from "../positioning/getPosition";
import { AWAY_BLUELINE, AWAY_NET, FATIGUE_REDUCTION, HOME_BLUELINE, HOME_NET, MAX_PASS_SPEED, RINK_HEIGHT, RINK_WIDTH, SKATE_FRICTION } from "./config";

export class Player {
  constructor(player, team, position) {
    this.position = { x: 0, y: 0 };
    this.velocity = { x: 0, y: 0 };

    this.id = player._id;
    this.name = player.bio.getName();
    this.team = team;
    this.role = player.type.position;
    this.linePosition = position;
    this.type = player.type.abbr;
    this.actions = player.type.actions;
    this.data = player;

    this.selectedAction = {
      action: '',
      confidence: -1,
      target: null,
      targetPlayer: null,
    }

    this.stat = {
      goals: 0,
      assists: 0,
      shots : 0,
      saves: 0,
      hits: 0,
      takeaways: 0,
      blocks: 0,
      faceoffsWon: 0,
      faceoffs: 0,
      plusMinus: 0,
      toi: 0,
    }

    this.energy = 1;
    this.actionTimeout = 0; // 0 = yes, > 0 = time until action
    this.actionCallback = null; // used for linechange or board battle?

    this.modifiers = {
      adaptability: 1 - (0.15 * (1 - player.adaptability)),
    }

    if (position === 'center' ||
    ((position === 'leftWing' || position === 'leftDefense') && player.bio.handedness === 'L') ||
    ((position === 'rightWing' || position === 'rightDefense') && player.bio.handedness === 'R')) {
      this.modifiers.adaptability = 1;
    }

    this.skills = {
      recovery: this.adjustSkill([
        { value: this.data.athletic.endurance, weight: 1.25 },
        { value: this.data.athletic.strength, weight: 0.25 },
        { value: this.data.mental.tenacity, weight: 0.25 },
      ]),
      fatigue: this.adjustSkill([
        { value: this.data.athletic.endurance, weight: 1 },
        { value: this.data.athletic.agility, weight: 0.25 },
        { value: this.data.athletic.speed, weight: 0.1 },
        { value: this.data.mental.tenacity, weight: 0.5 },
      ]),
      defensiveAwareness: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.5 },
        { value: this.data.mental.vision, weight: 0.2 },
        { value: this.data.defense.cover, weight: 1 },
        { value: this.data.athletic.agility, weight: 0.1 },
      ]),
      offensiveAwareness: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.7 },
        { value: this.data.mental.tenacity, weight: 0.1 },
        { value: this.data.mental.vision, weight: 0.25 },
        { value: this.data.shooting.skill, weight: 1 },
        { value: this.data.athletic.speed, weight: 0.2 },
      ]),
      passingAwareness: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.5 },
        { value: this.data.mental.vision, weight: 0.5 },
        { value: this.data.technique.control, weight: 0.25 },
        { value: this.data.shooting.accuracy, weight: 0.75 },
        { value: this.data.technique.passing, weight: 1.25 },
      ]),
      skatingAwareness: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.25 },
        { value: this.data.mental.vision, weight: 0.5 },
        { value: this.data.technique.control, weight: 0.1 },
        { value: this.data.technique.dangling, weight: 0.25 },
        { value: this.data.athletic.balance, weight: 0.1 },
        { value: this.data.athletic.speed, weight: 1 },
        { value: this.data.athletic.agility, weight: 1 },
      ]),
      shootingSkill: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.25 },
        { value: this.data.mental.vision, weight: 0.5 },
        { value: this.data.shooting.accuracy, weight: 1 },
        { value: this.data.shooting.power, weight: 1.25 },
        { value: this.data.shooting.range, weight: 0.5 },
        { value: this.data.shooting.skill, weight: 0.75 },
        { value: this.data.technique.control, weight: 0.25 },
        { value: this.data.technique.dangling, weight: 0.25 },
      ]),
      offensiveSkill: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.25 },
        { value: this.data.mental.tenacity, weight: 0.15 },
        { value: this.data.mental.vision, weight: 0.5 },
        { value: this.data.athletic.balance, weight: 0.1 },
        { value: this.data.athletic.strength, weight: 0.1 },
        { value: this.data.athletic.speed, weight: 0.25 },
        { value: this.data.technique.control, weight: 0.25 },
        { value: this.data.technique.passing, weight: 0.75 },
        { value: this.data.shooting.accuracy, weight: 1 },
        { value: this.data.shooting.power, weight: 0.75 },
        { value: this.data.shooting.range, weight: 0.5 },
        { value: this.data.shooting.skill, weight: 0.75 },
      ]),
      deflect: this.adjustSkill([
        { value: this.data.athletic.strength, weight: 0.5 },
        { value: this.data.athletic.balance, weight: 0.15 },
        { value: this.data.mental.vision, weight: 0.35 },
        { value: this.data.technique.control, weight: 1 },
        { value: this.data.shooting.skill, weight: 1 },
      ]),
      faceoff: this.adjustSkill([
        { value: this.data.athletic.strength, weight: 0.25 },
        { value: this.data.athletic.balance, weight: 0.5 },
        { value: this.data.technique.control, weight: 0.5 },
        { value: this.data.technique.faceoff, weight: 1 },
      ]),
      shootingPower: this.adjustSkill([
        { value: this.data.mental.vision, weight: 0.1 },
        { value: this.data.athletic.strength, weight: 0.25 },
        { value: this.data.shooting.power, weight: 1 },
        { value: this.data.shooting.range, weight: 0.75 },
        { value: this.data.shooting.skill, weight: 0.25 },
      ]),
      skatingSpeed: (this.linePosition === 'goaltender') ?
        (this.data.mental.vision * 0.05 + this.data.athletic.agility * 0.7 + this.data.technique.positioning * 0.25)
        :
        this.adjustSkill([
          { value: this.data.mental.awareness, weight: 0.1 },
          { value: this.data.mental.tenacity, weight: 0.15 },
          { value: this.data.athletic.strength, weight: 0.1 },
          { value: this.data.athletic.balance, weight: 0.5 },
          { value: this.data.athletic.agility, weight: 0.5 },
          { value: this.data.athletic.speed, weight: 1.25 },
          { value: this.data.technique.control, weight: 0.25 },
        ]),
      skatingAgility: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.1 },
        { value: this.data.athletic.balance, weight: 0.25 },
        { value: this.data.athletic.agility, weight: 1.25 },
        { value: this.data.technique.control, weight: 0.1 },

      ]),
      passingPower: this.adjustSkill([
        { value: this.data.mental.vision, weight: 0.25 },
        { value: this.data.athletic.strength, weight: 0.25 },
        { value: this.data.shooting.power, weight: 0.25 },
        { value: this.data.technique.passing, weight: 1.25 },
      ]),
      passingAccuracy: this.adjustSkill([
        { value: this.data.mental.awareness, weight: 0.25 },
        { value: this.data.mental.vision, weight: 0.5 },
        { value: this.data.athletic.strength, weight: 0.25 },
        { value: this.data.shooting.accuracy, weight: 0.5 },
        { value: this.data.technique.passing, weight: 1.5 },
      ]),
    }
  }

  updateFatigue(state, bench) {
    // As the game goes on, reduce the recovery rate and the max energy
    const energyModifier = ((state.period - 1) * 1200) + state.time;
    const maxEnergy = 1 - (0.1 * energyModifier / 3600);
    
    if (bench) {
      let rate = 0.0075;
      if (this.position === 'leftDefense' || this.position === 'rightDefense')
        rate = 0.01;
    
      this.energy += this.skills.recovery * rate * state.delta * maxEnergy;
      if (this.energy > maxEnergy) this.energy = maxEnergy;
    } else {
      let bonus = 1;
      if (Math.abs(this.position.x) > 0.35) bonus = 1.15;
      if (this.position === 'leftDefense' || this.position === 'rightDefense')
        bonus *= 0.75;
      
      this.energy -= (1-this.skills.fatigue) * FATIGUE_REDUCTION * bonus * state.delta;
      if (this.energy < 0) this.energy = 0;
    }

    return this.updateState(state);
  }

  adjustSkill(skills) {
    // apply modifiers, energy and coaching
    let adjusted = 0;
    let sum = 0;

    skills.forEach(skill => {
      adjusted += skill.value * skill.weight;
      sum += skill.weight;
    });

    adjusted = adjusted / sum;
    adjusted *= this.modifiers.adaptability;
    adjusted *= 0.5 + this.energy * this.energy * 0.5;

    return adjusted;
  }

  resolve(state) {
    // resolve any actions or effects
    if (this.actionTimeout > 0) {
      this.actionTimeout -= state.delta;

      if (this.actionCallback && this.actionTimeout <= 0) state = this.actionCallback(state);

      return this.updateState(state);
    }

    this.velocity = {
      x: this.velocity.x * 0.95,
      y: this.velocity.y * 0.95,
    }

    // If we shot or pass, check that it happened and clear the action
    if (this.selectedAction.action === 'shoot' && state.puck.state === 'shot') {
      this.selectedAction.confidence = 0;
    }
    if (this.selectedAction.action === 'pass' && state.puck.state === 'pass') {
      this.selectedAction.confidence = 0;
    }

    return this.updateState(state);
  }

  move(state) {
    // Update the player's position.
    this.position.x += this.velocity.x * state.delta;
    this.position.y += this.velocity.y * state.delta;

    // Keep the player in bounds.

    const tested = getBoundedPosition(this.position);
    if (tested.x !== this.position.x)
      this.velocity.x = -this.velocity.x * SKATE_FRICTION;

    if (tested.y !== this.position.y)
      this.velocity.y = -this.velocity.y * SKATE_FRICTION;

    this.position = tested;

    return this.updateState(state);
  }

  think(state, map, scoringMap) {
    if (this.actionTimeout > 0) return state;

    if (this.linePosition === 'goaltender') {
      this.selectedAction = {
        ...this.selectedAction,
        action: 'skate',
        target: getPosition(state, this),
        confidence: 1
      }

      return this.updateState(state);
    }

    // Need to see what the confidence max is
    let action = this.decide(state, map, scoringMap);

    //console.log('Action', action);
    // Consider changing actions only if the new action has higher confidence
    if (action.confidence > this.selectedAction.confidence) {
      this.selectedAction.confidence *= 0.75;
      if (this.selectedAction.confidence < 0.25) {
        this.selectedAction = {...action};
      }
    } else {
      this.selectedAction.confidence *= 0.95;
    }
    
    return this.updateState(state);
  }

  decide(state, map, scoringMap) {
    let actions = this.evaluate(state, map, scoringMap);
    
    //console.log('actions', actions);

    // Select a random action by weight
    let totalWeight = actions.reduce((sum, action) => sum + action.confidence, 0);
    let randomNum = Math.random() * totalWeight;
    let selected;
    
    //if (state.puck.player && state.puck.player.id === this.id) console.log(this.position, actions);

    if (totalWeight === 0) return actions[0];


    //if (state.puck.player.id === this.id) console.log('Selected Actions', this.linePosition, actions);
    for (let action of actions) {
      if (randomNum <= action.confidence) {
        selected = action;
        break;
      }
      randomNum -= action.confidence;
    }
    //console.log('selected', selected);

    return selected;
  }

  evaluate(state, map, scoringMap) {
    let actions = [];
    // Confidence is a value between 0 and 1

    if (state.puck.player &&
      state.puck.player.id === this.id &&
      state.puck.state === 'carried') {
      actions = [
        { action: 'skate', confidence: 0 },
        { action: 'pass', confidence: 0 },
        { action: 'shoot', confidence: 0 }
      ];
      let results = this.evaluatePass(state, map);
      actions.find(a => a.action === 'pass').confidence = results.confidence;
      actions.find(a => a.action === 'pass').target = results.target;
      actions.find(a => a.action === 'pass').targetPlayer = results.targetPlayer;

      results = this.evaluateSkate(state, map);
      actions.find(a => a.action === 'skate').confidence = results.confidence;
      actions.find(a => a.action === 'skate').target = results.target;

      results = this.evaluateShoot(state, scoringMap);
      actions.find(a => a.action === 'shoot').confidence = results.confidence;
      actions.find(a => a.action === 'shoot').target = results.target;
    } else {
      actions = [
        { action: 'deflect', confidence: 0 },
        { action: 'intercept', confidence: 0 },
        { action: 'challenge', confidence: 0 },
        { action: 'recover', confidence: 0 },
        { action: 'skate', confidence: 0 }
      ];

      // is there a puck carrier?
      if (!state.puck.player || (state.puck.state !== 'carried')) {
        // If the puck is within range and there is an opponent nearby
        // challenge the opponent for the puck
        // a challenge happens when there are more then 1 player with a recover action
        // and those players are within a certain distance of the puck
        if (getDistance(this.position, state.puck.position) < 10) {
          actions.find(a => a.action === 'recover').confidence = 1;
          actions.find(a => a.action === 'recover').target = state.puck.position;
        } else {
          // Map the players team and their distance to the puck
          const players = state.home.id === this.team.id ? state.home.active : state.away.active;
          // for each key in players
          let distances = [];
          for(const position in playerPositionList) {
            const player = players[playerPositionList[position]];
            distances.push({
              isPlayer: player.id === this.id,
              distance: getDistance(player.position, state.puck.position)
            });
          }
          // sort the distances by distance and get the index of the current player
          distances.sort((a, b) => a.distance - b.distance);
          const index = distances.findIndex(d => d.isPlayer);
          // set the confidence based on the index
          actions.find(a => a.action === 'recover').confidence = 1 - (index / distances.length);
          actions.find(a => a.action === 'recover').target = state.puck.position;

          // else skate to a benefitial area of the ice
          actions.find(a => a.action === 'skate').confidence = 0.5;
          actions.find(a => a.action === 'skate').target = getRecoveryPosition(this, state.puck, state.home.id !== this.team.id)
        }
      } else if (state.puck.player.team.id !== this.team.id) {
        // What is the puck carrier doing?
        // and is the current player in a position to do something about it?
        const opponentAction = state.puck.player.selectedAction;
        const opponent = state.puck.player;
        const distance = getDistance(this.position, opponent.position);
        const targetSkatePosition = getDefensivePosition(
          this,
          (this.team.id === state.home.id) ? state.away.active : state.home.active,
          this.team.strategy,
          state.puck,
          state.home.id !== this.team.id
        );
        //console.log('Sakte Def Pos', targetSkatePosition);
        actions.find(a => a.action === 'skate').target = targetSkatePosition;
        actions.find(a => a.action === 'challenge').target = opponent.position;
        actions.find(a => a.action === 'challenge').targetPlayer = opponent.id;

        if (Math.random() < this.skills.defensiveAwareness) {
          if (opponentAction.action === 'pass') {
            const passDistance = getDistanceToLine(opponent.position, opponentAction.target, this.position);
            const passInterceptLocation = getClosestPointAlongLine(opponent.position, opponentAction.target, this.position);

            if (passDistance < 5) {
              actions.find(a => a.action === 'intercept').confidence = 1;
              actions.find(a => a.action === 'intercept').target = passInterceptLocation;
            } else if (distance < 5) {
              actions.find(a => a.action === 'challenge').confidence = 1;
            } else {
              actions.find(a => a.action === 'skate').confidence = 1;
            }
          } else if (opponentAction.action === 'skate') {
            // Get the distance between the opponent and player if within 5 ft challenge
            if (distance < 5) {
              actions.find(a => a.action === 'challenge').confidence = 1;
            } else {
              actions.find(a => a.action === 'skate').confidence = 1;
            }
          }
        } else {
          actions.find(a => a.action === 'skate').confidence = 1;
        }

        // Set skate confidence based on distance from defensive formation position
        if (actions.find(a => a.action === 'skate').confidence === 0) {
          const distance = getDistance(this.position, targetSkatePosition);
          actions.find(a => a.action === 'skate').confidence = Math.min(distance / 70, 1);
        }
      } else { // Might not work if we unset the puck carrier
        // The puck carrier is a teammate
        // Check if you are the target of a pass and move to that location
        const currentAction = state.puck.player.selectedAction;
        const targetSkatePosition = getOffensivePosition(
          state.puck.position,
          this,
          this.team.strategy,
          state.home.id !== this.team.id
        );
        //console.log('Sakte Off Pos', targetSkatePosition);

        actions.find(a => a.action === 'skate').target = targetSkatePosition;
        actions.find(a => a.action === 'skate').confidence = 0.1;

        if (currentAction.action === 'pass' && getDistance(this.position, currentAction.target) < 10) {
          actions.find(a => a.action === 'skate').target = currentAction.target;
          actions.find(a => a.action === 'skate').confidence = 1;
        } else if (currentAction.action === 'shoot' &&
        (this.position.x - currentAction.target.x < 20) &&
        (this.position.y - currentAction.target.y < 7.5) &&
        (Math.random() < this.skills.offensiveAwareness)) {
          actions.find(a => a.action === 'deflect').confidence = 1;
          actions.find(a => a.action === 'deflect').target = currentAction.target;
        } else {
          // otherwise get into position
          actions.find(a => a.action === 'skate').confidence = 1;
        }
      }
    }

    // Apply exponential decrease to sorted action confidence
    actions.sort((a, b) => b.confidence - a.confidence);
    let reductionFactor = 0.5;
    for (let i = 0; i < actions.length; i++) {
      actions[i].confidence *= Math.pow(reductionFactor, i);
    }

    return actions;
  }

  evaluatePass(state, map) {
    // get the pass gradient map for the players team\
    let action = {
        confidence: 0,
        target: { x: 0, y: 0 },
        targetPlayer: null
    }
    let players = [
      state.home.active.center,
      state.home.active.leftWing,
      state.home.active.rightWing,
      state.home.active.leftDefense,
      state.home.active.rightDefense
    ];
    let opponents = [
      state.away.active.center,
      state.away.active.leftWing,
      state.away.active.rightWing,
      state.away.active.leftDefense,
      state.away.active.rightDefense
    ]
    if (state.home.id !== this.team.id){
      const flip = players;
      players = opponents;
      opponents = flip;
    }

    // get how many players are close to you, if many, pass
    let closePlayers = 0;
    for (const opponent of opponents) {
      const distance = getDistance(this.position, opponent.position);
      if (distance < 10)
        closePlayers++;
      
    }
    const threatLevel = closePlayers / 2.5;

    const options = [];
    for(const player of players) {
      if (player.id === this.id) continue;

      // project the players position forward based on the puck traveltime
      let playerDistance = getDistance(this.position, player.position);
      let passTime = playerDistance / (this.skills.passingPower * MAX_PASS_SPEED);
      let projectedPosition;
      if (player.velocity.x === 0 && player.velocity.y === 0) {
        // The receiver is stationary. Pass the puck slightly ahead of them.
        const leadDistance = state.home.id !== this.team.id ? -15 : 15;  // Define this constant as appropriate for your simulation
        projectedPosition = getBoundedPosition({
          x: player.position.x + leadDistance,
          y: player.position.y
        });
      } else {
        // The receiver is moving. Estimate where they will be when the pass arrives.
        projectedPosition = getBoundedPosition({
          x: player.position.x + player.velocity.x * passTime * 0.9,
          y: player.position.y + player.velocity.y * passTime * 0.9
        });
      }
      
      // Calculate the distance between the puck and the projected position of the target player
      let passDistance = getDistance(this.position, projectedPosition);
      if (passDistance >= 120)
      passDistance = 0.1;
      else if (passDistance > 50)
        passDistance = Math.max(0, Math.min(1, 1-((passDistance - 50) / 76.5)));
      else if (passDistance > 35)
        passDistance = 1;
      else if (passDistance > 5)
        passDistance = Math.max(0, Math.min(1, (passDistance / 35)));
      else
        passDistance = 0.1;

      // Calculate a value based on the pass vector towards the opposing net
      const passValue =  Math.abs((this.team.id === state.home.id) ?
        (AWAY_NET.x - projectedPosition.x) : (HOME_NET.x - projectedPosition.x));

      const currentPositionValue = Math.abs((this.team.id === state.home.id) ?
        (AWAY_NET.x - this.position.x) : (HOME_NET.x - this.position.x));

      // use the targetPlayers velocity to see if they are moving towards the opposing goal
      // if they are assign a higher value to the pass

      // compare passValue to the current position value
      // if the pass is in the wrong direction, reduce confidence
      let modifier = 1;
      if (currentPositionValue < passValue)
        modifier = (currentPositionValue / passValue) * (currentPositionValue / passValue);
      
      // Check if the velocity magnitude is > 0 and if it's towards the opponents net
      if (this.team.id === state.home.id)
        AWAY_NET.x - projectedPosition.x > 0 ? modifier *= 1.5 : modifier *= 0.5;
      else
        HOME_NET.x - projectedPosition.x > 0 ? modifier *= 1.5 : modifier *= 0.5;

      // reduce the modifier if its a side pass y direction
      if (this.position.x - projectedPosition.x < 5)
        modifier *= 0.5;
      
      const mapValue = getCell(map, projectedPosition);
      
      let passLaneConfidence = (
        (player.skills.offensiveSkill +
        passDistance +
        mapValue) / 3) * modifier;
      
      // If the target is in the offensive zone, and passer is not, set confidence to 0
      if (this.team.id === state.home.id && this.position.x < AWAY_BLUELINE.x && projectedPosition.x > AWAY_BLUELINE.x)
        passLaneConfidence = 0;
      else if (this.team.id === state.away.id && this.position.x > HOME_BLUELINE.x && projectedPosition.x < HOME_BLUELINE.x)
        passLaneConfidence = 0;

      for (let i = 0; i < opponents.length; i++) {
        const opponentDistance = getDistance(projectedPosition, opponents[i].position);
        
        // if opponent is too close to the passing lane, reduce confidence
        if (opponentDistance < 10) {
          const modifier = (0.75 + 0.25 * (opponentDistance / 10))
          passLaneConfidence *= modifier;
        }
      }

      options.push({
        confidence: passLaneConfidence + threatLevel,
        target: projectedPosition,
        targetPlayer: player
      });
    }

    // Choose an option based on random weighted selection exponential decrease
    options.sort((a, b) => b.confidence - a.confidence);
    let reductionFactor = 0.25 + 0.75 * (1 - this.skills.passingAwareness);
    let totalWeight = 0;
    for (let i = 0; i < options.length; i++) {
      options[i].confidence *= Math.pow(reductionFactor, i);
      totalWeight += options[i].confidence;
    }

    // Choose a random option based on confidence
    let random = Math.random();
    for (let i = 0; i < options.length; i++) {
      const weight = options[i].confidence / totalWeight;
      if (random < weight) 
        return options[i];
      
      random -= weight;
    }

    return action;
  }
  
  evaluateSkate(state, map) {
    // define default action
    let action = {
      confidence: 0,
      target: { x: 0, y: 0 }
    }
        
    // generate potential target positions to consider
    let targets = this.generateTargets(state, map);
    if (state.home.id === this.team.id)
      targets.push({x: AWAY_NET.x - 10, y: AWAY_NET.y, weight: 0.75});
    else
      targets.push({x: HOME_NET.x + 10, y: HOME_NET.y, weight: 0.75});
    //console.log('Targets', targets);
    let options = [];

    // evaluate each target
    for (let target of targets) {
      // calculate confidence for this target
      let confidence = (target.weight + this.skills.skatingAwareness) / 2;
      
      // Player Avoidance? or is that built into the map?

      options.push({
        confidence: confidence,
        target: {
          x: target.x,
          y: target.y
        }
      });
    }

    // Choose an option based on random weighted selection exponential decrease
    options.sort((a, b) => b.confidence - a.confidence);
    let reductionFactor = 0.5 + 0.5 * (1 - this.skills.skatingAwareness);
    let totalWeight = 0;
    for (let i = 0; i < options.length; i++) {
      options[i].confidence *= Math.pow(reductionFactor, i);
      totalWeight += options[i].confidence;
    }
    //console.log('Skate Options', options);

    // Choose a random option based on confidence
    let random = Math.random();
    for (let i = 0; i < options.length; i++) {
      const weight = options[i].confidence / totalWeight;
      if (random < weight)
        return options[i];
      
      random -= weight;
    }
    
    return action;
  }
  

  evaluateShoot(state, scoringMap) {
    // If not in the offensive Zone don't shoot
    const goalLocation = (this.team.id === state.home.id) ? AWAY_NET : HOME_NET;
    let opponents = [
      state.home.active.center,
      state.home.active.leftWing,
      state.home.active.rightWing,
      state.home.active.leftDefense,
      state.home.active.rightDefense
    ]

    if (this.team.id === state.home.id) {
      opponents = [
        state.away.active.center,
        state.away.active.leftWing,
        state.away.active.rightWing,
        state.away.active.leftDefense,
        state.away.active.rightDefense
      ]
    }

    // Can't shoot from behind the goal line or outside the zone
    if ((this.team.id === state.home.id && (this.position.x < AWAY_BLUELINE || this.position.x > AWAY_NET.x)) ||
      (this.team.id !== state.home.id && (this.position.x > HOME_BLUELINE || this.position.x < HOME_NET.x))) {
      return { confidence: 0, target: goalLocation };
    }

    // Check the current location for how good a shooting location is
    let confidence = 0;
    //console.log(scoringMap, this.position)
    let mapValue = getCell(scoringMap, this.position);

    // Every 15 degrees towards the center of the net is an increase in confidence
    // 0 - 15 degrees = 0.13, 15 - 30 degrees = 0.1, 30 - 45 degrees = 0.08, 45 - 60 degrees = 0.075
    // 60 - 75 degrees = 0.05, 75 - 90 degrees = 0.035, 90 - 105 degrees = 0.03
    const playerVector = { x: this.position.x - goalLocation.x, y: this.position.y - goalLocation.y };
    const straigtToGoal = { x: goalLocation.x, y: 0 };
    const angle = this.getAngleBetweenPoints(playerVector, straigtToGoal);
    let angleConfidence = 0;
    if (angle < 15) angleConfidence = 0.13 / 0.13;
    else if (angle < 30) angleConfidence = 0.1 / 0.13;
    else if (angle < 45) angleConfidence = 0.08 / 0.13;
    else if (angle < 60) angleConfidence = 0.075 / 0.13;
    else if (angle < 75) angleConfidence = 0.05 / 0.13;
    else if (angle < 90) angleConfidence = 0.035 / 0.13;
    else if (angle < 105) angleConfidence = 0.03 / 0.13;
  
    // Check the shooting line for opponents
    let shootingLaneConfidence = 1;
    for (const opponent of opponents) {
      const lineDistance = getDistanceToLine(this.position, goalLocation, opponent.position);
      if (lineDistance < 5)
        shootingLaneConfidence *= 0.75;
    }

    confidence = (mapValue +
      angleConfidence +
      shootingLaneConfidence +
      this.skills.shootingSkill) / 4;

    //console.log('Shot: ', this.position, confidence, angleConfidence, shootingLaneConfidence)

    return {
      confidence: confidence,
      target: goalLocation
    };
  }

  setActionDelay(state, delay, callback) {
    this.actionTimeout = delay;
    this.actionCallback = callback;

    // Reset the selected Action in the next think phase
    this.selectedAction.confidence = 0;

    return this.updateState(state);
  }

  overall() {
    return this.data.overall()
  }

  updateState(state) {
    if (state.home.id === this.team.id) state.home.active[this.position] = this;
    else state.away.active[this.position] = this;
    return state
  }

  generateTargets(state, map) {
    let targets = [];
    let range = 50; // the range of positions to consider, in game units
    let resolution = 10; // the distance between each position, in game units
    const position = {
      x: Math.floor(this.position.x),
      y: Math.floor(this.position.y)
    }
  
    // generate a grid of positions within the specified range
    for (let x = position.x - range; x <= position.x + range; x += resolution) {
      for (let y = position.y - range; y <= position.y + range; y += resolution) {
        // check if the position is within the game area and add it to the list of targets
        let currentPosition = {
          x: range + x,
          y: range + y
        }

        let weight = getCell(map, {x: currentPosition.x, y: currentPosition.y}) * 3;
        
        let xdirection = AWAY_NET.x - this.position.x;
        let targetCompareX = AWAY_NET.x - currentPosition.x;
        if (this.team.id === state.away.id) {
          xdirection = (HOME_NET.x - this.position.x) * -1;
          targetCompareX = (HOME_NET.x - currentPosition.x) * -1;
        }

        if (targetCompareX <= xdirection) weight *= 1.5
        else weight *= 0.75;
        
        if (this.isValidPosition(currentPosition.x, currentPosition.y)) {
          targets.push({x: currentPosition.x, y: currentPosition.y, weight: weight});
        }
      }
    }
  
    targets.sort((a, b) => b.weight - a.weight);
    return targets.slice(0, 10);
  }
  
  isValidPosition(x, y) {
    const bounded = getBoundedPosition({x, y});
    if (bounded.x !== x || bounded.y !== y) return false;
    return true;
  }

  getAngleBetweenPoints(a, b) {
    return Math.atan2(b.y - a.y, b.x - a.x) * 180 / Math.PI;
  }
}