import React, { useContext, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';

import { CardPack } from '../Packs/CardPack';
import { PlayerCardList } from './PlayerCardList';

import './OpenPacks.scss';
import connection from '../../feathers';
import { AuthContext } from '../../Contexts';

export const OpenPack = ({ packs, type, callback }) => {
  const [opened, setOpened] = useState(false);
  const [players, setPlayers] = useState();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // get a random pack, then get the players from that pack
    let pack = packs[Math.floor(Math.random() * packs.length)];

    //console.log('pack.players', pack.players.data);
    setPlayers(pack.players.data);
    pack.players.data.forEach((player) => {
      connection.service('players').patch(player._id, {
        revealed: true,
        ownerId: currentUser.user._id
      })
    })
    connection.service('packs').remove(pack._id);

    setTimeout(() => {
      setOpened(true);
    }, 1500);
  }, [])

  return (
    <div className='openPackWrapper'>
      <div className='darkenScreen'></div>
      {(!opened) ? (
        <CardPack type={type} jitter={true} offset={(window.innerHeight / 2) - 225} />
      ) : (
        <>
        <PlayerCardList players={players} />
        <div className='closeButton' style={{ fontSize: '1.5rem' }} onClick={callback}><IoClose /></div>
        </>
      )}
    </div>
  )
}
